import gql from "graphql-tag";

export const spendLimitNameFragment = gql`
  fragment SpendLimitNameFragment on SpendLimit {
    name
  }
`;

export const spendLimitStatusFragment = gql`
  fragment SpendLimitStatusFragment on SpendLimit {
    status
    lastStatusUpdateDetails {
      updatedAt
    }
  }
`;

export const spendLimitAmountSpentFragment = gql`
  fragment SpendLimitAmountSpentFragment on SpendLimit {
    currentPeriodBalance {
      amountSpent {
        quantity
        instrumentCodeString
      }
    }
  }
`;

export const spendLimitAvailableAmountFragment = gql`
  fragment SpendLimitAvailableAmountFragment on SpendLimit {
    currentPeriodBalance {
      availableAmount {
        quantity
        instrumentCodeString
      }
    }
  }
`;

export const spendLimitOverLimitAmountFragment = gql`
  fragment SpendLimitOverLimitAmountFragment on SpendLimit {
    currentPeriodBalance {
      isOverLimit
      overLimitAmount {
        quantity
        instrumentCodeString
      }
    }
  }
`;

export const spendLimitUserAmountSpentFragment = gql`
  fragment SpendLimitUserAmountSpentFragment on SpendLimit {
    userAmountSpent {
      quantity
      instrumentCodeString
    }
  }
`;

export const spendLimitIsAutomatedFragment = gql`
  fragment SpendLimitIsAutomatedFragment on SpendLimit {
    budgetProgram {
      id
      userGroup {
        id
        syncType
      }
    }
    userGroup {
      id
      syncType
    }
  }
`;

export const spendLimitIsLimitPerEmployeeFragment = gql`
  fragment SpendLimitIsLimitPerEmployeeFragment on SpendLimit {
    budgetProgram {
      id
      budgetBlueprints {
        id
        fields {
          limit {
            quantity
            instrumentCodeString
          }
        }
      }
    }
  }
`;

export const spendLimitHasLimitGroupFragment = gql`
  fragment SpendLimitHasLimitGroupFragment on SpendLimit {
    limitGroup {
      id
    }
  }
`;

export const spendLimitBudgetProgramPropagationStatusFragment = gql`
  fragment SpendLimitBudgetProgramPropagationStatusFragment on SpendLimit {
    budgetProgram {
      id
      propagationStatus
    }
  }
`;

export const spendLimitBaseLimitFragment = gql`
  fragment SpendLimitBaseLimitFragment on SpendLimit {
    authorizationSettings {
      baseLimit {
        quantity
        instrumentCodeString
      }
    }
  }
`;

export const spendLimitSpendableLimitFragment = gql`
  fragment SpendLimitSpendableLimitFragment on SpendLimit {
    spendableLimit {
      quantity
      instrumentCodeString
    }
  }
`;

export const spendLimitLimitFragment = gql`
  fragment SpendLimitLimitFragment on SpendLimit {
    ...SpendLimitBaseLimitFragment
    ...SpendLimitSpendableLimitFragment
    ...SpendLimitIsLimitPerEmployeeFragment
  }
  ${spendLimitBaseLimitFragment}
  ${spendLimitSpendableLimitFragment}
  ${spendLimitIsLimitPerEmployeeFragment}
`;

export const spendLimitLimitFlexibilityFragment = gql`
  fragment SpendLimitLimitFlexibilityFragment on SpendLimit {
    authorizationSettings {
      authorizationType
      flexibleBufferPercentage
    }
  }
`;

export const spendLimitLimitWithBufferFragment = gql`
  fragment SpendLimitLimitWithBufferFragment on SpendLimit {
    authorizationSettings {
      baseLimit {
        quantity
        instrumentCodeString
      }
    }
    ...SpendLimitLimitFlexibilityFragment
  }
  ${spendLimitLimitFlexibilityFragment}
`;

export const spendLimitTransactionLimitFragment = gql`
  fragment SpendLimitTransactionLimitFragment on SpendLimit {
    transactionLimit {
      quantity
      instrumentCodeString
    }
  }
`;

export const spendLimitLimitVisibilityFragment = gql`
  fragment SpendLimitLimitVisibilityFragment on SpendLimit {
    authorizationVisibility
    authorizationSettings {
      __typename
    }
  }
`;

export const spendLimitAmountsLiteFragment = gql`
  fragment SpendLimitAmountsLiteFragment on SpendLimit {
    id
    ...SpendLimitLimitFragment
    ...SpendLimitAmountSpentFragment
    ...SpendLimitLimitVisibilityFragment
  }
  ${spendLimitLimitFragment}
  ${spendLimitAmountSpentFragment}
  ${spendLimitLimitVisibilityFragment}
`;

export const spendLimitAmountsFragment = gql`
  fragment SpendLimitAmountsFragment on SpendLimit {
    id
    ...SpendLimitAmountsLiteFragment
    ...SpendLimitLimitWithBufferFragment
    ...SpendLimitTransactionLimitFragment
  }
  ${spendLimitAmountsLiteFragment}
  ${spendLimitLimitWithBufferFragment}
  ${spendLimitTransactionLimitFragment}
`;

// uncomment once field available on BE
// export const spendLimitAllocationFragment = gql`
//   fragment SpendLimitAllocationFragment on SpendLimit {
//     id
//     allocation {
//       currentAllocatedAmount {
//         quantity
//         instrumentCodeString
//       }
//       pastSpentAmount {
//         quantity
//         instrumentCodeString
//       }
//     }
//   }
// `;

export const spendLimitAllocationsFragment = gql`
  fragment SpendLimitAllocationsFragment on SpendLimit {
    spendLimitAllocations(referenceBudgetId: $budgetId) {
      startDate
      endDate
      temporaryIncreasesAmount {
        quantity
        instrumentCodeString
      }
      permanentIncreasesAmount {
        quantity
        instrumentCodeString
      }
      rolloverAmount {
        quantity
        instrumentCodeString
      }
      negativeAdjustmentAmount {
        quantity
        instrumentCodeString
      }
      limitAmount {
        quantity
        instrumentCodeString
      }
    }
  }
`;

export const spendLimitLimitIncreaseFragment = gql`
  fragment SpendLimitLimitIncreaseFragment on SpendLimit {
    limitIncreaseSetting
    limitIncreaseRequests {
      id
      request {
        id
        createdBy {
          id
          customerUserId
        }
        status
      }
    }
  }
`;

export const spendLimitAuthorizedSpendSourcesFragment = gql`
  fragment SpendLimitAuthorizedSpendSourcesFragment on SpendLimit {
    spendSourceSetting {
      authorizationType
      spendSources
    }
  }
`;

export const spendLimitRecurrenceFragment = gql`
  fragment SpendLimitRecurrenceFragment on SpendLimit {
    periodType
  }
`;

export const spendLimitAncestryPathFragment = gql`
  fragment SpendLimitAncestryPathFragment on SpendLimit {
    ancestryPath {
      id
      name
    }
  }
`;

export const spendLimitParentBudgetFragment = gql`
  fragment SpendLimitParentBudgetFragment on SpendLimit {
    parentBudget {
      id
      name
    }
  }
`;

export const spendLimitParentSpendLimitFragment = gql`
  fragment SpendLimitParentSpendLimitFragment on SpendLimit {
    parentSpendLimit {
      id
    }
  }
`;

export const spendLimitDistributionFragment = gql`
  fragment SpendLimitDistributionFragment on SpendLimit {
    ...SpendLimitIsLimitPerEmployeeFragment
    ...SpendLimitParentSpendLimitFragment
    ...SpendLimitHasLimitGroupFragment
  }
  ${spendLimitIsLimitPerEmployeeFragment}
  ${spendLimitParentSpendLimitFragment}
  ${spendLimitHasLimitGroupFragment}
`;

export const spendLimitAssignedCardsFragment = gql`
  fragment SpendLimitAssignedCardsFragment on SpendLimit {
    id
    cardsSummary(
      input: {
        summarizeBy: CARD_BUDGET_OWNERSHIP_TYPE
        summaryItemCardsLimit: 1
        filter: { statusViews: [ACTIVE, LOCKED, WAITING_ACTIVATION] }
      }
    ) {
      items {
        field
        value
        cards {
          id
          last4
          displayName
        }
      }
    }
  }
`;

export const spendLimitUsageFragment = gql`
  fragment SpendLimitUsageFragment on SpendLimit {
    ...SpendLimitAmountSpentFragment
    ...SpendLimitLimitFragment
  }
  ${spendLimitAmountSpentFragment}
  ${spendLimitLimitFragment}
`;

export const spendLimitCreatedAtFragment = gql`
  fragment SpendLimitCreatedAtFragment on SpendLimit {
    createdAt
    timezone
  }
`;

export const spendLimitStartDateFragment = gql`
  fragment SpendLimitStartDateFragment on SpendLimit {
    startDate
  }
`;

export const spendLimitEndDateFragment = gql`
  fragment SpendLimitEndDateFragment on SpendLimit {
    endDate
  }
`;

export const spendLimitDescriptionFragment = gql`
  fragment SpendLimitDescriptionFragment on SpendLimit {
    description
  }
`;

export const spendLimitDatesFragment = gql`
  fragment SpendLimitDatesFragment on SpendLimit {
    ...SpendLimitStartDateFragment
    ...SpendLimitEndDateFragment
    timezone
  }
  ${spendLimitStartDateFragment}
  ${spendLimitEndDateFragment}
`;

export const spendLimitStartTimeFragment = gql`
  fragment SpendLimitStartTimeFragment on SpendLimit {
    startTimeUtc
  }
`;

export const spendLimitEndTimeFragment = gql`
  fragment SpendLimitEndTimeFragment on SpendLimit {
    endTimeUtc
  }
`;

export const spendLimitTimesFragment = gql`
  fragment SpendLimitTimesFragment on SpendLimit {
    ...SpendLimitStartTimeFragment
    ...SpendLimitEndTimeFragment
    timezone
  }
  ${spendLimitStartTimeFragment}
  ${spendLimitEndTimeFragment}
`;

export const spendLimitMemberTotalCountFragment = gql`
  fragment SpendLimitMemberTotalCountFragment on SpendLimit {
    # include page count to avoid conflicts with preview fragment
    members(page: { first: 6 }) {
      totalCount
    }
    userGroup {
      id
      memberCount
    }
  }
`;

export const spendLimitMembersPreviewFragment = gql`
  fragment SpendLimitMembersPreviewFragment on SpendLimit {
    members(page: { first: 6 }) {
      edges {
        node {
          id
          displayName
        }
      }
    }
    userGroup {
      id
      members(page: { first: 6 }) {
        edges {
          node {
            user {
              id
              displayName
            }
          }
        }
      }
    }
    ...SpendLimitMemberTotalCountFragment
  }
  ${spendLimitMemberTotalCountFragment}
`;

export const spendLimitAllowedOperationsFragment = gql`
  fragment SpendLimitAllowedOperationsFragment on SpendLimit {
    allowedOperations(operations: $spendLimitOperations) {
      allowed
      operation
    }
  }
`;

export const spendLimitPolicyFragment = gql`
  fragment SpendLimitPolicyFragment on SpendLimit {
    policy {
      id
      displayName
    }
  }
`;

export const spendLimitBreadcrumbsFragment = gql`
  fragment SpendLimitBreadcrumbsFragment on SpendLimit {
    id
    name
    ancestryPath {
      id
      name
    }
  }
`;

export const spendLimitExistingIncreaseRequestsFragment = gql`
  fragment SpendLimitExistingIncreaseRequestsFragment on SpendLimit {
    limitIncreaseRequests {
      id
      increaseAmount {
        quantity
        instrumentCodeString
      }
      reason
      endDate
      endingStrategy
      request {
        id
        status
        owner {
          id
          customerUserId
        }
      }
    }
  }
`;

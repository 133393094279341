import gql from "graphql-tag";
import {
  billPayOnEmpowerMigratedFragment,
  productConfigFeaturesFragment,
  productConfigFeaturesFragmentV2,
} from "@/data/fragments";
import { useQuery } from "@/features/Apollo";
import { userPropertiesQueryAtoms } from "@/features/Authentication/atoms/userPropertiesQueryAtoms";
import type { UserIntegrationsQuery } from "@/features/Authentication/data/__generated__/queries.generated";
import { useAwaitQueryWithAtoms } from "@/features/Jotai";

// If a user is in Copilot mode, queries on "user" will return the data of the person they're copiloting for.
// We query "userWithoutDelegator" here instead, to ensure this query receives the data of the currently logged-in user.
// See https://docs.google.com/document/d/1lkq2LMG1Z3MA6WxoNsaE6MYRbesrhvGfmmOaZgmjZDI/edit?usp=sharing for context.
export const userPropertiesQuery = gql`
  query UserPropertiesQuery {
    user: userWithoutDelegator {
      id
      role
      userRole
      email
      firstName
      lastName
      depositsRole
      isInitialApplicant
      isInvitedDepositsAdmin
      isInvitedDepositsCashAndCardUser
      helpshiftAuthToken
      hasTransaction
      isManager
      insertedAt
      status
      hasOnboarded
      locale {
        languageTag
      }
      account {
        id
        ct
        dbaName
        legalName
        signupIntent
        hasProductApplication
        financialProductType
        insertedAt
        latestApprovedProductApplicationSubmittedAt
        hasClearedTransaction
        approvedBlueprintAtOnboarding
        riskTier
        initialMarketSegment
        isInternalSignup
        isSandbox
        status
        partnershipIntegrations {
          status
          name
        }
        depositsAccounts(first: 1) {
          edges {
            node {
              id
              status
              activatedAt
            }
          }
        }
      }
    }
    signupPayload {
      id
      attributionToken
    }
    segmentationQuery {
      v4 {
        segmentV4
        subsegmentV4
      }
    }
    cohorts {
      key
    }
    productConfig {
      configurationHome {
        tierV2
      }
      ...ProductConfigFeaturesFragment
      ...ProductConfigFeaturesFragmentV2
    }
    migratedAccount {
      ...BillPayOnEmpowerMigratedFragment
    }
  }
  ${productConfigFeaturesFragment}
  ${productConfigFeaturesFragmentV2}
  ${billPayOnEmpowerMigratedFragment}
`;

export const userIntegrationsQuery = gql`
  query UserIntegrationsQuery {
    integrations(last: 100) {
      edges {
        node {
          id
          vendor
          status
          credential {
            id
            status
          }
        }
      }
    }
  }
`;

export const useInitializeUserPropertiesQueries = () =>
  useAwaitQueryWithAtoms(userPropertiesQuery, {
    atomsWithQuery: userPropertiesQueryAtoms,
  });

export const useUserIntegrationsQuery = () =>
  useQuery<UserIntegrationsQuery>(userIntegrationsQuery, {
    fetchPolicy: "cache-first",
  });

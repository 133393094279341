import { atom } from "jotai";
import { selectAtom } from "jotai/utils";
import { userPropertiesDataAtom } from "./userPropertiesQueryAtoms";
import type { UserProperties } from "@/features/Analytics/sharedTypes";
import { selectUserProperties } from "@/features/Authentication/selectors/selectUserProperties";
import type { UserPropertiesContextProps } from "@/features/Authentication/sharedTypes";

// This atom is used to override the user properties for testing purposes.
const userPropertiesTestAtom = atom<UserProperties | undefined>(undefined);

let userPropertiesCache: UserProperties | undefined = undefined;

export const userPropertiesAtom = atom(
  (get) => {
    const testUserProperties = get(userPropertiesTestAtom);
    const userPropertiesData = get(userPropertiesDataAtom);

    // If we've already resolved the user properties, return the cached value.
    // User properties are not expected to change during the lifetime of the app.
    // However, it's possible that this atom is re-evaluated after a query with user
    // is ran. In that case, we want to return the cached value, instead of putting
    // the atom in a pending state.
    if (userPropertiesCache) {
      return userPropertiesCache;
    }

    if (testUserProperties) {
      return testUserProperties;
    }

    userPropertiesCache = selectUserProperties(userPropertiesData);
    return userPropertiesCache;
  },
  (_, set, overrideValue: UserProperties) => {
    set(userPropertiesTestAtom, overrideValue);
  },
);

export const userPropertiesForContextAtom = selectAtom(
  userPropertiesAtom,
  (data): UserPropertiesContextProps => ({
    userId: data?.id,
    isInitialApplicant: data?.isInitialApplicant,
    accountId: data?.companyId,
    dbaName: data?.companyName,
    userRole: data?.userRole,
    userDisplayName: data?.name,
    userEmail: data?.email,
    segmentV4: data?.segmentV4,
    attributionToken: data?.attributionToken,
    hasPartnershipIntegration: data?.hasPartnershipIntegration,
    partnerName: data?.partnerName,
  }),
);

export const analyticsIdentifiedAtom = atom(new Promise<true>(() => {}));

import deepEqual from "fast-deep-equal";
import { atom } from "jotai";
import { parse } from "@/helpers/queryString";

/**
 * Atom that stays synced with React Router's current `location.search` value.
 */
export const locationSearchAtom = atom<string>("");

/**
 * Atom that stays synced with React Router's current `location.pathname` value.
 */
export const locationPathnameAtom = atom<string>("/");

const internalLocationStateAtom = atom<unknown>(null);

/**
 * Atom that stays synced with React Router's current `location.state` value.
 */
export const locationStateAtom = atom(
  (get) => get(internalLocationStateAtom),
  (get, set, newValue: unknown) => {
    const oldValue = get(internalLocationStateAtom);
    if (!deepEqual(oldValue, newValue)) {
      set(internalLocationStateAtom, newValue);
    }
  },
);

/** Atom that contains an object of parsed `location.search` */
export const queryParamsAtom = atom((get) => parse(get(locationSearchAtom)));

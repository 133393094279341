import { IconSizes } from "@brexhq/component-library";
import {
  AccountingIntegrationSyncStatus,
  CountryAlpha2,
  FinancialInstrumentCode,
  OnboardingApplicationStatus,
} from "@/__generated__/globalTypes";

export const navbarHeight = 56;
export const sidePanelWidth = 224;
export const detailPaneWidth = 540;

export const sideNavPanelWidth = 80;

export const supportPhoneNumber = "+1 (855) 637-2227";
export const supportPhoneNumberLink = "tel:+1-855-637-2227";
export const travelSupportEmail = "support@travel.brex.com";
export const travelVipSupportEmail = "seat1a.support@travel.brex.com";
export const travelSupportPhoneNumber = "+1 (800) 610-7379";
export const travelVipSupportUSPhoneNumber = "+1 (866) 201-7978";
export const travelVipSupportGBPhoneNumber = "+44 80 8134 9566";
export const travelSupportEmailLink = "mailto:support@travel.brex.com";
export const travelVipSupportEmailLink =
  "mailto:seat1a.support@travel.brex.com";
export const travelSupportPhoneNumberLink = "tel:+1-800-610-7379";
export const travelVipSupportUSPhoneNumberLink = "tel:+1 (866) 201-7978";
export const travelVipSupportGBPhoneNumberLink = "tel:+44 80 8134 9566";

/** URLs to the Brex mobile app store listings. */
export const mobileAppLinks = {
  /** Google Play listing URL. */
  ANDROID: "https://play.google.com/store/apps/details?id=com.brex.mobile",
  /** App Store listing URL. */
  IOS: "https://apps.apple.com/us/app/brex/id1472905508",
};

/**
 * Organization IDs used for ACH allow listing.
 * See the “[How do I manage the autopay account for Brex card payments?](https://support.brex.com/how-do-i-manage-the-funding-source-for-brex-card-payments/)” Brex Support article for reference.
 */
export const brexOrganizationIds = {
  WHT: "9121145349",
  JPM: "8195391000",
};

/**
 * FBO organization ID is currently separate from `brexOrganizationIds` because it is behind a feature flag.
 */
export const fboOrganizationIds = [
  "8297979000", //Main FBO
  "9378085000", //Payments FBO
];

export const OVERDUE_SHIPPED_CARD_THRESHOLD_DAYS = 14;
export const ESTIMATED_SHIPMENT_PROCESSED_DAYS = 2;
export const CARD_LIKELY_LOST_DAYS = 31;

export const cardShipmentDateFormat = {
  weekday: "long",
  month: "long",
  day: "numeric",
  inUTC: true,
};

export const cardShipmentDateFormatShort = {
  weekday: "short",
  month: "short",
  day: "numeric",
  inUTC: true,
};

export const taskIconSizeMap: { [size: number]: number } = {
  [IconSizes.MEDIUM]: 48,
  [IconSizes.SMALL]: 29,
};

export const SpotnanaVisitedLocalStorageKey = "__BREX__SPOTNANA_VISITED";

/**
 * Legacy query params carried over from existing connection flows, to be updated/cleaned up in connection flow redesign
 */
export const RouteQueryParams = {
  VENDOR: "vendor",
  RETRY_CONNECT: "retryConnect",
  DISCONNECTED: "disconnected",
  FINISH_SETUP: "integrationFinishSetup",
  SUBSCRIPTION_ISSUE_MODAL_OPEN: "integrationSubscriptionIssueModalOpen",
  QBO_CONNECT_MODAL_OPEN: "qboConnectModalOpen",
  QBO_CREDENTIAL_MODAL_OPEN: "qboCredentialModalOpen",
  FINISH_ENABLE_BILL_SYNC: "integrationFinishEnableBillSync",
  ORIGIN: "origin",
};

export enum IntegrationStatuses {
  processing = "processing",
  active = "active",
  failed = "failed",
  disconnected = "disconnected",
  outdated = "outdated",
  syncing = "syncing",
  refreshing = "refreshing",
  refresh_failed = "refresh_failed",
  disabled = "disabled",
}

export const activeIntegrationStatuses = [
  IntegrationStatuses.active,
  IntegrationStatuses.processing,
  IntegrationStatuses.failed,
  IntegrationStatuses.disconnected,
  IntegrationStatuses.outdated,
  IntegrationStatuses.syncing,
  IntegrationStatuses.refreshing,
  IntegrationStatuses.refresh_failed,
];

// Represents when integration is pulling from (ie, refreshing) or pushing to
// (ie, exporting) ERP
export const processingIntegrationStatuses = [
  IntegrationStatuses.processing,
  IntegrationStatuses.syncing,
  IntegrationStatuses.refreshing,
  AccountingIntegrationSyncStatus.IMPORTING,
  AccountingIntegrationSyncStatus.EXPORTING,
];

export const syncCompleteIntegrationV2Statuses = [
  AccountingIntegrationSyncStatus.EXPORT_FAILED,
  AccountingIntegrationSyncStatus.IMPORT_FAILED,
  AccountingIntegrationSyncStatus.SUCCESS,
];

export const defaultCurrency = {
  instrumentCodeString: FinancialInstrumentCode.USD,
  name: "US Dollar",
};

export const defaultCountry = CountryAlpha2.US;

export const inReviewOnboardingApplicationStatuses: (
  | OnboardingApplicationStatus
  | undefined
  | null
)[] = [
  OnboardingApplicationStatus.SUBMITTED,
  OnboardingApplicationStatus.PROCESSING_EVIDENCES,
  OnboardingApplicationStatus.PENDING_REVIEW,
];

export const emptyFrozenObject = Object.freeze({});
export const emptyFrozenArray = Object.freeze([] as const);

/** String to display for a datagrid or table cell without a value. Do not use this for dropdowns or select components – use a "Select" placeholder value instead for those. */
export const EMPTY_VALUE_STRING = "";

import gql from "graphql-tag";
import { informationRequest, onboardingApplicationV2 } from "@/data/fragments";
import {
  parentBudgetFragmentV2,
  VatExpenseExtendedFieldFragment,
  budgetEmployeesFragment,
  employeeFragment,
  budgetAttributesFromTemplateFragment,
  workflowRuleFragment,
  policyListOptionPolicyFragment,
  automationExtendedFieldFragment,
} from "@/domains/Primitives/shared/data/fragments";
import { internationalWireConfigFragment } from "@/features/InternationalWireConfig/data/fragments";
import {
  budgetMerchantCategoryConfigFragment,
  inheritedMerchantCategoryControlsFragment,
} from "@/features/PolicyState/data/fragments";
import { spendLimitAmountsLiteFragment } from "@/features/SpendLimits/data/fragments";

export const budgetDetailsQuery = gql`
  query BudgetDetailsQuery($id: ID!) {
    budget(id: $id) {
      id
      name
      ownerEmployees {
        edges {
          node {
            id
            customerUserId
          }
        }
      }
    }
  }
`;

export const budgetsQuery = gql`
  query AllExpensesBudgetsQuery(
    $isBudgetChildrenEnabled: Boolean = false
    $filter: BudgetFilterInput
    $order: BudgetOrderInput = { field: NAME, direction: ASCENDING }
  ) {
    budgets(filter: $filter, order: $order) {
      totalCount
      edges {
        node {
          id
          name
          ancestryPath {
            id
            name
          }
          memberEmployees {
            totalCount
          }
          template {
            id
            type
          }
          children(order: $order) @include(if: $isBudgetChildrenEnabled) {
            totalCount
            edges {
              node {
                id
                name
              }
            }
          }
        }
      }
    }
  }
`;

export const globalBudgetIdQuery = gql`
  query GlobalBudgetIdQuery {
    globalBudget {
      id
    }
  }
`;

export const topLevelBudgetsQuery = gql`
  query PrimitivesTopLevelBudgetsQuery($globalBudgetId: ID) {
    budgets(filter: { parentBudgetId: $globalBudgetId }) {
      edges {
        node {
          id
          name
          memberEmployees {
            totalCount
          }
          template {
            id
            type
          }
          startDate
          endDate
        }
      }
    }
  }
`;

export const productApplicationKYCQuery = gql`
  query ProductApplicationKYCQuery($last: Int = 1) {
    user {
      id
    }
    account {
      id
      cardAccountType
      balanceSummary {
        remainingSpend
      }
    }
    onboardingApplications(last: $last, type: KYC) {
      edges {
        node {
          id
          status
          submittedAt
          createdByUser {
            id
            firstName
            lastName
          }
          blueprint {
            id
            name
            type
          }
          state {
            ... on OnboardingApplicationStateProductV1 {
              card
              cash
            }
          }
          informationRequests(first: 50) {
            edges {
              node {
                id
                ...InformationRequest
              }
            }
          }
        }
      }
    }
  }
  ${informationRequest}
`;

export const productApplicationQuery = gql`
  query ProductApplicationQuery {
    user {
      id
    }
    account {
      id
      cardAccountType
      balanceSummary {
        remainingSpend
      }
    }
    kyc: onboardingApplications(last: 1, type: KYC) {
      edges {
        node {
          id
          status
          submittedAt
          createdByUser {
            id
          }
          blueprint {
            id
            name
            type
          }
        }
      }
    }
    productV1: onboardingApplications(last: 1, type: PRODUCT_V1) {
      edges {
        node {
          id
          status
          submittedAt
          createdByUser {
            id
          }
          blueprint {
            id
            name
            type
          }
          state {
            ... on OnboardingApplicationStateProductV1 {
              card
              cash
            }
          }
        }
      }
    }
    productV2: onboardingApplications(last: 2, type: PRODUCT_V2) {
      edges {
        node {
          id
          status
          submittedAt
          createdByUser {
            id
          }
          blueprint {
            id
            name
            type
          }
        }
      }
    }
  }
`;

export const depositsApplicationQuery = gql`
  query DepositsApplicationQuery {
    user {
      id
    }
    onboardingApplications(last: 1, type: DEPOSITS) {
      edges {
        node {
          id
          status
          submittedAt
          createdByUser {
            id
          }
          blueprint {
            id
            name
            type
          }
          state {
            ... on OnboardingApplicationStateProductV1 {
              card
              cash
            }
          }
        }
      }
    }
  }
`;

export const getReimbursementDaysRuleViaPolicyId = gql`
  query GetReimbursementDaysRuleViaPolicyId($policyId: ID!) {
    policy(id: $policyId) {
      id
      ruleDetails {
        nodes {
          __typename
          ... on PolicyRule {
            definition {
              __typename
              ... on PolicyReimbursementRuleDefinition {
                submissionDeadlineDaysAfterPurchase
              }
            }
          }
        }
      }
    }
  }
`;

export const getReimbursementDaysRuleViaBudgetId = gql`
  query GetReimbursementDaysRuleViaBudgetId($budgetId: ID!) {
    budget(id: $budgetId) {
      id
      policy {
        id
        ruleDetails {
          nodes {
            __typename
            ... on PolicyRule {
              definition {
                __typename
                ... on PolicyReimbursementRuleDefinition {
                  submissionDeadlineDaysAfterPurchase
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const hrisConnectionQuery = gql`
  query PrimitivesHrisConnectionQuery($hrisConnectionId: ID!) {
    hrConnection(id: $hrisConnectionId) {
      hrisStatus
      hrisSystem
      hrisSystemName
      id
      syncStatus
      lastSyncAt
      autoOffboardingAvailability
      autoOnboardingAvailability
      userManagementSettings {
        id
        connectionId
        autoOnboardSetting {
          userSetting {
            role
            monthlyLimitAmount {
              quantity
            }
          }
        }
        autoOffboardSetting {
          isEnabled
        }
      }
    }
  }
`;

export const internationalWireConfigQuery = gql`
  query InternationalWireConfigQuery {
    internationalWireConfig {
      ...InternationalWireConfigFragment
    }
  }
  ${internationalWireConfigFragment}
`;

export const getHistoricalExchangeRate = gql`
  query HistoricalExchangeRate(
    $sourceCurrency: String!
    $targetCurrency: String!
    $exchangeRateTime: Time
  ) {
    historicalExchangeRate(
      sourceCurrency: $sourceCurrency
      targetCurrency: $targetCurrency
      exchangeRateTime: $exchangeRateTime
    ) {
      baseCurrency
      quoteCurrency
      rate
      determinedAt
    }
  }
`;

export const getReimbursementExchangeRate = gql`
  query GetReimbursementExchangeRate(
    $sourceCurrency: String!
    $targetCurrency: String!
  ) {
    getExpenseExchangeRate(
      type: REIMBURSEMENT
      sourceCurrencyCode: $sourceCurrency
      targetCurrencyCode: $targetCurrency
    )
  }
`;

export const budgetsDescendantIdsQuery = gql`
  query BudgetsDescendantIdsQuery($ids: [ID!]!) {
    budgetsDescendantIds(ids: $ids) {
      budgetId
      descendantIds
    }
  }
`;

export const budgetsOwnedCountQuery = gql`
  query BudgetsOwnedCountQuery($filter: BudgetFilterInput) {
    budgets(filter: $filter) {
      totalCount
    }
  }
`;

export const budgetTypeQuery = gql`
  query BudgetTypeQuery($id: ID!) {
    budget(id: $id) {
      id
      budgetType
    }
  }
`;

export const budgetRequestQuery = gql`
  query BudgetRequestQuery($id: ID!) {
    budgetRequestEntity(id: $id) {
      id
      version
      name
      description
      limit {
        quantity
        instrumentCodeString
      }
      periodType
      startDate
      endDate
      policy {
        id
      }
      parent {
        id
        ...ParentBudgetFragmentV2
      }
      memberEmployees {
        totalCount
        edges {
          node {
            id
            customerUserId
            displayName
            role
            email
          }
        }
      }
      ownerEmployees {
        totalCount
        edges {
          node {
            id
            customerUserId
            displayName
            role
            email
          }
        }
      }
      merchantCategoryControls {
        ...BudgetMerchantCategoryConfigFragment
      }
      template {
        id
        type
        name
        description
        properties {
          templatePolicyId
          limitIncreaseRequestEnabled
          limitVisibility
          expenseVisibilityShared
          enforceLimit
          enforceLimitBufferPercentage
          expensePolicy {
            id
            currency
            eligibleWorkflows
          }
        }
      }
      templateDerivedMetadata {
        id
        ...BudgetAttributesFromTemplateFragment
      }
      requestedByEmployee {
        ...EmployeeFragment
      }
      request {
        id
        status
        actions(order: { field: EXECUTION_ORDER, order: ASCENDING }) {
          edges {
            node {
              id
              status
              owners {
                employee {
                  id
                  customerUserId
                }
              }
            }
          }
        }
      }
    }
  }
  ${budgetAttributesFromTemplateFragment}
  ${parentBudgetFragmentV2}
  ${budgetMerchantCategoryConfigFragment}
  ${employeeFragment}
`;

export const budgetRequestDetailsQuery = gql`
  query BudgetRequestDetailsQuery($id: ID!) {
    budgetRequestEntity(id: $id) {
      id
      version
      name
      createdBudget {
        id
      }
      description
      limit {
        quantity
        instrumentCodeString
      }
      periodType
      startDate
      endDate
      policy {
        id
        displayName
      }
      parent {
        id
        name
        inheritedMerchantCategoryControls {
          ...InheritedMerchantCategoryControlsFragment
        }
      }
      expenseVisibilityShared
      limitVisibility
      memberEmployees {
        ...BudgetEmployeesFragment
      }
      ownerEmployees {
        ...BudgetEmployeesFragment
      }
      merchantCategoryControls {
        ...BudgetMerchantCategoryConfigFragment
      }
      template {
        id
        name
        type
      }
      templateDerivedMetadata {
        ...BudgetAttributesFromTemplateFragment
      }
      requestedByEmployee {
        ...EmployeeFragment
      }
      requestedByCopilotEmployee {
        ...EmployeeFragment
      }
      commentThread {
        id
        externalEntityAccessLevel
      }
      request {
        createdAt
        overriddenBy {
          ...EmployeeFragment
        }
        overriddenOnBehalfOf {
          ...EmployeeFragment
        }
        overriddenAt
        id
        status
        actions(order: { field: EXECUTION_ORDER, order: ASCENDING }) {
          edges {
            node {
              id
              status
              resolvedAt
              ackedBy {
                employee {
                  ...EmployeeFragment
                }
                owner {
                  employee {
                    ...EmployeeFragment
                  }
                }
              }
              owners {
                __typename
                ... on RequestActionManagerDifference {
                  levelDifference
                }
                employee {
                  ...EmployeeFragment
                }
              }
            }
          }
        }
      }
    }
  }
  ${budgetAttributesFromTemplateFragment}
  ${budgetEmployeesFragment}
  ${employeeFragment}
  ${inheritedMerchantCategoryControlsFragment}
`;

export const isBudgetFromTemplateQuery = gql`
  query IsBudgetFromTemplateQuery($id: ID!) {
    budget(id: $id) {
      id
      name
      template {
        id
        type
        name
      }
      memberEmployees {
        totalCount
      }
      templateDerivedMetadata {
        id
        budgetTemplateCreationMetadata {
          __typename
        }
      }
      parent {
        id
        name
        memberEmployees {
          totalCount
        }
        template {
          id
          type
        }
      }
    }
  }
`;

export const budgetPermissionsQuery = gql`
  query BudgetPermissionsQuery($budgetId: ID!) {
    budgetAnyRequestEntities(
      page: { first: 1 }
      filter: { budgetId: $budgetId, statuses: [PENDING], requestedBy: ME }
    ) {
      edges {
        node {
          __typename
          ... on BudgetLimitIncreaseRequestEntity {
            id
          }
        }
      }
    }
    budget(id: $budgetId) {
      id
      status
      editPermissionGrant
      budgetType
      membership {
        isMember
        isOwner
      }
      limitIncreaseRequestEnabled
      template {
        id
        type
      }
    }
  }
`;

export const financialInstrumentsQuery = gql`
  query FinancialInstrumentsQuery {
    financialInstruments(
      filters: { includeSupportedForHistoricalFxRates: true }
    ) {
      instrumentCodeString
      name
      emoji
      defaultCountries {
        displayName
      }
    }
  }
`;

// Checks ancestors and descendants for budget edits
export const conflictingBudgetMerchantCategoryControlsQuery = gql`
  query ConflictingBudgetMerchantCategoryControlsQuery(
    $merchantCategoryControls: [ConflictingBudgetMerchantCategoryControls!]
  ) {
    conflictingBudgetMerchantCategoryControls(
      merchantCategoryControls: $merchantCategoryControls
    ) {
      merchantCategories {
        id
        name
        budget {
          id
          name
        }
        configs {
          controlReference {
            ... on MerchantCategoryIdControlReference {
              __typename
              merchantCategoryId
              merchantCategoryName
            }
            ... on MerchantNameControlReference {
              __typename
              merchantName
            }
          }
          controlType
        }
      }
      merchants {
        id
        name
        budget {
          id
          name
        }
        configs {
          controlReference {
            ... on MerchantCategoryIdControlReference {
              __typename
              merchantCategoryId
              merchantCategoryName
            }
            ... on MerchantNameControlReference {
              __typename
              merchantName
            }
          }
          controlType
        }
      }
    }
  }
`;

// Checks ancestor only for budget creation
export const conflictingAncestorBudgetMerchantCategoryControlsQuery = gql`
  query ConflictingAncestorBudgetMerchantCategoryControlsQuery(
    $merchantCategoryControls: [ConflictingAncestorBudgetMerchantCategoryControls!]
  ) {
    conflictingAncestorBudgetMerchantCategoryControls(
      merchantCategoryControls: $merchantCategoryControls
    ) {
      merchantCategories {
        id
        name
        budget {
          id
          name
        }
        configs {
          controlReference {
            ... on MerchantCategoryIdControlReference {
              __typename
              merchantCategoryId
              merchantCategoryName
            }
            ... on MerchantNameControlReference {
              __typename
              merchantName
            }
          }
          controlType
        }
      }
      merchants {
        id
        name
        budget {
          id
          name
        }
        configs {
          controlReference {
            ... on MerchantCategoryIdControlReference {
              __typename
              merchantCategoryId
              merchantCategoryName
            }
            ... on MerchantNameControlReference {
              __typename
              merchantName
            }
          }
          controlType
        }
      }
    }
  }
`;

// Checks ancestors and descendants for budget edits
export const conflictingBudgetRequestMerchantCategoryControlsQuery = gql`
  query ConflictingBudgetRequestMerchantCategoryControlsQuery(
    $merchantCategoryControls: [ConflictingBudgetRequestMerchantCategoryControls!]
  ) {
    conflictingBudgetRequestMerchantCategoryControls(
      merchantCategoryControls: $merchantCategoryControls
    ) {
      merchantCategories {
        id
        name
        budget {
          id
          name
        }
        configs {
          controlReference {
            ... on MerchantCategoryIdControlReference {
              __typename
              merchantCategoryId
              merchantCategoryName
            }
            ... on MerchantNameControlReference {
              __typename
              merchantName
            }
          }
          controlType
        }
      }
      merchants {
        id
        name
        budget {
          id
          name
        }
        configs {
          controlReference {
            ... on MerchantCategoryIdControlReference {
              __typename
              merchantCategoryId
              merchantCategoryName
            }
            ... on MerchantNameControlReference {
              __typename
              merchantName
            }
          }
          controlType
        }
      }
    }
  }
`;

export const kycApplicationQuery = gql`
  query KYCApplicationQuery($identifier: LegalEntityIdentifier!) {
    legalEntity(identifier: $identifier) {
      id
      country {
        name
        displayName
      }
      displayName
      latestOnboardingApplication {
        id
        ...OnboardingApplicationV2
      }
    }
  }
  ${onboardingApplicationV2}
`;

export const vatExpenseExtendedFieldsQuery = gql`
  query VatExpenseExtendedFieldsQuery(
    $legalEntityIds: [ID!]
    $groups: [ExtendedFieldGroup!]
  ) {
    extendedFields(
      filter: {
        fieldOptionFilterInput: {
          fieldOptionViews: [INCLUDE_DISABLED, INCLUDE_DELETED]
        }
        groups: $groups
        sourceObjectTypes: [EXPENSE]
        legalEntityIds: $legalEntityIds
      }
    ) {
      edges {
        node {
          id
          # eslint-disable-next-line @graphql-eslint/require-id-when-available
          ...VatExpenseExtendedFieldFragment
        }
      }
    }
  }
  ${VatExpenseExtendedFieldFragment}
`;

export const accountingTaxCodeLegalEntityQuery = gql`
  query AccountingTaxCodeLegalEntityQuery($identifier: LegalEntityIdentifier!) {
    legalEntity(identifier: $identifier) {
      id
      country {
        alpha2
      }
    }
  }
`;

export const accountInfoQuery = gql`
  query AccountQuery {
    account {
      id
      dbaName
      legalName
      status
      systemSettings {
        showCashUpsell
      }
    }
    productConfig {
      configurationHome {
        tierV2
      }
    }
  }
`;

export const recentBudgetAnyRequestsQuery = gql`
  query RecentBudgetAnyRequestsQuery(
    $page: PaginationInput!
    $filter: BudgetRequestEntityFilterInput!
  ) {
    budgetRequests: budgetAnyRequestEntities(page: $page, filter: $filter) {
      __typename
      totalCount
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        cursor
        node {
          __typename
          ... on BudgetRequestEntity {
            id
            name
            description
            limit {
              instrumentCodeString
              quantity
            }
            request {
              id
              createdAt
              status
            }
            requestedByEmployee {
              id
              displayName
            }
          }
          ... on BudgetLimitIncreaseRequestEntity {
            id
            budget {
              id
              name
              periodType
              startDate
            }
            reason
            increaseAmount {
              instrumentCodeString
              quantity
            }
            endDate
            endingStrategy
            version
            request {
              id
              createdAt
              status
              requestedByEmployee {
                id
                displayName
                customerUserId
              }
            }
          }
        }
      }
    }
  }
`;

export const hasBudgetOffboardingActionsQuery = gql`
  query HasBudgetsOffboardingActionsQuery {
    hasBudgetsOffboardingActions
  }
`;

export const getFspClientsQuery = gql`
  query GetFSPClients($status: FspConnectionStatus, $fspUserId: ID) {
    getFSPClients(status: $status, fspUserId: $fspUserId) {
      customerAccountId
      name
      contact
      status
      connectedUsers {
        id
        firstName
        lastName
        fspUserRoles
      }
    }
  }
`;

export const getClientFspsQuery = gql`
  query GetClientFsps {
    getClientFSPs {
      connectedUsers {
        id
        fspUserFirstName
        fspUserLastName
      }
      fspCustomerAccountId
      name
      description
      logoLink
      detailsLink
      status
    }
  }
`;

export const fetchSpendLimitsForWalletQuery = gql`
  query FetchSpendLimitsForWalletQuery(
    $filter: SpendLimitFilterInput
    $order: SpendLimitOrderInput
    $page: PaginationInput
    $customerUserIds: [ID!]
  ) {
    spendLimits(filter: $filter, page: $page, order: $order) {
      edges {
        node {
          id
          name
          status
          expenseVisibility
          ...SpendLimitAmountsLiteFragment
          legacyBudgetTemplate {
            id
            name
            type
          }
          parentBudget {
            id
            name
          }
          cards(
            filter: {
              cardBudgetOwnershipTypes: [BUDGET]
              customerUserIds: $customerUserIds
              hasBeenTransferred: false
            }
            page: { first: 1 }
          ) {
            edges {
              node {
                id
              }
            }
          }
        }
      }
      totalCount
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
  ${spendLimitAmountsLiteFragment}
`;

export const userCanCreateCardQuery = gql`
  query UserCanCreateCardQuery($userId: ID!) {
    isWhiteListedForAutoPay
    user(id: $userId) {
      id
      canAccessCorporateCards
      account {
        id
        status
        hasCustomerSelectedAutopayFundingSource
      }
    }
  }
`;

export const userLimitStatusQuery = gql`
  query UserLimitStatusQuery($userId: ID!) {
    migrationTargetUser(id: $userId) {
      id
      userLimitStatus
    }
  }
`;

export const selfApprovalGuardrailsEnabledQuery = gql`
  query SelfApprovalGuardrailsEnabledQuery {
    selfApprovalGuardrailsEnabled
  }
`;

export const invitableUserCountQuery = gql`
  query InvitableUserCountQuery($condition: UserConditionInput) {
    invitableUserCount(condition: $condition)
  }
`;

export const userIsBudgetOwnerQuery = gql`
  query UserIsBudgetOwnerQuery(
    $userId: ID
    $budgetTypeFilter: [BudgetType!]
    $statusesFilter: [BudgetStatus!] = [APPROVED, EXPIRED, TERMINATED]
  ) {
    budgets(
      filter: {
        owners: { userIds: [$userId] }
        statuses: $statusesFilter
        budgetTypes: $budgetTypeFilter
      }
      page: { first: 1 }
    ) {
      edges {
        node {
          id
        }
      }
    }
  }
`;

export const getCommentTaskQuery = gql`
  query CommentTaskQuery(
    $taskIds: [String!]
    $entityIds: [String!]
    $taskTypeFilters: [InboxTaskType!]
    $taskStates: [InboxTaskState!]
  ) {
    inboxTasksV2(
      filter: {
        taskIds: $taskIds
        entityIds: $entityIds
        taskTypesFilter: $taskTypeFilters
        taskStates: $taskStates
      }
    ) {
      edges {
        node {
          id
          encodedId
          taskType
          taskState
          taskEntityDetails {
            __typename
            encodedEntityId
            ... on CommentResponseTaskEntityDetails {
              externalEntityId
              externalEntityType
            }
          }
        }
      }
    }
  }
`;

export const hasCardQuery = gql`
  query HasCardQuery {
    user {
      id
      cards(first: 1) {
        edges {
          node {
            id
          }
        }
      }
    }
  }
`;

export const policiesQuery = gql`
  query PoliciesQuery(
    $filter: PolicyFilterInput
    $page: PaginationInput
    $order: PolicyOrderInput
  ) {
    policies(filter: $filter, page: $page, order: $order) {
      __typename
      edges {
        node {
          id
          ...PolicyListOptionPolicyFragment
        }
        __typename
      }
      totalCount
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
  ${policyListOptionPolicyFragment}
`;

export const bulkEditTaskQuery = gql`
  query BulkEditTaskQuery($id: ID!) {
    task(id: $id) {
      id
      status
      response {
        ... on BulkOperationResponse {
          errors {
            error {
              message
            }
          }
        }
      }
    }
  }
`;

export const AccountAutoPayWhiteListQuery = gql`
  query onboardingUserAutoPayWhiteList {
    isWhiteListedForAutoPay
  }
`;

// reimbursementMerchantCategorySearch
export const semanticMerchantCategorySearchQuery = gql`
  query ReimbursementMerchantCategorySearch($filter: String!, $limit: Int!) {
    entityRows(
      filter: {
        filter: {
          filterCondition: {
            vendorCategoryFieldMatcher: { similaritySearch: $filter }
          }
        }
      }
      entityType: VENDOR_CATEGORY
      page: { first: $limit }
      sorts: {
        fieldKey: {
          key: { vendorCategoryPrimitiveFieldKey: VENDOR_CATEGORY_NAME }
        }
        direction: ASCENDING
      }
    ) {
      edges {
        node {
          entity {
            __typename
            ... on VendorCategory {
              id
              name
            }
          }
        }
      }
    }
  }
`;

export const shouldDepositFundsQuery = gql`
  query ShouldDepositFundsQuery {
    account {
      id
      depositsAccounts(first: 1) {
        edges {
          cursor
          node {
            id
            canAddFunds
            balance
            transactions(first: 5) {
              edges {
                node {
                  id
                  amount
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const createAccountingMappingMutation = gql`
  mutation CreateAccountingMappingMutation($input: CreateWorkflowRuleInput!) {
    createWorkflowRule(input: $input) {
      rule {
        ...WorkflowRuleFragment
      }
    }
  }
  ${workflowRuleFragment}
`;

export const accountingAutomationMappingsConfigQuery = gql`
  query AccountingAutomationMappingsConfigQuery(
    $includeCustomFields: Boolean = false
    $includeIntegrationFields: Boolean = false
    $customFieldGroups: [ExtendedFieldGroup!]
    $integrationTags: [ExtendedFieldTagFilterInput!]
    $limit: Int = 100
    $sourceObjectTypes: [SourceObjectType]
    $tags: [ExtendedFieldTagFilterInput!]
  ) {
    extendedFields(
      filter: {
        fieldOptionFilterInput: { returnOptions: EXCLUDE }
        groups: $customFieldGroups
        sourceObjectTypes: $sourceObjectTypes
        tags: $tags
      }
      page: { first: $limit }
    ) @include(if: $includeCustomFields) {
      edges {
        node {
          ...AutomationExtendedFieldFragment
        }
      }
    }
    integrationFields: extendedFields(
      filter: {
        fieldOptionFilterInput: { returnOptions: EXCLUDE }
        groups: [INTEGRATION]
        sourceObjectTypes: [ACCOUNTING_RECORD, EXPENSE]
        tags: $integrationTags
      }
      page: { first: 200 }
    ) @include(if: $includeIntegrationFields) {
      edges {
        node {
          ...AutomationExtendedFieldFragment
        }
      }
    }
  }
  ${automationExtendedFieldFragment}
`;

export const vendorFileMatchOwnerQuery = gql`
  query VendorFileMatchOwnerQuery($vendorFileMatchId: ID!) {
    vendorFileMatch(id: $vendorFileMatchId) {
      id
      vendorOwner {
        id
      }
      migrationStatus
    }
  }
`;

export const hasVendorFileMatchQuery = gql`
  query HasVendorFileMatchQuery {
    vendorFileMatches(page: { first: 1 }) {
      totalCount
    }
  }
`;

export const isPaymentAccountsMappingEnabledQuery = gql`
  query IsPaymentAccountsMappingEnabledQuery(
    $vendorToEnable: AccountingIntegrationVendor
  ) {
    isPaymentAccountsMappingEnabled(vendorToEnable: $vendorToEnable) {
      isEnabled
    }
  }
`;

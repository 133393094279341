import gql from "graphql-tag";

export const financialAccountFragment = gql`
  fragment FinancialAccountFragment on FinancialAccount {
    id
    details {
      accountNumberLast4
      routingNumberLast4
      type
    }
    latestBalance {
      id
      amountLong
      accrualTime
    }
    source {
      id
      institution {
        id
        name
      }
    }
    achDebitCapability
  }
`;

// Note: this fragment should only contain non-dynamic fields on the cashTransaction (safe to batch)
export const cashTransactionFragment = gql`
  fragment CashTransactionFragment on CashTransaction {
    id
    amountUsdCents
    type
    status
    origin
    displayName
    cancellationReason
    processedBalanceSnapshotUsdCents
    customerMemo
    notifyContactProcessing
    isRecurring
    bill {
      id
    }
    details {
      __typename
      ... on CashTransactionAchDetails {
        accountClass
        accountNumber
        accountType
        transferDescription
        routingNumber
        email
        paymentMethod
        companyId
        beneficiaryBankLogoBase64
        beneficiaryBank {
          name
          info
        }
      }
      ... on CashTransactionDomesticWireDetails {
        accountNumber
        beneficiaryBank {
          name
          info
        }
        beneficiaryBankLogoBase64
        email
        fedReferenceNumber
        imad
        omad
        paymentMethod
        routingNumber
        transferDescription
      }
      ... on CashTransactionInternationalWireDetails {
        accountIdentifier {
          type
          value
        }
        beneficiaryBank {
          info
          name
        }
        countryCodeAlpha2
        countryName
        email
        mt103
        paymentMethod
        swiftCode
        trackingNumber
        transferDescription
      }
      ... on CashTransactionOutgoingChequeDetails {
        chequeMemo
        chequeNumber
        email
        expectedDeliveryDate
        mailedAt
        recipientName
      }
      ... on CashTransactionPayrollEstimateDetails {
        payrollEndDate
        payrollProviderName
        payrollStartDate
      }
    }
  }
`;

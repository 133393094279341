import { useSetAtom } from "jotai";
import * as React from "react";
import { useLocation } from "react-router-dom";
import {
  locationSearchAtom,
  locationPathnameAtom,
  locationStateAtom,
} from "@/helpers/atoms/locationAtoms";

/** Internal component that sync React Router location with our location atoms */
export const LocationAtomSync: React.VFC = () => {
  const { pathname, search, state } = useLocation();

  const setPathname = useSetAtom(locationPathnameAtom);
  const setSearch = useSetAtom(locationSearchAtom);
  const setState = useSetAtom(locationStateAtom);

  React.useEffect(() => void setPathname(pathname), [pathname, setPathname]);
  React.useEffect(() => void setSearch(search), [search, setSearch]);
  React.useEffect(() => void setState(state), [state, setState]);

  return null;
};

import LogRocket from "logrocket";
import { v4 as uuid } from "uuid";
import { getWorkflowId } from "@/features/Analytics/helpers/workflowSessionManager";
import { isOperationExemptFromAuthentication } from "@/features/Apollo/helpers/authExemptRequests";
import { getProductSurface } from "@/features/Apollo/helpers/contextHelpers";
import type { GqlContext, GqlClientName } from "@/features/Apollo/sharedTypes";
import { useAuthenticationContext } from "@/features/Authentication/contexts/Authentication";
import { useCopilot } from "@/features/Copilot/hooks/useCopilot";
import { inSyntheticTest } from "@/helpers/environment";
import { getPersistedStateValue } from "@/hooks/useLocalPersistedState";
import { LocalPersistedStateKeys } from "@/hooks/useLocalPersistedState/types";
import { useRefCallback } from "@/hooks/useRefCallback";

const filterNullishRecordEntries = <T extends Record<string, unknown>>(
  value: T,
): T =>
  Object.fromEntries(Object.entries(value).filter(([, v]) => v != null)) as T;

const getBaggageHeader = () => {
  const routingKey = getPersistedStateValue(
    LocalPersistedStateKeys.DEV_STEED_ROUTING_KEY,
  );

  if (!routingKey) return undefined;

  // Support either sd-routing-key=abc123 or just abc123
  const key = routingKey.toString().trim();
  const baggageValue = key.startsWith("sd-routing-key=")
    ? key
    : `sd-routing-key=${key}`;

  return baggageValue;
};

const getBrexRequestId = () => {
  const shouldSampleTraces = getPersistedStateValue(
    LocalPersistedStateKeys.SAMPLE_TRACES,
  );

  if (!shouldSampleTraces) return uuid();
  return "sample-" + uuid();
};

type GetCustomHeadersArgs = {
  isMutation: boolean;
  controllerName: string | undefined;
  productSurfaceSuffix: string | undefined;
  delegatorUserId: string | undefined;
  graphqlClient: GqlClientName;
  accessToken: string | undefined;
  requestId: string | undefined;
};

const getCustomHeaders = ({
  isMutation,
  controllerName,
  productSurfaceSuffix,
  delegatorUserId,
  graphqlClient,
  accessToken,
  requestId = getBrexRequestId(),
}: GetCustomHeadersArgs): NonNullable<GqlContext["headers"]> => {
  return filterNullishRecordEntries({
    "x-is-synthetic": String(inSyntheticTest()),
    "x-brex-request-id": requestId,
    "x-brex-workflow-id": getWorkflowId(),
    // Language and time zone headers used for localization
    "accept-language": navigator.language,
    "time-zone": Intl.DateTimeFormat().resolvedOptions().timeZone,
    Baggage: getBaggageHeader(),
    "x-brex-product-surface": isMutation
      ? getProductSurface({ controllerName, productSurfaceSuffix })
      : undefined,
    "x-logrocket-url": LogRocket.sessionURL ?? undefined,
    "x-on-behalf-of-user-id": delegatorUserId,
    "x-graphql-client": graphqlClient,
    authorization: accessToken ? `Bearer ${accessToken}` : undefined,
  });
};

export const useGetCustomHeaders = () => {
  const { getAccessToken } = useAuthenticationContext();
  const { getDelegatorUserId } = useCopilot();
  return useRefCallback(function augmentedGetCustomHeaders({
    operationName,
    ...args
  }: Omit<GetCustomHeadersArgs, "delegatorUserId" | "accessToken"> & {
    operationName: string | undefined;
  }) {
    return getCustomHeaders({
      delegatorUserId: getDelegatorUserId(),
      accessToken: isOperationExemptFromAuthentication({ operationName })
        ? undefined
        : (getAccessToken() ?? undefined),
      ...args,
    });
  });
};

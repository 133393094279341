import { OverlayAppElementProvider } from "@brexhq/component-library";
import { ConfirmationDialogContainer } from "@brexhq/metal-dialog";
import { ToastContainer } from "@brexhq/metal-toast";
import { BodyScrollProvider } from "@brexhq/metal-utils";
import { datadogRum } from "@datadog/browser-rum";
import { useStore } from "jotai";
import { useHydrateAtoms } from "jotai/utils";
import * as React from "react";
import { Router } from "./Router";
import { TrackedErrorBoundary } from "@/components/Error";
import { CurrencyProvider } from "@/domains/App/contexts/Currency";
import { useSetFirstLoadAtom } from "@/domains/App/contexts/FirstLoadStatus";
import { useSetOriginalLocationAtom } from "@/domains/App/contexts/OriginalLocation";
import { initializeLocalization } from "@/domains/App/features/Localization/helpers/initializeLocalization";
import { MaintenancePage } from "@/domains/App/features/MaintenancePage";
import { useSardine } from "@/domains/Onboarding/hooks/useSardine";
import { useAnalytics } from "@/features/Analytics/contexts/Analytics";
import { useTrackLoadMountPerformance } from "@/features/Analytics/helpers/performance";
import { saveReferralProperties } from "@/features/Analytics/helpers/referrals";
import { BrexDataProvider } from "@/features/Apollo/components/BrexDataProvider";
import { useInitializeCopilotAtom } from "@/features/Copilot/hooks/useInitializeCopilotAtom";
import { _debug_queryTimeout } from "@/features/Jotai";
import { useFeatureFlags } from "@/features/LaunchDarkly/hooks/useFeatureFlags";
import { ServerTimeProvider } from "@/features/ServerTime/contexts/ServerTime";
import { UnauthorizedLayoutLoading } from "@/features/UnauthorizedLayout";

const AppContent = () => {
  const { enableMaintenancePage } = useFeatureFlags();
  useSardine();
  return enableMaintenancePage ? <MaintenancePage /> : <Router />;
};

initializeLocalization();

/**
 * !!! DO NOT ADD ANY EFFECTS THAT WATCH LOCATION AS IT WILL CAUSE TONS OF
 * RE-RENDERS DOWNSTREAM !!!
 */
const AppWithoutLocationEffects = React.memo(() => {
  const { initialized: copilotInitialized } = useInitializeCopilotAtom();
  const {
    identify: analyticsIdentify,
    initialize: analyticsInitialize,
    shutdown: analyticsShutdown,
  } = useAnalytics();
  /**
   * The Dashboard is considered initialized after third-party analytics are
   * loaded/initialized, and an anonymous user is identified.
   */
  const [initialized, setInitialized] = React.useState(false);

  /**
   * The OverlayAppElementProvider assists ReactModal in knowing where the main
   * app content is located. ReactModal sets aria-hidden="true" on this element
   * so that screen readers can navigate the modal content. We're letting the
   * Overlay using ReactModal know that our overlayAppElement is the div with
   * id="root" in our index.html file. In the unexpected case it cannot be
   * found, we'll fall back to using body.
   *
   * See https://reactcommunity.org/react-modal/accessibility/ for details
   */
  const overlayAppElement = document.getElementById("root") || "body";

  const store = useStore();
  useHydrateAtoms(
    [
      [
        _debug_queryTimeout,
        {
          duration: 60000,
          onTimeout: ({ operationName }: { operationName: string }) => {
            // eslint-disable-next-line no-console
            console.log("Query took too long", operationName);
            datadogRum.addAction("longHangingQuery", {
              opName: operationName,
            });
          },
        },
      ],
    ] as const,
    { store },
  );

  React.useEffect(() => {
    (async function initializeAnalytics() {
      await analyticsInitialize();
      // identify initial (anonymous) user
      await analyticsIdentify(undefined, {
        ...saveReferralProperties(),
      });
      setInitialized(true);
    })();

    return () => {
      // cleanup analytics resources on unmount
      analyticsShutdown();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return !initialized || !copilotInitialized ? (
    <UnauthorizedLayoutLoading />
  ) : (
    /**
     * Only add providers that are necessary for unauthenticated routes.
     * Otherwise, this slows down the auth checks and redirects.
     *
     * DO NOT ADD PROVIDERS THAT QUERY THE GRAPHQL API!!!
     *
     * Authentication has not occurred yet and most queries will silently fail.
     *
     * If a provider queries the GraphQL API at any point in its lifecycle,
     * add it to AuthenticatedRouterController before AuthenticatedRouter.
     * */
    <React.Suspense fallback={<UnauthorizedLayoutLoading />}>
      <TrackedErrorBoundary __DROP_TRACK_ORIGIN="App">
        <ToastContainer />
        <BrexDataProvider>
          <OverlayAppElementProvider overlayAppElement={overlayAppElement}>
            <BodyScrollProvider>
              <CurrencyProvider>
                <ServerTimeProvider>
                  <ConfirmationDialogContainer />
                  <AppContent />
                </ServerTimeProvider>
              </CurrencyProvider>
            </BodyScrollProvider>
          </OverlayAppElementProvider>
        </BrexDataProvider>
      </TrackedErrorBoundary>
    </React.Suspense>
  );
});
AppWithoutLocationEffects.displayName = "memo(AppWithoutLocationEffects)";

/**
 * Hooks that are only used for side-effects should be placed here.
 */
const WithAppEffects = ({ children }: React.PropsWithChildren<{}>) => {
  useTrackLoadMountPerformance("app.main_app");
  useSetOriginalLocationAtom();
  useSetFirstLoadAtom();

  return <>{children}</>;
};

export const App: React.VFC = React.memo(function App() {
  return (
    <WithAppEffects>
      <AppWithoutLocationEffects />
    </WithAppEffects>
  );
});

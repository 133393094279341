export enum AnalyticsEvents {
  TravelSupportChatOpened = "travel.support.chat.open",
  TravelSupportChatClosed = "travel.support.chat.closed",
  TravelSupportChatMinimized = "travel.support.chat.minimized",
  TravelSupportChatBubbleClicked = "travel.support.chat.bubble.clicked",
  TravelSupportChatLogoutError = "travel.support.chat.logout.error",
  TravelSupportChatInitialized = "travel.support.chat.initialized",
  TravelSupportChatCleared = "travel.support.chat.clear",

  TravelAccountTermsModalViewed = "travel.account-terms-modal.viewed",
  TravelAccountTermsModalEnabledTravel = "travel.account-terms-modal.enabled-travel",
  TravelAccountTermsModalDeferredTravelEnablement = "travel.account-terms-modal.deferred-travel-enablement",
  TravelAccountTermsModalDismissed = "travel.account-terms-modal.dismissed",
  TravelAccountTermsModalEnableError = "travel.account-terms-modal.enable-error",
  TravelAccountTermsModalDeferError = "travel.account-terms-modal.derer-error",

  TravelUserConsentModalViewed = "travel.user-consent-modal.viewed",
  TravelUserConsentModalAccepted = "travel.user-consent-modal.accepted",
  TravelUserConsentModalDismissed = "travel.user-consent-modal.dismissed",
  TravelUserConsentModalError = "travel.user-consent-modal.error",

  TravelBookingBackClicked = "travel.booking.back-clicked",
  TravelBookingCreateTripClicked = "travel.booking.create-trip-clicked",
  TravelBookingCreateTripSuccess = "travel.booking.create-trip-success",
  TravelBookingPageClosed = "travel.booking.page-closed",
  TravelBookingPageViewed = "travel.booking.page-viewed",
  TravelBookingApprovalBannerViewed = "travel.booking.approval-banner-viewed",
  TravelBookingApprovalBannerError = "travel.booking.approval-banner-error",
  TravelBookingApprovalHistoryViewed = "travel.booking.approval-history-viewed",
  TravelBookingApprovalHistoryError = "travel.booking.approval-history-error",
  TravelBookingApprovalApproveClicked = "travel.booking.approval-approve-clicked",
  TravelBookingApprovalApproveSuccess = "travel.booking.approval-approve-success",
  TravelBookingApprovalApproveError = "travel.booking.approval-approve-error",
  TravelBookingApprovalRejectClicked = "travel.booking.approval-reject-clicked",
  TravelBookingApprovalRejectSuccess = "travel.booking.approval-reject-success",
  TravelBookingApprovalRejectError = "travel.booking.approval-reject-error",
  TravelBookingApprovalUndoClicked = "travel.booking.approval-undo-clicked",
  TravelBookingApprovalUndoSuccess = "travel.booking.approval-undo-success",
  TravelBookingApprovalUndoError = "travel.booking.approval-undo-error",

  TravelBookingSuccessResultSuccess = "travel.booking-success.result-success",
  TravelBookingSuccessResultError = "travel.booking-success.result-error",

  TravelBookingSuccessScreenViewed = "travel.booking-success.page-viewed",
  TravelBookingSuccessScreenClosed = "travel.booking-success.page-closed",
  TravelBookingSuccessScreenErrorRedirect = "travel.booking-success-screen.error-redirect",
  TravelBookingSuccessScreenViewTripDetailsClicked = "travel.booking-success-screen.view-trip-details-clicked",
  TravelBookingSuccessScreenAddBookingClicked = "travel.booking-success-screen.add-booking-clicked",

  TravelSNIframeMounted = "travel.spotnana-iframe.mounted",

  TravelDowntimeBannerViewed = "travel.maintenance.banner.viewed",

  TravelGroupEventCreateEventFormSubmitClicked = "travel.group-event.create-form.submit-clicked",
  TravelGroupEventAttendeeDetailsPaneConfirmAttendanceClicked = "travel.group-event.attendee-details.confirm-attendance-clicked",
  TravelGroupEventAttendeeDetailsPaneEditAttendanceClicked = "travel.group-event.attendee-details.edit-attendance-clicked",
  TravelGroupEventAttendeeDetailsPaneAcceptInvitationClicked = "travel.group-event.attendee-details.accept-invitation-clicked",
  TravelGroupEventAttendeeDetailsPaneDeclineInvitationClicked = "travel.group-event.attendee-details.decline-invitation-clicked",
  TravelGroupEventDetailsPageViewed = "travel.group-event.details.page-viewed",
  TravelGroupEventDetailsPageEdit = "travel.group-event.details.edit",
  TravelGroupEventActionsBuildGuestList = "travel.group-event.details.actions.build-guest-list",
  TravelGroupEventActionsSendInvites = "travel.group-event.details.actions.send-invites",
  TravelGroupEventActionsSendReminders = "travel.group-event.details.actions.send-reminders",
  TravelGroupEventActionsHotelRoomBlocks = "travel.group-event.details.actions.hotel-room-blocks",
  TravelGroupEventActionsRequestBudget = "travel.group-event.details.actions.request-budget",
  TravelGroupEventActionsViewBudget = "travel.group-event.details.actions.view-budget",
  TravelGroupEventActionsViewBudgetRequest = "travel.group-event.details.actions.view-budget-request",
  TravelGroupEventActionsViewPolicy = "travel.group-event.details.actions.view-policy",
  TravelGroupEventEditPaneCancel = "travel.group-event.details.edit-pane.cancel",
  TravelGroupEventEditPaneDelete = "travel.group-event.details.edit-pane.delete",
  TravelGroupEventAttendeeTableViewGuestRequirements = "travel.group-event.details.attendees-table.view-guest-requirements",
  TravelGroupEventAttendeeTableViewAttendeeDetails = "travel.group-event.details.attendees-table.view-attendee-details",
  TravelGroupEventAttendeeTableFilterAttendeeName = "travel.group-event.details.attendees-table.filter-attendee-name",
  TravelGroupEventAttendeeTableFilterAttendanceStatus = "travel.group-event.details.attendees-table.filter-attendance-status",
  TravelGroupEventAttendeeTableFilterBookingStatus = "travel.group-event.details.attendees-table.filter-booking-status",
  TravelGroupEventAttendeeTableFilterGuestRequirements = "travel.group-event.details.attendees-table.filter-guest-requirements",
  TravelGroupEventAttendeeTableRemove = "travel.group-event.details.attendees-table.remove",
  TravelGroupEventAttendeeTableSendInvite = "travel.group-event.details.attendees-table.send-invite",
  TravelGroupEventAttendeeTableSendReminder = "travel.group-event.details.attendees-table.send-reminder",
  TravelGroupEventAttendeeDetailsPaneSendBookingReminder = "travel.group-event.details.attendee-details.send-booking-reminder",
  TravelGroupEventAttendeeDetailsPaneSendRsvpReminder = "travel.group-event.details.attendee-details.send-rsvp-reminder",
  TravelGroupEventAttendeeDetailsPaneSendInvite = "travel.group-event.details.attendee-details.send-invite",
  TravelGroupEventAttendeeDetailsPaneRemove = "travel.group-event.details.attendee-details.remove",

  TravelTripsPageDraftTripViewClicked = "travel.trips-page.draft-trip-view-clicked",
  TravelTripsPageDraftTripDeleteClicked = "travel.trips-page.draft-trip-delete-clicked",
  TravelBookingPageRequestBudgetClicked = "travel.booking-page.request-budget-clicked",
  TravelBudgetRequestPageCreateBudgetClicked = "travel.budget-request-page.create-budget-clicked",
  TravelBudgetRequestPageCreateBudgetSuccess = "travel.budget-request-page.create-budget-success",

  TravelTripsExpandedTripCardClicked = "travel.trips.expanded-trip-card-clicked",
  TravelTripsDetailsPaneClicked = "travel.trips.details-pane-clicked",
  TravelTripsGroupEventDetailsPaneClicked = "travel.trips.group-event-details-pane-clicked",
  TravelTripsGroupEventRsvpDetailsPaneClicked = "travel.trips.group-event-rsvp-details-pane-clicked",
  TravelTripsTripsListPaneClicked = "travel.trips.trips-list-pane-clicked",

  TravelBookingSearchFormMounted = "travel.booking-search-form.mounted",
  TravelBookingSearchFormPageMounted = "travel.booking-search-form-page.mounted",

  TravelBookingSearchFormSearchClicked = "travel.booking-search-form.search-clicked",
  TravelBookingSearchFormSearchWithTripClicked = "travel.booking-search-form.with-trip.search-clicked",
  TravelBookingSearchFormSearchSuccess = "travel.booking-search-form.search-success",
  TravelBookingSearchFormSearchError = "travel.booking-search-form.search-error",
  TravelBookingSearchFormTransformFlightParams = "travel.booking-search-form.transform-flight-params",
  TravelBookingSearchFormTransformHotelParams = "travel.booking-search-form.transform-hotel-params",
  TravelBookingSearchFormTransformRailParams = "travel.booking-search-form.transform-rail-params",
  TravelBookingSearchFormTransformCarParams = "travel.booking-search-form.transform-car-params",

  TravelBookingPaneViewed = "travel.booking-pane.viewed",
  TravelBookingPaneClosed = "travel.booking-pane.closed",
  TravelBookingPaneErrorViewed = "travel.booking-pane.error-viewed",

  TravelBookingSearchFormViewed = "travel.booking-search-form.viewed",
  TravelBookingSearchFormClosed = "travel.booking-search-form.closed",

  TravelTripDetailsPaneViewed = "travel.trip-details-pane.viewed",
  TravelTripDetailsPaneClosed = "travel.trip-details-pane.closed",
  TravelTripDetailsPaneErrorViewed = "travel.trip-details-pane-error.viewed",
  TravelTripDetailsPaneErrorRetryClicked = "travel.trip-details-pane-error.retry-clicked",
  TravelTripDetailsPaneBackClicked = "travel.trip-details-pane.back-clicked",
  TravelTripDetailsRequestReimbursementClicked = "travel.trip-details-pane.req-reimbursement-clicked",
  TravelTripDetailsViewReimbursementClicked = "travel.trip-details-pane.view-reimbursement-clicked",
  TravelTripDetailsPaneFlightReservationClicked = "travel.trip-details-pane.flight-reservation-clicked",
  TravelTripDetailPaneRailReservationClicked = "travel.trip-details-pane.rail-reservation-clicked",
  TravelTripDetailsPaneLimoReservationClicked = "travel.trip-details-pane.limo-reservation-clicked",
  TravelTripDetailsPaneViewAttendeesClicked = "travel.trip-details-pane.view-attendees-clicked",
  TravelTripDetailsPaneFlightFareRulesClicked = "travel.trip-details-pane.flight-fare-rules-clicked",
  TravelTripDetailsPaneRentalCarReservationClicked = "travel.trip-details-pane.rental-car-reservation-clicked",
  TravelTripDetailsPaneHotelReservationClicked = "travel.trip-details-pane.hotel-reservation-clicked",
  TravelTripDetailsPaneHotelViewAmenitiesClicked = "travel.trip-details-pane.hotel-view-amenities-clicked",
  TravelTripDetailsPaneHotelViewAmenitiesViewed = "travel.trip-details-pane.hotel-view-amenities-viewed",
  TravelTripDetailsPaneHotelRoomViewAmenitiesClicked = "travel.trip-details-pane.hotel-room-view-amenities-clicked",
  TravelTripDetailsPaneMiscReservationClicked = "travel.trip-details-pane.misc-reservation-clicked",
  TravelTripDetailsPaneRequestReimbursementClicked = "travel.trip-details-pane.request-reimbursement-clicked",
  TravelTripDetailsPaneRequestReimbursementSuccess = "travel.trip-details-pane.request-reimbursement-success",
  TravelTripDetailsPaneRequestReimbursementError = "travel.trip-details-pane.request-reimbursement-error",
  TravelTripDetailsPaneLinkTripToGroupEventClicked = "travel.trip-details-pane.link-trip-to-group-event-clicked",
  TravelTripDetailsPaneGroupEventRsvpClicked = "travel.trip-details-pane.group-event-rsvp-clicked",
  TravelTripDetailsPaneBudgetViewPolicyClicked = "travel.trip-details-pane.budget-view-policy-clicked",
  TravelTripDetailsPaneDirectBookingClicked = "travel.trip-details-pane.direct-booking-clicked",
  TravelTripDetailsPaneManageTripClicked = "travel.trip-details-pane.manage-trip-clicked",
  TravelTripDetailsPaneAddToCalendarClicked = "travel.trip-details-pane.add-to-calendar-clicked",
  TravelTripDetailsPaneAddToCalendarError = "travel.trip-details-pane.add-to-calendar-error",
  TravelTripDetailsPaneBookingOutOfPolicyActionsClicked = "travel.trip-details-pane.booking-out-of-policy-action-clicked",
  TravelTripDetailsPaneBookingOutOfPolicyActionsError = "travel.trip-details-pane.booking-out-of-policy-action-error",
  TravelTripDetailsPaneBookingOutOfPolicyActionsSuccess = "travel.trip-details-pane.booking-out-of-policy-action-success",

  TravelInsightsPageClosed = "travel.insights.page-closed",
  TravelInsightsPageViewed = "travel.insights.page-viewed",
  TravelProfilePageClosed = "travel.profile.page-closed",
  TravelProfilePageViewed = "travel.profile.page-viewed",
  TravelTripDetailPageClosed = "travel.trip-detail.page-closed",
  TravelTripDetailPageViewed = "travel.trip-detail.page-viewed",
  TravelTripDetailPageManageReservationClicked = "travel.trip-detail.manage-reservation.clicked",
  TravelSNTripDetailPageClosed = "travel.spotnana-trip-detail.page-closed",
  TravelSNTripDetailPageViewed = "travel.spotnana-trip-detail.page-viewed",
  TravelSNTripDetailRedirectSuccess = "travel.spotnana-trip-detail.redirect-success",
  TravelTripsBookNewTripClicked = "travel.trips.book-new-trip-clicked",
  TravelEventsCreateGroupEventClicked = "travel.events.create-group-event-clicked",
  TravelEventsEmptyStateCreateClicked = "travel.events.empty-state.create-clicked",
  TravelTripsEmptyStateBookClicked = "travel.trips.empty-state.book-clicked",
  TravelTripsInsightsAnalyticsClicked = "travel.trips.insights-analytics-clicked",
  TravelTripsInsightsReportsClicked = "travel.trips.insights-reports-clicked",
  TravelTripsInsightsSafetyClicked = "travel.trips.insights-safety-clicked",
  TravelTripsManageProfileClicked = "travel.trips.manage-profile-clicked",
  TravelTripsManageTripClicked = "travel.trips.manage-trip-clicked",
  TravelTripsPageViewed = "travel.trips.page-viewed",
  TravelTripsSideNavigationTooltipViewed = "travel.trips.side_navigation_tooltip.viewed",
  TravelTripsSideNavigationTooltipClicked = "travel.trips.side_navigation_tooltip.clicked",
  TravelTripsListRowClicked = "travel.trips.list-row-clicked",
  TravelTripsViewPolicyClicked = "travel.trips.view-policy-clicked",
  TravelTripsTabChanged = "travel.trips.tab-changed",
  TravelSNApiError = "travel.spotnana-embed.api.error",
  TravelSNBackToTripsClicked = "travel.spotnana-event.back-to-trips-clicked",
  TravelSNCancellationSuccess = "travel.spotnana-event.cancellation-success",
  TravelSNError = "travel.spotnana-event.error",
  TravelSNExchangeSuccess = "travel.spotnana-event.exchange-success",
  TravelSNNavigationEvent = "travel.spotnana-event.navigation",
  TravelSNSuccessfulBooking = "travel.spotnana-event.successful-booking",
  TravelSNLoggedIn = "travel.spotnana-event.logged-in",
  TravelTakeoverSupportClicked = "travel.takeover-support.clicked",
  TravelTokenExchangeError = "travel.token-exchange.error",
  TravelTokenExchangeRequest = "travel.token-exchange.request",
  TravelTokenExchangeResponse = "travel.token-exchange.response",
  TravelTokenExchangeSkipped = "travel.token-exchange.skipped",
  TravelSpotnanaSignoutSuccess = "travel.spotnana.signout.success",
  TravelSpotnanaSignoutFailure = "travel.spotnana.signout.failure",
  TravelSpotnanaSignoutError = "travel.spotnana.signout.error",
  TravelSpotnanaSignoutTimeout = "travel.spotnana.signout.timeout",

  // Spotnana analytics events
  TravelSNFlightSearched = "travel.spotnana-event.flight.searched",
  TravelSNFlightViewed = "travel.spotnana-event.flight.viewed",
  TravelSNFlightSelected = "travel.spotnana-event.flight.selected",
  TravelSNFlightSeatSelected = "travel.spotnana-event.flight.seat-selected",
  TravelSNFlightCheckout = "travel.spotnana-event.flight.checkout",
  TravelSNFlightBooked = "travel.spotnana-event.flight.booked",
  TravelSNFlightFiltersChanges = "travel.spotnana-event.flight.filters-changes",
  TravelSNFlightSort = "travel.spotnana-event.flight.sort",
  TravelSNFlightTicketTypeSelected = "travel.spotnana-event.flight.ticket-type-selected",

  TravelSNHotelSearched = "travel.spotnana-event.hotel.searched",
  TravelSNHotelSelected = "travel.spotnana-event.hotel.selected",
  TravelSNHotelFiltersChanges = "travel.spotnana-event.hotel.filters-changes",
  TravelSNHotelRoomSelected = "travel.spotnana-event.hotel.room-selected",
  TravelSNHotelCheckout = "travel.spotnana-event.hotel.checkout",
  TravelSNHotelMapChanged = "travel.spotnana-event.hotel.map-changed",
  TravelSNHotelBooked = "travel.spotnana-event.hotel.booked",

  TravelSNCarSearched = "travel.spotnana-event.car.searched",
  TravelSNCarCheckout = "travel.spotnana-event.car.checkout",
  TravelSNCarFiltersChanges = "travel.spotnana-event.car.filters-changes",
  TravelSNCarOutOfPolicyClicked = "travel.spotnana-event.car.out-of-policy-clicked",
  TravelSNCarSort = "travel.spotnana-event.car.sort",
  TravelSNCarSelected = "travel.spotnana-event.car.selected",
  TravelSNCarBooked = "travel.spotnana-event.car.booked",

  TravelSNProfileAirPreferencesChanged = "travel.spotnana-event.profile.preferences.changed",
  TravelSNProfileLoyaltyAddProgram = "travel.spotnana-event.profile.loyalty.add-program",
  TravelSNProfileLoyaltyDeleteProgram = "travel.spotnana-event.profile.loyalty.delete-program",
  TravelSNProfileLoyaltyChangeProgram = "travel.spotnana-event.profile.loyalty.change-program",
  TravelSNProfilePersonalSaveChanges = "travel.spotnana-event.profile.personal.save-changes",
  TravelSNProfilePersonalCancelChanges = "travel.spotnana-event.profile.personal.cancel-changes",
  TravelSNProfilePersonalUploadPhoto = "travel.spotnana-event.profile.personal.upload-photo",
  TravelSNProfilePersonalChangeTitle = "travel.spotnana-event.profile.personal.change-title",
  TravelSNProfilePersonalChangeGender = "travel.spotnana-event.profile.personal.change-gender",
  TravelSNProfilePersonalChangeDateOfBirth = "travel.spotnana-event.profile.personal.change-date-of-birth",
  TravelSNProfilePersonalChangeMobile = "travel.spotnana-event.profile.personal.change-mobile",
  TravelSNProfilePersonalAddMobile = "travel.spotnana-event.profile.personal.add-mobile",
  TravelSNProfilePersonalDeleteMobile = "travel.spotnana-event.profile.personal.delete-mobile",
  TravelSNProfilePersonalChangeAddress = "travel.spotnana-event.profile.personal.change-address",
  TravelSNProfilePersonalAddAddress = "travel.spotnana-event.profile.personal.add-address",
  TravelSNProfilePersonalDeleteAddress = "travel.spotnana-event.profile.personal.delete-address",
  TravelSNProfilePersonalChangePassport = "travel.spotnana-event.profile.personal.change-passport",
  TravelSNProfilePersonalAddPassport = "travel.spotnana-event.profile.personal.add-passport",
  TravelSNProfilePersonalDeletePassport = "travel.spotnana-event.profile.personal.delete-passport",
  TravelSNProfilePersonalChangeKnownTravelerNumber = "travel.spotnana-event.profile.personal.change-known-traveler-number",
  TravelSNProfilePersonalAddKnownTravelerNumber = "travel.spotnana-event.profile.personal.add-known-traveler-number",
  TravelSNProfilePersonalDeleteKnownTravelerNumber = "travel.spotnana-event.profile.personal.delete-known-traveler-number",
  TravelSNProfilePersonalChangeRedressNumber = "travel.spotnana-event.profile.personal.change-redress-number",
  TravelSNProfilePersonalAddRedressNumber = "travel.spotnana-event.profile.personal.add-redress-number",
  TravelSNProfilePersonalDeleteRedressNumber = "travel.spotnana-event.profile.personal.delete-redress-number",
  TravelSNProfilePersonalChangeEmergencyContact = "travel.spotnana-event.profile.personal.change-emergency-contact",
  TravelSNProfilePersonalAddEmergencyContact = "travel.spotnana-event.profile.personal.add-emergency-contact",
  TravelSNProfilePersonalDeleteEmergencyContact = "travel.spotnana-event.profile.personal.delete-emergency-contact",

  TravelSNScreenInitialDimensions = "travel.spotnana-event.screen-initial-dimensions",
  TravelSNTripAddToTrip = "travel.spotnana-event.trip-detail.add-to-trip",
  TravelSNTripCancelTrip = "travel.spotnana-event.trip-detail.cancel-trip",
  TravelSNTripCancelClicked = "travel.spotnana-event.trip-detail.cancel-clicked",
  TravelSNTripChangeClicked = "travel.spotnana-event.trip-detail.change-clicked",
  TravelSNTripDetailsExpanded = "travel.spotnana-event.trip-detail.trip-details-expanded",
  TravelSNTripItemExpanded = "travel.spotnana-event.trip-detail.item-expanded",
  TravelSNTripPaymentDetailsClicked = "travel.spotnana-event.trip-detail.payment-details-clicked",
  TravelSNTripDetailsViewed = "travel.spotnana-event.trip-detail.details-viewed",
  TravelSNTripShowCanceledClicked = "travel.spotnana-event.trip-detail.show-canceled-clicked",

  TravelEmbedUnhandledMessage = "travel.embed.unhandled-message",
  TravelEmbedSrcChanged = "travel.embed.src-changed",
  TravelEmbedNavigation = "travel.embed.navigation",
}

type EmptyPayload = {};

type ScreenSizePayload = {
  width: number;
  height: number;
};

type TripIdPayload = {
  tripId: string;
};

type NavigationPayload = {
  path: string;
};

type BookingPayload = {
  brexTripId: string;
  partnerTripId: string;
  partnerPnrId: string;
};

type BookingWithPnrIdPayload = {
  brexTripId?: string;
  spotnanaPnrId?: string;
};

type TravelBookingPayload = {
  bookingId?: string;
};

type SNErrorPayload = {
  errorType: string;
  errorCode?: string;
};

type SNApiErrorPayload = {
  errorMessage: string;
  errorCode?: string;
  request: string;
  debugToolLink: string;
};

type TokenExchangeErrorPayload = {
  errorCode: string;
};

type TabChangedPayload = {
  tab: string;
};

type BookingPageClosedPayload = {
  isDraftTrip?: boolean;
  brexTripId: string;
  partnerTripId: string;
};

type TripAndGroupEventPayload = {
  groupEventId?: string;
  newTripId?: string;
  customerUserId?: string;
};

type GroupEventPayload = {
  groupEventId?: string;
};

type CreateTripPayload = {
  tripName: string;
  budgetId?: string;
};

type SearchWithTripPayload = {
  tripId: string;
};

type TripIdAndBookingIdPayload = {
  tripId?: string;
  bookingId?: string;
};

type TransformParamsPayload = {
  params: string;
};

type CreateTripBookingSearchPayload = {
  tripId: string;
  searchParams: string;
};

type TripPayload = {
  tripId?: string;
};

type FlightPayload = {
  flightLegIndex: number;
};

type RentalCarPayload = {
  rentalCardId: string;
};

type HotelPayload = {
  hotelId: string;
};

type ExpensePayload = {
  expenseId: string;
};

type TripsListPanePayload = {
  paneType: string;
};

type BookingSuccessAddBookingPayload = {
  currentBookingType: string | undefined;
  tripId: string;
};

type SpotnanaIframePayload = {
  src: string;
};

type AddToCalendarPayload = {
  provider: string;
  isFirstAttempt: boolean;
};

type AddToCalendarErrorPayload = {
  errorMessage?: string;
  providerId?: string;
  event?: string;
};

type BookingOutOfPolicyActionsPayload = {
  action: "APPROVE" | "DENY";
  bookingId?: string;
  errorMessage?: string;
};

type BookingSearchFormPayload = {
  tripBrexId?: string;
  tripPartnerId?: string;
};

type TravelSupportPayload = {
  provider: "freshchat" | "twilio";
  isUnread?: boolean;
};

type TravelEmbedUnhandledMessagePayload = {
  payload: string;
};

type TravelEmbedSrcChangedPayload = {
  previousSrc: string;
  newSrc: string;
};

type TravelEmbedNavigationPayload = {
  location: string;
};

type BrexEventSchemaMap = {
  [AnalyticsEvents.TravelSupportChatOpened]: TravelSupportPayload;
  [AnalyticsEvents.TravelSupportChatClosed]: TravelSupportPayload;
  [AnalyticsEvents.TravelSupportChatMinimized]: TravelSupportPayload;
  [AnalyticsEvents.TravelSupportChatBubbleClicked]: TravelSupportPayload;
  [AnalyticsEvents.TravelSupportChatLogoutError]: TravelSupportPayload;
  [AnalyticsEvents.TravelSupportChatInitialized]: TravelSupportPayload;
  [AnalyticsEvents.TravelSupportChatCleared]: TravelSupportPayload;

  [AnalyticsEvents.TravelBookingBackClicked]: EmptyPayload;
  [AnalyticsEvents.TravelBookingCreateTripClicked]: EmptyPayload;
  [AnalyticsEvents.TravelBookingCreateTripSuccess]: EmptyPayload;
  [AnalyticsEvents.TravelBookingPageClosed]: BookingPageClosedPayload;
  [AnalyticsEvents.TravelBookingPageViewed]: ScreenSizePayload;

  [AnalyticsEvents.TravelAccountTermsModalViewed]: EmptyPayload;
  [AnalyticsEvents.TravelAccountTermsModalEnabledTravel]: EmptyPayload;
  [AnalyticsEvents.TravelAccountTermsModalDeferredTravelEnablement]: EmptyPayload;
  [AnalyticsEvents.TravelAccountTermsModalDismissed]: EmptyPayload;
  [AnalyticsEvents.TravelAccountTermsModalEnableError]: EmptyPayload;
  [AnalyticsEvents.TravelAccountTermsModalDeferError]: EmptyPayload;

  [AnalyticsEvents.TravelUserConsentModalViewed]: EmptyPayload;
  [AnalyticsEvents.TravelUserConsentModalAccepted]: EmptyPayload;
  [AnalyticsEvents.TravelUserConsentModalDismissed]: EmptyPayload;
  [AnalyticsEvents.TravelUserConsentModalError]: EmptyPayload;

  [AnalyticsEvents.TravelBookingApprovalBannerViewed]: TravelBookingPayload;
  [AnalyticsEvents.TravelBookingApprovalBannerError]: TravelBookingPayload;
  [AnalyticsEvents.TravelBookingApprovalHistoryViewed]: TravelBookingPayload;
  [AnalyticsEvents.TravelBookingApprovalHistoryError]: TravelBookingPayload;
  [AnalyticsEvents.TravelBookingApprovalApproveClicked]: TravelBookingPayload;
  [AnalyticsEvents.TravelBookingApprovalApproveSuccess]: TravelBookingPayload;
  [AnalyticsEvents.TravelBookingApprovalApproveError]: TravelBookingPayload;
  [AnalyticsEvents.TravelBookingApprovalRejectClicked]: TravelBookingPayload;
  [AnalyticsEvents.TravelBookingApprovalRejectSuccess]: TravelBookingPayload;
  [AnalyticsEvents.TravelBookingApprovalRejectError]: TravelBookingPayload;
  [AnalyticsEvents.TravelBookingApprovalUndoClicked]: TravelBookingPayload;
  [AnalyticsEvents.TravelBookingApprovalUndoSuccess]: TravelBookingPayload;
  [AnalyticsEvents.TravelBookingApprovalUndoError]: TravelBookingPayload;

  [AnalyticsEvents.TravelBookingSuccessResultSuccess]: BookingWithPnrIdPayload;
  [AnalyticsEvents.TravelBookingSuccessResultError]: BookingWithPnrIdPayload;

  [AnalyticsEvents.TravelBookingSuccessScreenViewed]: EmptyPayload;
  [AnalyticsEvents.TravelBookingSuccessScreenClosed]: EmptyPayload;
  [AnalyticsEvents.TravelBookingSuccessScreenErrorRedirect]: EmptyPayload;
  [AnalyticsEvents.TravelBookingSuccessScreenViewTripDetailsClicked]: EmptyPayload;
  [AnalyticsEvents.TravelBookingSuccessScreenAddBookingClicked]: BookingSuccessAddBookingPayload;

  [AnalyticsEvents.TravelSNIframeMounted]: SpotnanaIframePayload;

  [AnalyticsEvents.TravelDowntimeBannerViewed]: EmptyPayload;

  [AnalyticsEvents.TravelBookingPaneViewed]: TravelBookingPayload;
  [AnalyticsEvents.TravelBookingPaneClosed]: EmptyPayload;

  [AnalyticsEvents.TravelTripDetailsRequestReimbursementClicked]: EmptyPayload;
  [AnalyticsEvents.TravelTripDetailsViewReimbursementClicked]: EmptyPayload;
  [AnalyticsEvents.TravelGroupEventAttendeeDetailsPaneConfirmAttendanceClicked]: EmptyPayload;
  [AnalyticsEvents.TravelGroupEventAttendeeDetailsPaneEditAttendanceClicked]: EmptyPayload;
  [AnalyticsEvents.TravelGroupEventAttendeeDetailsPaneAcceptInvitationClicked]: EmptyPayload;
  [AnalyticsEvents.TravelGroupEventAttendeeDetailsPaneDeclineInvitationClicked]: EmptyPayload;
  [AnalyticsEvents.TravelGroupEventCreateEventFormSubmitClicked]: EmptyPayload;
  [AnalyticsEvents.TravelGroupEventDetailsPageViewed]: GroupEventPayload;
  [AnalyticsEvents.TravelGroupEventDetailsPageEdit]: GroupEventPayload;
  [AnalyticsEvents.TravelGroupEventEditPaneCancel]: GroupEventPayload;
  [AnalyticsEvents.TravelGroupEventEditPaneDelete]: GroupEventPayload;
  [AnalyticsEvents.TravelGroupEventAttendeeTableViewGuestRequirements]: EmptyPayload;
  [AnalyticsEvents.TravelGroupEventAttendeeTableViewAttendeeDetails]: EmptyPayload;
  [AnalyticsEvents.TravelGroupEventAttendeeTableFilterAttendeeName]: EmptyPayload;
  [AnalyticsEvents.TravelGroupEventAttendeeTableFilterAttendanceStatus]: EmptyPayload;
  [AnalyticsEvents.TravelGroupEventAttendeeTableFilterBookingStatus]: EmptyPayload;
  [AnalyticsEvents.TravelGroupEventAttendeeTableFilterGuestRequirements]: EmptyPayload;
  [AnalyticsEvents.TravelGroupEventAttendeeTableRemove]: EmptyPayload;
  [AnalyticsEvents.TravelGroupEventAttendeeTableSendInvite]: EmptyPayload;
  [AnalyticsEvents.TravelGroupEventAttendeeTableSendReminder]: EmptyPayload;
  [AnalyticsEvents.TravelGroupEventAttendeeDetailsPaneSendBookingReminder]: EmptyPayload;
  [AnalyticsEvents.TravelGroupEventAttendeeDetailsPaneSendRsvpReminder]: EmptyPayload;
  [AnalyticsEvents.TravelGroupEventAttendeeDetailsPaneSendInvite]: EmptyPayload;
  [AnalyticsEvents.TravelGroupEventAttendeeDetailsPaneRemove]: EmptyPayload;
  [AnalyticsEvents.TravelGroupEventActionsBuildGuestList]: EmptyPayload;
  [AnalyticsEvents.TravelGroupEventActionsSendInvites]: EmptyPayload;
  [AnalyticsEvents.TravelGroupEventActionsSendReminders]: EmptyPayload;
  [AnalyticsEvents.TravelGroupEventActionsHotelRoomBlocks]: EmptyPayload;
  [AnalyticsEvents.TravelGroupEventActionsRequestBudget]: EmptyPayload;
  [AnalyticsEvents.TravelGroupEventActionsViewBudget]: EmptyPayload;
  [AnalyticsEvents.TravelGroupEventActionsViewBudgetRequest]: EmptyPayload;
  [AnalyticsEvents.TravelGroupEventActionsViewPolicy]: EmptyPayload;
  [AnalyticsEvents.TravelTripsPageDraftTripViewClicked]: EmptyPayload;
  [AnalyticsEvents.TravelTripsPageDraftTripDeleteClicked]: EmptyPayload;
  [AnalyticsEvents.TravelBookingPageRequestBudgetClicked]: EmptyPayload;
  [AnalyticsEvents.TravelBudgetRequestPageCreateBudgetClicked]: EmptyPayload;
  [AnalyticsEvents.TravelBudgetRequestPageCreateBudgetSuccess]: EmptyPayload;

  [AnalyticsEvents.TravelTripsExpandedTripCardClicked]: TripPayload;
  [AnalyticsEvents.TravelTripsDetailsPaneClicked]: TripPayload;
  [AnalyticsEvents.TravelTripsGroupEventDetailsPaneClicked]: GroupEventPayload;
  [AnalyticsEvents.TravelTripsGroupEventRsvpDetailsPaneClicked]: GroupEventPayload;
  [AnalyticsEvents.TravelTripsTripsListPaneClicked]: TripsListPanePayload;

  [AnalyticsEvents.TravelBookingSearchFormMounted]: EmptyPayload;
  [AnalyticsEvents.TravelBookingSearchFormPageMounted]: BookingSearchFormPayload;

  [AnalyticsEvents.TravelBookingSearchFormSearchClicked]: CreateTripPayload;
  [AnalyticsEvents.TravelBookingSearchFormSearchWithTripClicked]: SearchWithTripPayload;
  [AnalyticsEvents.TravelBookingSearchFormSearchSuccess]: CreateTripBookingSearchPayload;
  [AnalyticsEvents.TravelBookingSearchFormSearchError]: CreateTripBookingSearchPayload;
  [AnalyticsEvents.TravelBookingSearchFormTransformFlightParams]: TransformParamsPayload;
  [AnalyticsEvents.TravelBookingSearchFormTransformHotelParams]: TransformParamsPayload;
  [AnalyticsEvents.TravelBookingSearchFormTransformCarParams]: TransformParamsPayload;
  [AnalyticsEvents.TravelBookingSearchFormTransformRailParams]: TransformParamsPayload;

  [AnalyticsEvents.TravelBookingSearchFormViewed]: EmptyPayload;
  [AnalyticsEvents.TravelBookingSearchFormClosed]: EmptyPayload;

  [AnalyticsEvents.TravelTripDetailsPaneViewed]: TripPayload;
  [AnalyticsEvents.TravelTripDetailsPaneClosed]: TripPayload;
  [AnalyticsEvents.TravelBookingPaneErrorViewed]: TravelBookingPayload;
  [AnalyticsEvents.TravelTripDetailsPaneErrorViewed]: TripIdAndBookingIdPayload;
  [AnalyticsEvents.TravelTripDetailsPaneErrorRetryClicked]: TripPayload;
  [AnalyticsEvents.TravelTripDetailPageManageReservationClicked]: EmptyPayload;
  [AnalyticsEvents.TravelTripDetailsPaneBackClicked]: EmptyPayload;
  [AnalyticsEvents.TravelTripDetailsPaneFlightReservationClicked]: FlightPayload;
  [AnalyticsEvents.TravelTripDetailPaneRailReservationClicked]: TravelBookingPayload;
  [AnalyticsEvents.TravelTripDetailsPaneLimoReservationClicked]: TravelBookingPayload;
  [AnalyticsEvents.TravelTripDetailsPaneViewAttendeesClicked]: EmptyPayload;
  [AnalyticsEvents.TravelTripDetailsPaneFlightFareRulesClicked]: EmptyPayload;
  [AnalyticsEvents.TravelTripDetailsPaneRentalCarReservationClicked]: RentalCarPayload;
  [AnalyticsEvents.TravelTripDetailsPaneHotelReservationClicked]: HotelPayload;
  [AnalyticsEvents.TravelTripDetailsPaneHotelViewAmenitiesClicked]: EmptyPayload;
  [AnalyticsEvents.TravelTripDetailsPaneHotelRoomViewAmenitiesClicked]: EmptyPayload;
  [AnalyticsEvents.TravelTripDetailsPaneMiscReservationClicked]: TravelBookingPayload;
  [AnalyticsEvents.TravelTripDetailsPaneRequestReimbursementClicked]: EmptyPayload;
  [AnalyticsEvents.TravelTripDetailsPaneRequestReimbursementSuccess]: ExpensePayload;
  [AnalyticsEvents.TravelTripDetailsPaneRequestReimbursementError]: EmptyPayload;
  [AnalyticsEvents.TravelTripDetailsPaneLinkTripToGroupEventClicked]: TripAndGroupEventPayload;
  [AnalyticsEvents.TravelTripDetailsPaneGroupEventRsvpClicked]: GroupEventPayload;
  [AnalyticsEvents.TravelTripDetailsPaneBudgetViewPolicyClicked]: EmptyPayload;
  [AnalyticsEvents.TravelTripDetailsPaneDirectBookingClicked]: EmptyPayload;
  [AnalyticsEvents.TravelTripDetailsPaneManageTripClicked]: EmptyPayload;
  [AnalyticsEvents.TravelTripDetailsPaneAddToCalendarClicked]: AddToCalendarPayload;
  [AnalyticsEvents.TravelTripDetailsPaneAddToCalendarError]: AddToCalendarErrorPayload;
  [AnalyticsEvents.TravelTripDetailsPaneBookingOutOfPolicyActionsClicked]: BookingOutOfPolicyActionsPayload;
  [AnalyticsEvents.TravelTripDetailsPaneBookingOutOfPolicyActionsError]: BookingOutOfPolicyActionsPayload;
  [AnalyticsEvents.TravelTripDetailsPaneBookingOutOfPolicyActionsSuccess]: BookingOutOfPolicyActionsPayload;

  [AnalyticsEvents.TravelInsightsPageClosed]: EmptyPayload;
  [AnalyticsEvents.TravelInsightsPageViewed]: ScreenSizePayload;
  [AnalyticsEvents.TravelProfilePageClosed]: EmptyPayload;
  [AnalyticsEvents.TravelProfilePageViewed]: ScreenSizePayload;
  [AnalyticsEvents.TravelTripDetailPageClosed]: EmptyPayload;
  [AnalyticsEvents.TravelTripDetailPageViewed]: ScreenSizePayload;
  [AnalyticsEvents.TravelSNTripDetailPageClosed]: EmptyPayload;
  [AnalyticsEvents.TravelSNTripDetailPageViewed]: ScreenSizePayload;
  [AnalyticsEvents.TravelSNTripDetailRedirectSuccess]: EmptyPayload;
  [AnalyticsEvents.TravelTripsBookNewTripClicked]: EmptyPayload;
  [AnalyticsEvents.TravelEventsCreateGroupEventClicked]: EmptyPayload;
  [AnalyticsEvents.TravelEventsEmptyStateCreateClicked]: EmptyPayload;
  [AnalyticsEvents.TravelTripsEmptyStateBookClicked]: EmptyPayload;
  [AnalyticsEvents.TravelTripsInsightsAnalyticsClicked]: EmptyPayload;
  [AnalyticsEvents.TravelTripsInsightsReportsClicked]: EmptyPayload;
  [AnalyticsEvents.TravelTripsInsightsSafetyClicked]: EmptyPayload;
  [AnalyticsEvents.TravelTripsManageProfileClicked]: EmptyPayload;
  [AnalyticsEvents.TravelTripsManageTripClicked]: TripIdPayload;
  [AnalyticsEvents.TravelTripsPageViewed]: ScreenSizePayload;
  [AnalyticsEvents.TravelTripsSideNavigationTooltipViewed]: EmptyPayload;
  [AnalyticsEvents.TravelTripsSideNavigationTooltipClicked]: EmptyPayload;
  [AnalyticsEvents.TravelTripsListRowClicked]: TripIdPayload;
  [AnalyticsEvents.TravelTripsViewPolicyClicked]: TripIdPayload;
  [AnalyticsEvents.TravelTripsTabChanged]: TabChangedPayload;
  [AnalyticsEvents.TravelSNApiError]: SNApiErrorPayload;
  [AnalyticsEvents.TravelSNBackToTripsClicked]: EmptyPayload;
  [AnalyticsEvents.TravelSNCancellationSuccess]: BookingPayload;
  [AnalyticsEvents.TravelSNError]: SNErrorPayload;
  [AnalyticsEvents.TravelSNExchangeSuccess]: BookingPayload;
  [AnalyticsEvents.TravelSNNavigationEvent]: NavigationPayload;
  [AnalyticsEvents.TravelSNSuccessfulBooking]: BookingPayload;
  [AnalyticsEvents.TravelSNLoggedIn]: EmptyPayload;
  [AnalyticsEvents.TravelTakeoverSupportClicked]: EmptyPayload;
  [AnalyticsEvents.TravelTokenExchangeError]: TokenExchangeErrorPayload;
  [AnalyticsEvents.TravelTokenExchangeRequest]: EmptyPayload;
  [AnalyticsEvents.TravelTokenExchangeResponse]: EmptyPayload;
  [AnalyticsEvents.TravelTokenExchangeSkipped]: EmptyPayload;
  [AnalyticsEvents.TravelSpotnanaSignoutSuccess]: EmptyPayload;
  [AnalyticsEvents.TravelSpotnanaSignoutFailure]: EmptyPayload;
  [AnalyticsEvents.TravelSpotnanaSignoutError]: EmptyPayload;
  [AnalyticsEvents.TravelSpotnanaSignoutTimeout]: EmptyPayload;
};

type SpotnanaPassthroughAnalyticsEventSchemaMap = {
  [AnalyticsEvents.TravelSNFlightSearched]: any;
  [AnalyticsEvents.TravelSNFlightViewed]: any;
  [AnalyticsEvents.TravelSNFlightSelected]: any;
  [AnalyticsEvents.TravelSNFlightSeatSelected]: EmptyPayload;
  [AnalyticsEvents.TravelSNFlightCheckout]: any;
  [AnalyticsEvents.TravelSNFlightBooked]: any;
  [AnalyticsEvents.TravelSNFlightFiltersChanges]: any;
  [AnalyticsEvents.TravelSNFlightSort]: any;
  [AnalyticsEvents.TravelSNFlightTicketTypeSelected]: any;

  [AnalyticsEvents.TravelSNHotelSearched]: any;
  [AnalyticsEvents.TravelSNHotelSelected]: any;
  [AnalyticsEvents.TravelSNHotelFiltersChanges]: any;
  [AnalyticsEvents.TravelSNHotelRoomSelected]: any;
  [AnalyticsEvents.TravelSNHotelCheckout]: any;
  [AnalyticsEvents.TravelSNHotelMapChanged]: EmptyPayload;
  [AnalyticsEvents.TravelSNHotelBooked]: any;

  [AnalyticsEvents.TravelSNCarSearched]: any;
  [AnalyticsEvents.TravelSNCarCheckout]: any;
  [AnalyticsEvents.TravelSNCarFiltersChanges]: any;
  [AnalyticsEvents.TravelSNCarOutOfPolicyClicked]: EmptyPayload;
  [AnalyticsEvents.TravelSNCarSort]: any;
  [AnalyticsEvents.TravelSNCarSelected]: any;
  [AnalyticsEvents.TravelSNCarBooked]: any;

  [AnalyticsEvents.TravelSNProfileAirPreferencesChanged]: EmptyPayload;
  [AnalyticsEvents.TravelSNProfileLoyaltyAddProgram]: EmptyPayload;
  [AnalyticsEvents.TravelSNProfileLoyaltyDeleteProgram]: EmptyPayload;
  [AnalyticsEvents.TravelSNProfileLoyaltyChangeProgram]: EmptyPayload;
  [AnalyticsEvents.TravelSNProfilePersonalSaveChanges]: EmptyPayload;
  [AnalyticsEvents.TravelSNProfilePersonalCancelChanges]: EmptyPayload;
  [AnalyticsEvents.TravelSNProfilePersonalUploadPhoto]: EmptyPayload;
  [AnalyticsEvents.TravelSNProfilePersonalChangeTitle]: EmptyPayload;
  [AnalyticsEvents.TravelSNProfilePersonalChangeGender]: EmptyPayload;
  [AnalyticsEvents.TravelSNProfilePersonalChangeDateOfBirth]: EmptyPayload;
  [AnalyticsEvents.TravelSNProfilePersonalChangeMobile]: EmptyPayload;
  [AnalyticsEvents.TravelSNProfilePersonalAddMobile]: EmptyPayload;
  [AnalyticsEvents.TravelSNProfilePersonalDeleteMobile]: EmptyPayload;
  [AnalyticsEvents.TravelSNProfilePersonalChangeAddress]: EmptyPayload;
  [AnalyticsEvents.TravelSNProfilePersonalAddAddress]: EmptyPayload;
  [AnalyticsEvents.TravelSNProfilePersonalDeleteAddress]: EmptyPayload;
  [AnalyticsEvents.TravelSNProfilePersonalChangePassport]: EmptyPayload;
  [AnalyticsEvents.TravelSNProfilePersonalAddPassport]: EmptyPayload;
  [AnalyticsEvents.TravelSNProfilePersonalDeletePassport]: EmptyPayload;
  [AnalyticsEvents.TravelSNProfilePersonalChangeKnownTravelerNumber]: EmptyPayload;
  [AnalyticsEvents.TravelSNProfilePersonalAddKnownTravelerNumber]: EmptyPayload;
  [AnalyticsEvents.TravelSNProfilePersonalDeleteKnownTravelerNumber]: EmptyPayload;
  [AnalyticsEvents.TravelSNProfilePersonalChangeRedressNumber]: EmptyPayload;
  [AnalyticsEvents.TravelSNProfilePersonalAddRedressNumber]: EmptyPayload;
  [AnalyticsEvents.TravelSNProfilePersonalDeleteRedressNumber]: EmptyPayload;
  [AnalyticsEvents.TravelSNProfilePersonalChangeEmergencyContact]: EmptyPayload;
  [AnalyticsEvents.TravelSNProfilePersonalAddEmergencyContact]: EmptyPayload;
  [AnalyticsEvents.TravelSNProfilePersonalDeleteEmergencyContact]: EmptyPayload;

  [AnalyticsEvents.TravelSNScreenInitialDimensions]: ScreenSizePayload;
  [AnalyticsEvents.TravelSNTripAddToTrip]: EmptyPayload;
  [AnalyticsEvents.TravelSNTripCancelTrip]: EmptyPayload;
  [AnalyticsEvents.TravelSNTripCancelClicked]: EmptyPayload;
  [AnalyticsEvents.TravelSNTripChangeClicked]: EmptyPayload;
  [AnalyticsEvents.TravelSNTripDetailsExpanded]: EmptyPayload;
  [AnalyticsEvents.TravelSNTripItemExpanded]: EmptyPayload;
  [AnalyticsEvents.TravelSNTripPaymentDetailsClicked]: EmptyPayload;
  [AnalyticsEvents.TravelSNTripDetailsViewed]: EmptyPayload;
  [AnalyticsEvents.TravelSNTripShowCanceledClicked]: EmptyPayload;

  [AnalyticsEvents.TravelEmbedUnhandledMessage]: TravelEmbedUnhandledMessagePayload;
  [AnalyticsEvents.TravelEmbedSrcChanged]: TravelEmbedSrcChangedPayload;
  [AnalyticsEvents.TravelEmbedNavigation]: TravelEmbedNavigationPayload;
};

export type EventSchemaMap = BrexEventSchemaMap &
  SpotnanaPassthroughAnalyticsEventSchemaMap;

import {
  DepositsAccountStatus,
  PartnerIntegrationStatus,
} from "@/__generated__/globalTypes";
import type { UserRole as RoleString } from "@/data/sharedTypes";
import type { UserProperties } from "@/features/Analytics/sharedTypes";
import type { UserPropertiesQuery } from "@/features/Authentication/data/__generated__/queries.generated";
import { connectionToNodes } from "@/helpers";
import { selectProductConfig } from "@/selectors/selectProductConfig";

export const selectUserProperties = (
  userPropertiesData?: UserPropertiesQuery,
): UserProperties | undefined => {
  if (!userPropertiesData) {
    return undefined;
  }
  const user = userPropertiesData?.user;
  const account = user?.account;
  const depositsAccounts = connectionToNodes(account?.depositsAccounts);
  const productConfig = selectProductConfig(userPropertiesData);
  const signupPayload = userPropertiesData?.signupPayload;
  const productTier =
    userPropertiesData?.productConfig?.configurationHome?.tierV2;
  const billPayOnEmpowerMigrated =
    userPropertiesData?.migratedAccount?.billPayOnEmpowerEnabled ?? false;
  const segmentationQuery = userPropertiesData?.segmentationQuery;
  const partnershipIntegration = account?.partnershipIntegrations?.[0];
  const hasPartnershipIntegration = !!(
    partnershipIntegration?.status &&
    partnershipIntegration.status !== PartnerIntegrationStatus.INACTIVE
  );
  const partnerName = hasPartnershipIntegration
    ? partnershipIntegration?.name
    : undefined;

  return {
    id: user?.id,
    role: (user?.role as RoleString) ?? undefined,
    userRole: user?.userRole ?? undefined,
    depositsRole: user?.depositsRole,
    companyHasDepositsAccount: depositsAccounts.some(
      (depositsAccount) =>
        ![
          DepositsAccountStatus.RESTRICTED,
          DepositsAccountStatus.REJECTED,
        ].includes(depositsAccount.status),
    ),
    invitedDepostsAdmin: user?.isInvitedDepositsAdmin,
    isInitialApplicant: user?.isInitialApplicant,
    isInvitedDepositsCashAndCardUser: user?.isInvitedDepositsCashAndCardUser,
    hasProductApplication: account?.hasProductApplication,
    helpshiftAuthToken: user?.helpshiftAuthToken,
    companyId: account?.id,
    companyName: account?.dbaName,
    companyLegalName: account?.legalName,
    ct: account?.ct,
    financialProductType: account?.financialProductType,
    email: user?.email,
    name: [user?.firstName, user?.lastName].filter((v) => v).join(" "),
    firstName: user?.firstName,
    lastName: user?.lastName,
    signupDatetime: account?.insertedAt,
    insertedAt: account?.insertedAt,
    productApplicationSubmittedDatetime:
      account?.latestApprovedProductApplicationSubmittedAt,
    productTier,
    billPayOnEmpowerMigrated,
    cashActivationDatetime: depositsAccounts[0]?.activatedAt,
    // this is used to target users who have at least one transaction to target
    // for the admin walkthrough, which shows how to edit memos and upload receipts
    firstTransactionCompleted: user?.hasTransaction ?? false,
    // this is used to target users who have at least one cleared transaction
    // for the rewards walkthough, which shows where points accrual and redemption
    // functionality lives.
    firstTransactionCleared: account?.hasClearedTransaction ?? false,
    isManager: user?.isManager,
    approvedBlueprintAtOnboarding: account?.approvedBlueprintAtOnboarding,
    riskTier: account?.riskTier,
    initialMarketSegment: account?.initialMarketSegment,
    isInternalSignup: !!account?.isInternalSignup,
    status: user?.status,
    accountStatus: account?.status,
    hasBasicReimbursementsEnabled: productConfig.basicReimbursementsEnabled,
    // TODO (ssisson): Migrate this to use hook, or remove entirely
    hasMultiEntityEnabled: productConfig.multiEntityEnabled,
    hasAdvancedExpenseManagementEnabled:
      productConfig.advancedExpenseManagementEnabled,
    hasPayableBillAccess: productConfig.payableBillAccess,
    attributionToken: signupPayload?.attributionToken,
    isSandbox: account?.isSandbox,
    segmentV4: segmentationQuery?.v4?.segmentV4,
    subsegmentV4: segmentationQuery?.v4?.subsegmentV4,
    partOfCohorts: userPropertiesData?.cohorts?.map((cohort) => cohort.key),
    hasPartnershipIntegration,
    partnerName,
  };
};

import { parse } from "qs";
import type {
  OAuthErrorResponse,
  OAuthResponse,
} from "@/features/Authentication/sharedTypes";

const oAuthKeys = ["code", "error", "error_description", "state"];

/**
 * Parse OAuth response URL from our OAuth provider
 * Filter out invalid keys
 */
export const parseOAuthResponseUrl = (resultUrl: string) => {
  const params = parse(resultUrl, { ignoreQueryPrefix: true });
  const type = params.error ? "error" : "ok";
  return Object.keys(params)
    .filter((key) => oAuthKeys.includes(key))
    .reduce(
      (filteredParams, key) => ({
        ...filteredParams,
        [key]: params[key],
      }),
      { type } as OAuthResponse,
    );
};

/**
 * Determine if an OAuth response is an error
 */
export const isOAuthErrorResponse = (
  result: OAuthResponse,
): result is OAuthErrorResponse => "error" in result;

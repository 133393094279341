import { relayStylePagination } from "@apollo/client/utilities";
import possibleTypes from "@root/possibleTypes.json";
import type { StrictTypedTypePolicies } from "@/__generated__/apollo-helpers";
import { InMemoryCache } from "@/features/Apollo/helpers/client";

export const typePolicies = {
  Query: {
    fields: {
      user: {
        merge(existing, incoming, { mergeObjects }) {
          // - We have many queries that query for the current user data (i.e. `UserPropertiesQuery`, `UserTimezone`, `PersonalInformation`, etc.
          // - If one of those queries fails, (i.e. returns `{ data { user: null } }`), it will overwrite the previous queries' user data even if those were successful
          //   - This led to an incident: https://brex.slack.com/archives/C05LM3M599B
          // - This code ensures that we only write to the current user apollo cache if the query was successful
          return existing && !incoming
            ? existing
            : mergeObjects(existing, incoming);
        },
      },
      account: {
        merge(existing, incoming, { mergeObjects }) {
          // Same idea as the user field above
          return existing && !incoming
            ? existing
            : mergeObjects(existing, incoming);
        },
      },
      inboxTasks: relayStylePagination(["filter", "sortBy"]),
      inboxTasksV2: relayStylePagination(["filter", "sortBy"]),
      budgetAnyRequestEntities: relayStylePagination(["filter"]),
      expense(_, { args, toReference }) {
        return toReference({
          __typename: "Expense",
          id: args?.id,
        });
      },
      requestAction(_, { args, toReference }) {
        return toReference({
          __typename: "RequestAction",
          id: args?.id,
        });
      },
      card(_, { args, toReference }) {
        return toReference({
          __typename: "Card",
          id: args?.id,
        });
      },
    },
  },
  ProductConfig: {
    merge: true,
  },
  /**
   * We discovered during #incident-4295 that a ProductConfig query made by
   * ExpenseBudgetSelectionController caused DataGrid to lose its scroll
   * position (it would reset to the top) after opening an expense details
   * pane.
   *
   * Turns out the `ProductConfig` merge above doesn't fully merge the
   * `ProductConfigurationHome`, leading to the issue. For now, we
   * explicitly merge `ProductConfigurationHome`.
   */
  ProductConfigurationHome: {
    merge: true,
  },
  MigratedAccount: {
    merge: true,
  },
  Budget: {
    fields: {
      currentPeriodBalance: {
        merge: true,
      },
      membership: {
        merge: true,
      },
      limit: {
        merge: true,
      },
      spendableLimit: {
        merge: true,
      },
      limitWithBuffer: {
        merge: true,
      },
    },
  },
  AccountPolicyCurrency: {
    keyFields: ["currency"],
    fields: {
      enablementStatus: {
        merge: true,
      },
    },
  },
  Expense: {
    fields: {
      amountViews: {
        merge: true,
      },
      paymentStatus: {
        merge: true,
      },
      receipts: relayStylePagination([]),
    },
  },
  Account: {
    fields: {
      balanceSummary: {
        merge: true,
      },
      cashTransactions: relayStylePagination((args) =>
        args
          ? Object.keys(args).filter((key) => !["after", "first"].includes(key))
          : undefined,
      ),
    },
  },
  Card: {
    fields: {
      cardSpendLimitAssignmentSettings: {
        merge: true,
      },
    },
  },
  FinancialAccount: {
    fields: {
      details: {
        merge: true,
      },
    },
  },
  FundingSource: {
    fields: {
      data: {
        merge: true,
      },
      health: {
        merge: true,
      },
    },
  },
  DepositsAccount: {
    fields: {
      balanceBreakdown: {
        merge: true,
      },
      cashTransactions: relayStylePagination((args) =>
        args
          ? Object.keys(args).filter((key) => !["after", "first"].includes(key))
          : undefined,
      ),
    },
  },
  BillingGroup: {
    fields: {
      delinquency: {
        merge: true,
      },
      latestBill: {
        merge: true,
      },
      estimatedUpcomingPayment: {
        merge: true,
      },
      totalBalance: {
        merge: true,
      },
      spendingEntities: {
        merge: true,
      },
    },
  },
  Bill: {
    fields: {
      sourceAccount: {
        merge: true,
      },
    },
  },
  PaymentInstrument: {
    fields: {
      paymentInstrumentType: {
        merge: true,
      },
    },
  },
  InboxTask: {
    fields: {
      taskEntityDetails: {
        merge: true,
      },
    },
  },
  AccountingIntegration: {
    fields: {
      settings: {
        merge: true,
      },
    },
  },
  LegalEntity: {
    fields: {
      country: {
        merge: true,
      },
    },
  },
  ...Object.fromEntries(
    possibleTypes.InboxTaskEntityDetails.map((type: string) => [
      type,
      { keyFields: ["encodedEntityId"] },
    ]),
  ),
  AccountManagementTaskEntityDetails: {
    keyFields: false,
  },
  ExpenseExtendedField: {
    /**
     * we need to normalize and not cache this type because when querying
     * for expense details, we could fetch multiple expenseExtendedFields
     * with the same id, but different option values.
     */
    keyFields: false,
  },
  SpendLimit: {
    fields: {
      currentPeriodBalance: {
        merge: true,
      },
      authorizationSettings: {
        merge: true,
      },
      members: {
        merge: true,
      },
      owners: {
        merge: true,
      },
    },
  },
  LimitGroup: {
    fields: {
      spendableLimit: {
        merge: true,
      },
      amountSpent: {
        merge: true,
      },
      blueprint: {
        merge: true,
      },
    },
  },
  SpendLimitBlueprint: {
    fields: {
      fields: {
        merge: true,
      },
    },
  },
} satisfies StrictTypedTypePolicies;

export const createCache = () =>
  new InMemoryCache({
    possibleTypes,
    typePolicies,
  });

export const createMockedCache = () => createCache();

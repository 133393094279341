import gql from "graphql-tag";
import { inheritedMerchantCategoryControlsFragment } from "@/features/PolicyState/data/fragments";

export const ExpenseDetailsPaneApprovalsSectionRequestActionEmployeeFragment = gql`
  fragment ExpenseDetailsPaneApprovalsSectionRequestActionEmployeeFragment on Employee {
    id
    displayName
    role
    customerUserId
  }
`;

export const ExpenseDetailsPaneApprovalsSectionRequestActionOwnerFragment = gql`
  fragment ExpenseDetailsPaneApprovalsSectionRequestActionOwnerFragment on RequestActionOwner {
    ... on RequestActionManagerDifference {
      levelDifference
    }
    employee {
      id
      ...ExpenseDetailsPaneApprovalsSectionRequestActionEmployeeFragment
    }
    __typename
  }
  ${ExpenseDetailsPaneApprovalsSectionRequestActionEmployeeFragment}
`;

// Note: this fragment should only contain non-dynamic fields on the cashTransaction (safe to batch)
export const cashTransactionFragment = gql`
  fragment CashTransactionFragment on CashTransaction {
    id
    amountUsdCents
    type
    status
    origin
    displayName
    cancellationReason
    processedBalanceSnapshotUsdCents
    customerMemo
    notifyContactProcessing
    isRecurring
    bill {
      id
    }
    details {
      __typename
      ... on CashTransactionAchDetails {
        accountClass
        accountNumber
        accountType
        transferDescription
        routingNumber
        email
        paymentMethod
        companyId
        beneficiaryBankLogoBase64
        beneficiaryBank {
          name
          info
        }
      }
      ... on CashTransactionDomesticWireDetails {
        accountNumber
        beneficiaryBank {
          name
          info
        }
        beneficiaryBankLogoBase64
        email
        fedReferenceNumber
        imad
        omad
        paymentMethod
        routingNumber
        transferDescription
      }
      ... on CashTransactionInternationalWireDetails {
        accountIdentifier {
          type
          value
        }
        beneficiaryBank {
          info
          name
        }
        countryCodeAlpha2
        countryName
        email
        mt103
        paymentMethod
        swiftCode
        trackingNumber
        transferDescription
      }
      ... on CashTransactionOutgoingChequeDetails {
        chequeMemo
        chequeNumber
        email
        expectedDeliveryDate
        mailedAt
        recipientName
      }
      ... on CashTransactionPayrollEstimateDetails {
        payrollEndDate
        payrollProviderName
        payrollStartDate
      }
    }
  }
`;

export const achDetailsFragment = gql`
  fragment AchDetailsFragment on CashTransactionAchDetails {
    email
    accountType
    accountClass
    routingNumber
    accountNumber
    address {
      address1
      address2
      city
      state
      country
      zip
    }
    beneficiaryBank {
      name
      info
      address {
        address1
        address2
        city
        state
        country
        zip
      }
    }
    paymentMethod
    transferDescription
    companyId
    traceNumber
    identificationNumber
    bankProvider
  }
`;

export const domesticWireDetailsFragment = gql`
  fragment DomesticWireDetailsFragment on CashTransactionDomesticWireDetails {
    accountNumber
    address {
      address1
      address2
      city
      state
      country
      zip
    }
    beneficiaryBank {
      name
      info
      address {
        address1
        address2
        city
        state
        country
        zip
      }
    }
    beneficiaryBankLogoBase64
    email
    fedReferenceNumber
    imad
    omad
    paymentMethod
    routingNumber
    transferDescription
    drawdownAuthorizationId
    bankProvider
  }
`;

export const internationalWireDetailsFragment = gql`
  fragment InternationalWireDetailsFragment on CashTransactionInternationalWireDetails {
    amountDriftUsdCents
    accountIdentifier {
      type
      value
    }
    address {
      address1
      address2
      city
      state
      country
      zip
    }
    bankReferenceNumber
    beneficiaryBank {
      info
      name
      address {
        address1
        address2
        city
        state
        country
        zip
      }
    }
    countryCodeAlpha2
    countryName
    email
    fxQuote {
      id
      beneficiaryAmount {
        instrumentCodeString
        quantity
      }
      originatorAmount {
        instrumentCodeString
        quantity
      }
      exchangeRate
    }
    imad
    mt103
    omad
    uetr
    paymentMethod
    swiftCode
    trackingNumber
    transferDescription
    bankProvider
  }
`;

export const outgoingChequeDetailsFragment = gql`
  fragment OutgoingChequeDetailsFragment on CashTransactionOutgoingChequeDetails {
    chequeMemo
    chequeNumber
    email
    expectedDeliveryDate
    mailedAt
    recipientName
    address {
      address1
      address2
      city
      state
      country
      zip
    }
  }
`;

export const depositedChequeDetailsFragment = gql`
  fragment DepositedChequeDetailsFragment on CashTransactionDepositedChequeDetails {
    backImageUrl
    chequeMemo
    frontImageUrl
    senderName
  }
`;

export const cashTransactionCardCollectionDetailsFragment = gql`
  fragment CashTransactionCardCollectionDetailsFragment on CashTransactionCardCollectionDetails {
    collectionId
    billingPayment @include(if: $canSeeAllCardTransactions) {
      id
      initiatedBy {
        ... on AutoInitiated {
          originatorType
        }
        ... on CustomerInitiated {
          user {
            id
            firstName
            lastName
          }
        }
      }
    }
  }
`;

export const sourceAccountFragment = gql`
  fragment SourceAccountFragment on SourceAccount {
    __typename
    ... on DepositsAccount {
      id
      name
      nickname
      displayColor
      accountNumber
      accountNumberLastFour
    }
    ... on FundingSource {
      id
      type
      data {
        __typename
        accountName
        accountNumberLastFour
      }
      financialAccount {
        id
        details {
          accountNumberLast4
        }
        source {
          id
          institution {
            id
            name
            imageUrls {
              icon
              logo
            }
            vendorsMapping {
              logoBase64
            }
          }
        }
      }
    }
    ... on RailAccountDetails {
      id
      displayName
    }
  }
`;

// NOTE: Disable id requirement to omit:
// - integrationField id - field id gets cached if since these two values are the same
// - integrationRuleEvaluations id - multiple evaluations with the same id can be returned, so must
//   omit to get the separate instances
//
// TODO: When [AE-839] is implemented, re-add id and remove eslint disable.
export const IntegrationRuleEvaluationsFragment = gql`
  fragment IntegrationRuleEvaluationsFragment on ExpenseLens {
    id
    integrationRuleEvaluations @include(if: $isAccountingCustomRulesEnabled) {
      integrationRule(ruleBodyEnriched: true) {
        id
        name
        type
        ... on IntegrationRule @include(if: $includeRuleEvaluationBody) {
          body
        }
      }
      integrationField {
        fieldId
        fieldValue
      }
      userCategoryId
    }
  }
`;

export const IntegrationFieldFragment = gql`
  fragment IntegrationFieldFragment on ExternalIntegrationField {
    id
    name
    type
    integrationId
    isInactive
    deletedAt
    # TODO: Delete once BE supports display_name
    externalKey
    externalMetadata
  }
`;

// NOTE: Disable id requirement to omit integrationField id, otherwise obj is cached
// (despite having different fieldValue)
// TODO: When [AE-839] is implemented, re-add id and remove eslint disable.
export const IntegrationFieldsExternalIntegrationEntityFragment = gql`
  fragment IntegrationFieldsExternalIntegrationEntityFragment on ExpenseLens {
    id
    integrationFields {
      externalIntegrationEntity {
        id
        externalIntegrationFieldId
        isInactive
        deletedAt
        name
      }
    }
  }
`;

export const VatExpenseExtendedFieldOptionFragment = gql`
  fragment VatExpenseExtendedFieldOptionFragment on ExtendedFieldOption {
    identifier
    status
    subFieldIds
    value
  }
`;

export const VatExpenseExtendedFieldFragment = gql`
  fragment VatExpenseExtendedFieldFragment on ExtendedField {
    id
    key
    name
    employeeEditable
    fieldType
    group
    status
    options(filter: { fieldOptionViews: [INCLUDE_DISABLED] }) {
      edges {
        node {
          ...VatExpenseExtendedFieldOptionFragment
        }
      }
    }
  }
  ${VatExpenseExtendedFieldOptionFragment}
`;

export const BudgetTemplateFlightLegFragment = gql`
  fragment BudgetTemplateFlightLegFragment on BudgetTemplateFlightLeg {
    origin {
      airportCode
      displayName
    }
    destination {
      airportCode
      displayName
    }
    date
  }
`;

export const CommentSuggestionsResultFragment = gql`
  fragment CommentSuggestionsResultFragment on CommentSuggestionsResult {
    externalEntityId
    externalEntityType
    suggestions {
      ... on CommentSendReminderSuggestion {
        __typename
        lastReminderSentAt
        commentText
        unformattedCommentText
        taggedEmployees {
          entityAccessLevel
          taggedEmployee {
            id
            customerUserId
            displayName
          }
        }
      }
      ... on CommentAddMemoSuggestion {
        __typename
        commentId
      }
    }
  }
`;

export const expenseFundingEntityFragment = gql`
  fragment ExpenseFundingEntityFragment on Expense {
    id
    fundingLegalEntity {
      id
      displayName
      billingGroups(productTypes: [CARD, REIMBURSEMENTS, EMPLOYEE_TRACKING]) {
        id
        productType
        billingFinancialInstrument {
          instrumentCodeString
        }
        isPushPayments
        autoCollectionStatus
      }
    }
  }
`;

export const budgetTemplateFragmentV2 = gql`
  fragment BudgetTemplateFragmentV2 on BudgetTemplate {
    __typename
    id
    type
    name
    description
    status
    properties {
      templatePolicyId
      expensePolicy {
        id
        displayName
        currency
        eligibleWorkflows
      }
      limit {
        quantity
        instrumentCodeString
      }
      enforceLimitBufferPercentage
      limitIncreaseRequestEnabled
      limitVisibility
      expenseVisibilityShared
      startDate
      periodType
      endDate
      onlyProvisionedCardSpend
    }
  }
`;

export const budgetMerchantCategoryConfigFragmentV2 = gql`
  fragment BudgetMerchantCategoryConfigFragmentV2 on MerchantCategoryConfig {
    controlReference {
      __typename
      ... on MerchantCategoryIdControlReference {
        __typename
        merchantCategoryId
        merchantCategoryName
      }
      ... on MerchantNameControlReference {
        __typename
        merchantName
        categoryId
        categoryName
      }
    }
    controlType
  }
`;

export const parentBudgetFragmentV2 = gql`
  fragment ParentBudgetFragmentV2 on Budget {
    id
    name
    parent {
      id
    }
    currentPeriodBalance {
      spent {
        instrumentCodeString
        quantity
      }
    }
    enforceLimit
    enforceLimitBufferPercentage
    limit {
      instrumentCodeString
      quantity
    }
    policy {
      id
      displayName
      currency
    }
    inheritedMerchantCategoryControls {
      ...InheritedMerchantCategoryControlsFragment
    }
    template {
      id
      ...BudgetTemplateFragmentV2
    }
    memberEmployees {
      totalCount
    }
    ownerEmployees {
      edges {
        node {
          id
          customerUserId
          displayName
          role
          email
        }
      }
    }
    startDate
    endDate
    periodType
  }
  ${inheritedMerchantCategoryControlsFragment}
  ${budgetTemplateFragmentV2}
`;

export const employeeFragment = gql`
  fragment EmployeeFragment on Employee {
    id
    customerUserId
    displayName
    titleDisplayName
    role
  }
`;

export const budgetEmployeesFragment = gql`
  fragment BudgetEmployeesFragment on BudgetEmployeeConnection {
    totalCount
    edges {
      node {
        ...EmployeeFragment
      }
    }
  }
  ${employeeFragment}
`;

export const budgetAttributesFromTemplateFragment = gql`
  fragment BudgetAttributesFromTemplateFragment on BudgetTemplateDerivedMetadata {
    id
    budgetTemplateId
    budgetTemplateType
    budgetTemplateCreationMetadata {
      __typename
      ... on BudgetTemplateOffsiteMetadata {
        startDate
        endDate
        location {
          displayName
          googlePlacesExternalId
        }
      }
      ... on BudgetTemplateTravelMetadata {
        startDate
        endDate
        travelType
        fromLocation {
          displayName
          googlePlacesExternalId
        }
        toLocations {
          displayName
          googlePlacesExternalId
        }
        flightLegs {
          ...BudgetTemplateFlightLegFragment
        }
      }
    }
  }
  ${BudgetTemplateFlightLegFragment}
`;

export const expenseStatusPollingFragment = gql`
  fragment ExpenseStatusPollingFragment on Expense {
    id
    status
  }
`;

export const ExtendedFieldValuesInputFragment = gql`
  fragment ExtendedFieldValuesInputFragment on ExpenseFieldValue {
    __typename
    ... on ExpenseBoolValue {
      id
      boolValue: value
    }
    ... on ExpenseNumberValue {
      id
      decimalValue: value
    }
    ... on ExpenseIdentifierToStringValue {
      id
      displayName
      identifier
      subFieldIds
    }
    ... on ExpenseStringValue {
      id
      stringValue: value
    }
    ... on ExpenseEnumValue {
      id
      enumValue: value
    }
    ... on ExpenseFinancialAssetValue {
      id
      financialAssetValue: value {
        instrumentCodeString
        quantity
      }
    }
  }
`;

export const datagridExpenseBudgetFragment = gql`
  fragment DatagridExpenseBudgetFragment on Expense {
    id
    status
    type
    purchasedAt
    amountViews {
      originalAmount {
        instrumentCodeString
        quantity
      }
      billingAmount {
        instrumentCodeString
        quantity
      }
      budgetAmount {
        instrumentCodeString
        quantity
      }
      purchasedAmount {
        instrumentCodeString
        quantity
      }
    }
    budget {
      id
      ancestryPath {
        id
        name
      }
      budgetType
      name
      status
      template {
        id
        name
        type
      }
    }
    employee {
      id
      customerUserId
      displayName
    }
    paymentStatus {
      statusType
    }
    originator {
      __typename
      ... on CardTransaction {
        id
        dispute {
          id
          status
          activeExpenses {
            id
          }
        }
      }
    }
    limitAssignmentExtendedFieldValues: extendedFieldValues {
      __typename
      id
      key
      values {
        __typename
        ...ExtendedFieldValuesInputFragment
        ... on ExpenseExtension {
          id
          extension {
            __typename
            ... on BudgetAssignmentExtension {
              id
              autoAssigned
              confidenceLevel
              confirmedByUser
            }
          }
        }
      }
    }
  }

  ${ExtendedFieldValuesInputFragment}
`;

// TODO: to replace automationWorkflowRuleFragment in Accounting fields
export const workflowRuleFragment = gql`
  fragment WorkflowRuleFragment on WorkflowRule {
    actions {
      ... on SetAction {
        leftHandSide {
          ... on CoreFieldAddress {
            address
          }
          ... on ExtendedFieldAddress {
            id
          }
        }
        rightHandSide {
          ... on RightHandSideSingleValue {
            value {
              ... on IdentifierToStringValue {
                displayName
                identifier
              }
            }
          }
        }
      }
    }
    filters {
      leftHandSide {
        ... on CoreFieldAddress {
          address
        }
        ... on ExtendedFieldAddress {
          id
        }
      }
      rightHandSide {
        ... on RightHandSideSingleValue {
          value {
            ... on IdentifierToStringValue {
              displayName
              identifier
            }
          }
        }
      }
    }
    id
  }
`;

// TODO: replace automationExtendedFieldFragment in Accounting Fields V2
export const automationExtendedFieldFragment = gql`
  fragment AutomationExtendedFieldFragment on ExtendedField {
    editableBy
    fieldType
    group
    id
    isReferenceable
    lastUpdatedAt
    name
    optionsWithSubfieldsCount
    parents {
      id
    }
    status
    tags {
      key
      value
    }
  }
`;

export const policyListOptionPolicyFragment = gql`
  fragment PolicyListOptionPolicyFragment on Policy {
    id
    displayName
    description
    currency
    activeSpendLimitCount
    status
    isDefault
  }
`;

import { JotaiMockProvider } from "@brexhq/jotai-utils/testing";
import { useAtomValue } from "jotai";
import * as React from "react";
import {
  userPropertiesAtom,
  userPropertiesForContextAtom,
} from "@/features/Authentication/atoms/userIdentityAtoms";
import { useInitializeUserPropertiesQueries } from "@/features/Authentication/data/queries";
import type { UserPropertiesContextProps } from "@/features/Authentication/sharedTypes";
import { useDeepMemo } from "@/hooks/useDeepMemo";

export const UserPropertiesContext = React.createContext<
  UserPropertiesContextProps | undefined
>(undefined);

// TODO: Move this to testing utils
export const MockUserPropertiesContextProvider: React.FC<
  Partial<UserPropertiesContextProps> & { overwrite?: boolean }
> = ({
  children,
  accountId,
  dbaName,
  isInitialApplicant,
  userId,
  userRole,
  userDisplayName,
  segmentV4,
  attributionToken,
  overwrite = true,
  hasPartnershipIntegration,
  partnerName,
}) => (
  <React.Suspense fallback={null}>
    <JotaiMockProvider
      mockedAtoms={[
        [
          userPropertiesAtom,
          {
            accountId,
            dbaName,
            isInitialApplicant,
            userId,
            userRole,
            userDisplayName,
            segmentV4,
            attributionToken,
            hasPartnershipIntegration,
            partnerName,
          },
        ],
      ]}
      overwrite={overwrite}
    >
      <UserPropertiesContext.Provider
        value={{
          accountId,
          dbaName,
          isInitialApplicant,
          userId,
          userDisplayName,
          segmentV4,
          attributionToken,
        }}
      >
        {children}
      </UserPropertiesContext.Provider>
    </JotaiMockProvider>
  </React.Suspense>
);

/**
 * `useInitializeUserPropertiesQueries` needs to be called from a separate component from
 * `ClearUserPropertiesMockContextProvider` because otherwise it will use the mocked values
 */
const InitializeUserPropertiesQueries = ({
  children,
}: React.PropsWithChildren<{}>) => {
  const { loading } = useInitializeUserPropertiesQueries();

  return loading ? null : <>{children}</>;
};

/**
 * This component clears user properties mocks from `MockUserPropertiesContextProvider` and
 * initializes it from GQL. It's meant to be used  in interaction tests where you want user
 * properties to come from GQL, as `userPropertiesDecorator` includes that mock provider by
 * default in Storybook
 **/
export const ClearUserPropertiesMockContextProvider = ({
  children,
}: React.PropsWithChildren<{}>) => (
  <React.Suspense fallback={null}>
    <JotaiMockProvider
      mockedAtoms={[[userPropertiesAtom, undefined]]}
      overwrite
    >
      <UserPropertiesContext.Provider value={undefined}>
        <InitializeUserPropertiesQueries>
          {children}
        </InitializeUserPropertiesQueries>
      </UserPropertiesContext.Provider>
    </JotaiMockProvider>
  </React.Suspense>
);

export const useUserProperties = () => {
  const userPropertiesFromAtoms = useAtomValue(userPropertiesForContextAtom);

  // Using context here allows for overriding the values for copilot in certain sections
  const userPropertiesFromContext = React.useContext(UserPropertiesContext);

  return useDeepMemo({
    ...userPropertiesFromAtoms,
    ...(userPropertiesFromContext ?? {}),
  });
};

export const useUserRole = () => useUserProperties().userRole;

export const useUserId = () => useUserProperties().userId;

export const useAccountId = () => useUserProperties().accountId;

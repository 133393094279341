// Naming convention for future additions, feature flags should be prefixed
// with a domain identifier, ex: primitives.[feature-flag-name]

/**
 * Don't use this outside of the context of this file (or its tests)
 *
 * An array of tuples with feature flag name strings and their default values.
 * Feature flag name strings correspond to their key on LaunchDarkly.
 *
 * In order to access a feature flag value, you must add the feature flag name
 * and its default value to this tuple array.
 *
 * Adding a default value helps in providing an appropriate type for the feature
 * flag value as well as guards against an unexpected value when the LaunchDarkly
 * service is down.
 *
 * When a feature flag is archived, please remove its tuple from this array.
 */
export default [
  ["empower-receipt-affidavit", false],
  ["primitives-mobile-upsells", false],
  ["primitives-mobile-upsells-reset-state", false],
  ["accounts-module-v2", false],
  ["show-credit-alerts-banners", false],
  // Default to true to preserve correct behavior on Empower Full
  ["primitives-parent-budget-reassignment", false],
  ["bill-pay-empower-eba-international-wire-countries", [] as string[]],
  // Repayments
  ["mm-employee-repayment-v1", false],
  ["pup-edd-repayment", false],
  ["dda-l3", false],
  ["allow-manually-connected-funding-source", false],
  ["empower-expense-categories", false],
  // Multi-entity
  ["multi-entity-frontend", true],
  ["multi-entity-reimbursements-frontend", false],
  ["multi-entity-configure-reimbursements-tracking", false],
  ["multi-entity-configure-reimbursements-tracking-for-us-entities", false],
  ["multi-entity-editions-frontend", false],
  ["integrations.change-integration-spending-entity-whitelist", false],
  ["empower-account-timezones", false],
  ["reimbursements-auto-retry-failed-after-fixes", false],
  ["local-autopay-enabled", false],
  ["spend-limit-user-group-members", false],
  ["local-autopay-enablement-banner", false],
  ["locally-funded-bill-pay", false],
  // Policy
  ["dynamic-policy-doc-approval-chain", false],
  ["roblox-one-off-per-diem-tracker", false],
  /**
   * this is a Roblox on-off request
   * it is not recommended practice to accept one-off requests from customers
   * here are some context, on why we are doing it in this case:
   * https://brex.slack.com/archives/C02JJC85BSR/p1694550695805079?thread_ts=1694549899.476039&cid=C02JJC85BSR
   * https://github.com/brexhq/credit_card/pull/154958
   */
  ["is-roblox", false], // DO NOT DELETE
  ["airbnb-demo-list-attendees", false],
  ["policy-simulation-vx-fields", false],
  ["expense-category-based-policy-rules", false],
  ["travel.enable-booking-approvals", false],
  ["travel.booking-approval-ga-features", false],
  ["travel-essentials-consent-gather", false],
  ["travel-spotnana-twilio-chat-enabled", false],
  ["show-budgets-table-template-filter", false],
  ["temp-budgets-table-status-filter-disable-top-level", false],
  ["expense-policy-reasons-v3-fe", false],
  ["policy-workflow-builder", false],
  // allow mcc controls to be set on a spend limit restriction,
  ["b24-mcc-controls", false],
  // Budget templates
  ["dynamic-currency-policy", false],
  ["travel-budget-estimator", false],
  // Expenses
  ["empower-expenses-approval-status-grouping", false],
  ["empower-budget-timezones", false],
  ["precompute-integration-lens", "OFF"],
  ["empower-accounting-custom-report-accounting-education-cohort", false],
  [
    "empower-accounting-custom-report-accounting-education-not-exported-days",
    -10000,
  ],
  ["empower-accounting-streamlined-rules-vat-action", false],
  ["empower-accounting-export-history-m1", false],
  ["empower-accounting-export-history-m2", false],
  ["empower-accounting-journal-v2-suggestions", false],
  ["empower-accounting-journal-v2-bulk-suggestions", false],
  ["empower-accounting-journal-accruals", false],
  ["empower-accounting-journal-sort-by-posted-date", false],
  ["empower-accounting-streamlined-rules-bulk-suggestions", 10000],
  ["spend-accounting-self-serve-unexport", false],
  ["empower-accounting-vat", false],
  ["empower-accounting-vat-1.5", false],
  ["empower-axp-and-int-merchant-to-vendor", false],
  ["empower-extended-fields-compliance-filters", false],
  ["delete-accounting-fields-on-csv-integration-disable", false],
  ["custom-mileage-rate-69-cents", false],
  ["accounting-automation-m2", false],
  ["25q1-accounting-automation-l2-page", false],
  ["accounting-custom-rule-integration-field-filters", false],
  ["accounting-managing-custom-fields", false],
  ["accounting-managing-custom-field-option-by-budget-templates", false],
  ["accounting-managing-custom-fields-bypass-employee-only-visibility", false],
  ["accounting-managing-custom-fields-permissions", false],
  ["custom-accounting-field-milestone-2", false],
  ["custom-accounting-field-billpay-universal-csv", false],
  ["accounting-vx-workflow-enabled", false],
  ["accounting-journal-initial-page-size", 25],
  ["accounting-journal-datagrid-receipt-column", false],
  ["accounting-journal-nested-budget-filter", false],
  ["accounting-journal-datagrid-render-logs", false],
  ["accounting.credit-gls", false],
  ["accounting.export-accruals", false],
  ["accounting.export-accruals-v2", false],
  ["accounting.interco-reimbursements-override", false],
  ["accounting.export-accruals-v2-filter", false],
  ["accounting-field-dependent-list", false],
  ["empower-create-card-enabled", false],
  ["empower-uber-integration", false],
  ["empower-accounting-ftux-suggestions-rearch", false],
  ["expense-improvements-for-reimbursements", false],
  ["edp-v-2-expense-category-suggestions", false],
  ["budgets-creation-nested-and-overrides", true], // Already True for everyone, to be removed soon
  ["account-state-cache-first", false],
  ["accounting.1-brex.vat-m1", false],
  ["accounting.datagrid-jobs-menu", false],
  ["accounting.fields-refresh-and-auto-sync-menu", false],
  ["accounting-settings-v-2", false],
  ["expense-edit-entity-enabled", false],
  ["empower-expense-autosave-v1", false],
  ["spend-expenses-custom-hierarchy-fields", false],
  ["growth-empower-move-autopay", false],
  ["essentials-stipends-enabled", false],
  ["expense-attendee-suggestions", false],
  ["shared-expense-budget-selection-dropdown", false],
  ["expenses-compliance-filter-per-diem-violation", false],
  ["expenses.hide-non-compliant-expenses", false],
  ["fraud-alerts-primitives", false],
  ["vat-legal-entity-visibility-override", false],
  ["budget-templates-disabled", false],
  ["svb-empower-implementation", false],
  ["bulk-invite-increased-page-size", false],
  ["empower-budgets-distribution", false],
  ["brex-assistant", false],
  ["brex-assistant-settings-calendar-oauth-v2", false],
  ["brex-assistant-chat-debug", false],
  ["brex-assistant-milestone-2", false],
  ["wallet-v2-tooltip-tour", false],
  ["new-virtual-card-takeover-infinite-scroll", false],
  ["budgets-v2-exclusions-list", false],
  ["budgets-tree-view", false],
  ["show-bifurcation-prelaunch-banner", false],
  ["show-bifurcation-prelaunch-banner-empower-plus", false],
  ["create-form-member-exclusions", false],
  ["accounting-infinite-scroll", false],
  ["card-ownership-transfer-flow", false],
  ["hris-legal-entity-matching", false],
  ["hris-changelog", false],
  ["accounting.1brex-phase1-qbo", false],
  ["multi-entity-accounting-legacy", false],
  ["global-search", false],
  ["global-search-account-menu-entry", false],
  ["post-auth-budget-suggestions", false],
  ["cip-refresh-enabled", false],
  ["ny-demographics-enabled", false],
  ["ownership-resequence-enabled", false],
  ["first-spend-launch-date", "9999-12-31"],
  ["budget-setup-helpers-netsuite-integration", false],
  ["spend-limits-datagrid", false],
  ["spend-improve-request-types-on-inactive-parent-budget", false],
  ["pci-card-legacy-mode-enabled", true],
  ["ai-comment-settings-and-upsells", false],
  ["ai-review-settings", false],
  ["tmc-card-m-1-enabled", false],
  ["expense-details-travel-data", false],
  ["lodge-card-request-plan", false],
  ["card-decouple-access-permission-m1", false],
  ["essentials-csv-enabled", true],
  ["remove-mileage-reimbursements", false],
  ["chat-in-slack", false],
  ["override-erp-multi-entity-supported", false],
  ["decoupling-limits-from-expenses", false],
  ["card-expense-itemization-enabled", true],
  ["mileage-expense-category-default-option-identifier", ""],
  ["self-serve-delete-reimbursements", false],
  ["expense-itemization-split-by-percentage", false],
  ["disable-expense-entities-dropdown-for-employees", false],
  ["25q1-pcard-taxonomy", false],
  // Accounting
  ["me-accounting-onboarding-m1", false],
  ["accounting-vat-automation", false],
  ["allow-exp-cat-update-post-export", false],
  ["24q1-brex-file-transfer", false],
  ["hide-accounting-journal-unexport", false],
  ["accounting-expense-categories-self-serve", false],
  ["essentials-v-2-toggle-custom-rules", false], // Fast follow from Fall 24 release
  // Integrations
  ["integrations.locally-funded-reimbursements", false],
  ["integrations.accrual_basis_reimbursements_phase_3", false],
  ["integrations.vat-gl-account-settings", false],
  ["integrations.netsuite_employee_field", false],
  ["integrations.multiple-legal-entity-mappings", false],
  ["consolidated-billing-modern-merchant-search", true],
  ["spend.deprecate-settle-expense", false],
  ["auto-invite-settings", false],
  ["decoupled-access-travel-vip-user", false],
  ["expense-admin-role", false],
  ["integrations.billsync-1b", false],
  ["expenses-ai-smart-filtering", false],
  ["budgets-budget-generation", false],
  ["empower-card-pan-cvv-query-rerouting", false],
  ["empower-card-pan-cvv-query-rerouting-compare-with-legacy-results", false],
  ["empower-card-pan-cvv-query-rerouting-shadow-mode", false],
  ["spend-auto-budget-assignment-enabled", false],
  ["shipping-address-phone-number", false],
  // Reporting
  ["travel-reporting", false],
  ["reporting-new-export-expenses", false],
  ["reporting-budget-filters-enabled", false],
  ["reporting-negative-bars", false],
  ["reporting-user-entity", false],
  // Search
  ["expenses-prototype-local-search", false],
  ["enable-scoped-search-on-legacy-tables", true],
  ["scoped-search-new-ranking-api", false],
  // Global Search V2
  ["show-global-search-v2", false],
  // Semantic Search
  ["expense-filters-vendor-category-semantic-search-enabled", false],
  // Airbnb demo
  ["hail-mary-task-inbox-show-pending-card-expenses", false],
  ["hail-mary-hide-non-blocking-errors", false],
  ["expenses-data-table-playground-enabled", false],
  ["accounting-journal-datagrid-fixed-layout", false],
  ["user-groups-enabled", false],
  // Spend migration
  ["spend-migration-sunlight-enabled", false],
  ["spend-migration-manually-add-vendors", false],
  ["spend-migration-upload-card-statements", false],
  ["spend-migration-enable-existing-customers", false],
  // Banking
  ["banking-cuj-surveys", false],
  ["banking-demo", false],
  ["banking-dda-enabled", false],
  ["banking-dda-automatic-transfers", false],
  ["banking-dda-migration-with-verification-steps", false],
  ["banking-dda-migration", false],
  ["charge-principal-protection-fee", false],
  ["customer-payment-tracking", false],
  ["customer-payment-tracking-bill-pay", false],
  // Expense reports
  ["expense-reports-test-1", false],
  ["expense-reports-test-1-tasks-on-wallet", false],
  ["expense-reports-test-2", false],
  /**
   * Begin Datadog one-off request flags
   */
  ["budget-req-templates-without-custom-contract-field", ""], // DO NOT DELETE
  ["budget-request-contract-field-email", ""], // DO NOT DELETE
  /**
   * End Datadog one-off request flags
   */
  ["roles-super-admin", false],
  ["hide-expenses-table-error", false],
  ["entity-page", false],
  ["datagrid-on-wallet", false],
  ["datagrid-on-all-expenses", false],
  ["datagrid-api-v2-enabled", false],
  ["audit-log-enabled", false],
  ["tasks-inbox-new-ia", false],
  ["accidental-decline-protection", false],
  ["expense-edit-entity-enabled", false],
  ["candidate-reimbursements", false],
  ["integrations.accounting_money_movement_refunds", false],
  ["expense-edit-entity-post-clearing-enabled", false],
  ["no-datagrid-cell-max-width", false],
  // OneTimePayment
  ["billing-use-early-crediting-v-3", false],
  [
    "banking-integrations-billing.bpi-collection-promises-migration-billing-groups",
    false,
  ],
  ["team-datagrid-enabled", false],
  ["team-data-grid-enabled-phase2", false],
  // Removing budget program of shared spend limits
  ["shared-spend-limits-without-budget-program", false],
  ["team-shelf-enabled", false],
  ["team-archived-status-filter-enabled", false],
  ["datagrid-expenses-fixed-layout-fullscreen", false],
  ["pro-access-bill-pay-drafter", false],
  ["logout-on-access-denied", false],

  // #region 25Q1
  // All 25 Q1 Flags should live in this region, sorted alphabetically:
  ["25q1-batched-bill-pay", false],
  ["25q1-custom-roles-team-domain", false],
  ["25q1-sunshine-act-bulk-add-attendees", false],
  // #endregion
] as const;

import type { Option } from "@brexhq/component-library";
import type { FinancialAsset } from "@brexhq/currency-ts";
import type * as React from "react";
import type { SetRequired } from "type-fest";
import type {
  BudgetPeriodType,
  CashTransactionType,
  CashTransactionStatus,
  CashTransactionPaymentMethod,
  CashTransactionChequeReturnReason,
  CashTransactionAchReturnReason,
  CashTransactionWireReturnReason,
  DepositsAccountsDisplayColor,
  MerchantCategoryControlType,
  BudgetAuditLogActionType,
  BudgetTemplateType,
  ExpensePaymentStatusType,
  ExpenseType,
  BudgetLimitVisibilityType,
  BudgetStatus,
  CashTransactionCancellationReason,
  BudgetType,
  ExpenseStatus,
  BillOrigin,
  StatusView,
  SpendLimitStatus,
  SpendLimitAuthorizationVisibility,
} from "@/__generated__/globalTypes";
import {
  ErrorCode,
  AccountingJournalEntryAlert,
  VerificationAnalysisResult,
} from "@/__generated__/globalTypes";
import type { AdditionalOptionProps } from "@/components/GenericOptionInput";
import type { OfficeAddress } from "@/data/sharedTypes";
import type {
  IntegrationRuleEvaluationsFragment_ExpenseLens_integrationRuleEvaluations_RuleEvaluation_integrationRule_IntegrationRule as IntegrationRule,
  DatagridExpenseBudgetFragment_Expense_budget_Budget as DatagridBudgetCellFragmentBudget,
  DatagridExpenseBudgetFragment_Expense_originator as DatagridBudgetCellFragmentOriginator,
} from "@/domains/Primitives/shared/data/__generated__/fragments.generated";
import type {
  GetFSPClients_getFSPClients_FSPClientAccount as GetFSPClients_getFSPClients,
  VatExpenseExtendedFieldsQuery_extendedFields_ExtendedFieldConnection_edges_ExtendedFieldEdge_node_ExtendedField as VatExtendedField,
  VatExpenseExtendedFieldsQuery_extendedFields_ExtendedFieldConnection_edges_ExtendedFieldEdge_node_ExtendedField_options_ExtendedFieldOptionConnection_edges_ExtendedFieldOptionEdge_node_ExtendedFieldOption as VatExtendedFieldOption,
} from "@/domains/Primitives/shared/data/__generated__/queries.generated";
import type { FormattedExpenseExtendedFieldValue } from "@/features/AccountingInputs/sharedTypes";
import type { AccountingIntegrationsQuery_accountingIntegrations_AccountingIntegrationConnection_edges_AccountingIntegrationEdge_node_AccountingIntegration_subsidiarySettings_AccountingIntegrationSubsidiarySettings as AccountingIntegrationSubsidiarySettings } from "@/features/AccountingIntegrations/data/__generated__/queries.generated";
import type { OperationVariables, QueryHookOptions } from "@/features/Apollo";
import type { ExpenseAmounts } from "@/helpers/data/amountView";

export type BudgetOption = {
  ancestryPath?: any[];
  label: string;
  value: string;
  budgetChildren?: {
    id: string;
    name: string;
  }[];
  budgetChildrenTotalCount?: number | null;
  memberEmployeesTotalCount?: number | null;
  templateType?: BudgetTemplateType | null;
};

export type InputOption = {
  value: string;
  label: string;
};

export enum IntegrationVendors {
  browserExtension = "browserExtension",
  concur = "concur",
  coupa = "coupa",
  csv = "csv",
  expensify = "expensify",
  gusto = "gusto",
  hris = "hris",
  intacct = "intacct",
  sage_intacct = "sage_intacct",
  netsuite = "netsuite",
  onePassword = "onePassword",
  oracle_fusion = "oracle_fusion",
  pilot = "pilot",
  qbd = "qbd",
  quickbooks = "quickbooks",
  xero = "xero",
  workday = "workday",
  zapier = "zapier",
  uber = "uber",
}

export enum BillPayLocations {
  billDetails = "billDetails",
  bills = "bills",
  integrationCredentialIssueModal = "integrationCredentialIssueModal",
  integrationBillSyncSetupModal = "integrationBillSyncSetupModal",
  integrationDetails = "integrationDetails",
  integrations = "integrations",
  integrationSetupModal = "integrationSetupModal",
  premiumUpsell = "premiumUpsell",
  integrationSubscriptionIssueModal = "integrationSubscriptionIssueModal",
}

export const VendorMaxMemoLengths: {
  [key in IntegrationVendors]: number | undefined;
} = {
  [IntegrationVendors.csv]: undefined,
  [IntegrationVendors.concur]: undefined,
  [IntegrationVendors.coupa]: undefined,
  [IntegrationVendors.expensify]: undefined,
  [IntegrationVendors.gusto]: undefined,
  [IntegrationVendors.hris]: undefined,
  [IntegrationVendors.intacct]: undefined,
  [IntegrationVendors.sage_intacct]: undefined,
  [IntegrationVendors.netsuite]: 800,
  [IntegrationVendors.onePassword]: undefined,
  [IntegrationVendors.oracle_fusion]: undefined,
  [IntegrationVendors.pilot]: undefined,
  [IntegrationVendors.qbd]: undefined,
  [IntegrationVendors.quickbooks]: 4000,
  [IntegrationVendors.xero]: 4000,
  [IntegrationVendors.workday]: undefined,
  [IntegrationVendors.zapier]: undefined,
  [IntegrationVendors.browserExtension]: undefined,
  [IntegrationVendors.uber]: undefined,
};

export enum PrimitivesIntegrationVendors {
  adp = "adp(workforcenow)",
  bamboo = "bamboohr",
  charlie = "charlie",
  charthop = "charthop",
  dayforce = "dayforce",
  deel = "deel",
  freshteam = "freshteam",
  gusto = "gusto",
  hibob = "hibob",
  hrcloud = "hrcloud",
  hrpartner = "hrpartner",
  humaans = "humaans",
  justworks = "justworks",
  kallidus = "kallidus",
  lano = "lano",
  namely = "namely",
  nmbrs = "nmbrs",
  paychex = "paychex",
  paycor = "paycor",
  paylocity = "paylocity",
  personio = "personio",
  proliant = "proliant",
  rippling = "rippling",
  sagehr = "sagehr",
  slack = "slack",
  squarepayroll = "squarepayroll",
  successfactors = "successfactors",
  trinethrplatform = "trinethrplatform",
  trinet = "trinet",
  ukgpro = "ukgpro",
  ukgready = "ukgready",
  workday = "workday",
  chromeExtension = "chromeextension",
  concur = "concur",
  coupa = "coupapay",
  csv = "csv",
  expensify = "expensify",
  gmail = "gmail",
  hris = "hris",
  intacct = "intacct",
  sage_intacct = "sage_intacct",
  netsuite = "netsuite",
  onePassword = "1password",
  oracle_fusion = "oracle_fusion",
  pilot = "pilot",
  qbd = "qbd",
  quickbooks = "quickbooks",
  uberforbusiness = "uberforbusiness",
  lyft = "lyft",
  xero = "xero",
  zapier = "zapier",
}

export enum BudgetDetailsTab {
  ThisBudget = "this-budget",
  NestedBudgets = "nested-budgets",
  Expenses = "expenses",
  Cards = "cards",
}

export enum ExpensesTab {
  Inbox = "inbox",
  All = "all",
}

// source of truth for user-facing labels of expense payment status
export enum ExpensePaymentStatusLabels {
  CANCELED = "Canceled",
  DECLINED_CARD = "Declined",
  DECLINED = "Failed",
  CLEARED = "Completed",
  CLEARED_REPAYMENT = "Paid",
  REFUNDING = "Refunding",
  REFUNDED = "Refunded",
  CASH_ADVANCE = "Cash advance",
  CREDITED = "Chargeback",
  AWAITING_PAYMENT = "Awaiting payment",
  PROCESSING = "Processing",
  PROCESSING_REIMBURSEMENT = "Not paid",
  TRACKING_EXPORTED = "Processed",
  TRACKING_EXPORTED_ADMIN = "Exported",
  TRACKING_NOT_EXPORTED = "Not processed",
  TRACKING_NOT_EXPORTED_ADMIN = "Not exported",
}

// source of truth for user-facing labels of expense approval status
export enum ExpenseApprovalStatusLabels {
  DRAFT = "Draft",
  SUBMITTED = "Submitted",
  SUBMITTED_REPAYMENT = "Repayment requested",
  PROCESSING_REPAYMENT = "Payment processing",
  APPROVED = "Approved",
  OUT_OF_POLICY = "Denied",
  OUT_OF_POLICY_CARD = "Pending repayment",
  VOID = "Deleted",
  SETTLED = "Settled",
  CANCELED = "Canceled",
  PAID = "Paid",
  FAILED = "Failed",
}

// ================== Display Amounts ==================
// OriginalAmount is only shown to users if its an reimbursement, so for display purposes, we use "Reimbursement amount"
export enum ExpenseAmountLabel {
  BILLING = "Billing",
  BUDGET = "Budget",
  RECEIPT = "Receipt", // AKA PurchasedAmount
  REIMBURSEMENT = "Reimbursement", // AKA OriginalAmount
}

export type SecondaryAmountsType = {
  [key in ExpenseAmountLabel]?: FinancialAsset;
};

export type DisplayAmountsType = {
  primaryAmount: FinancialAsset | undefined;
  secondaryAmounts: SecondaryAmountsType;
};

type GetDisplayAmountsProps = {
  amounts: ExpenseAmounts;
  paymentStatusType: ExpensePaymentStatusType | null | undefined;
  expenseType: ExpenseType;
};

export type GetDisplayAmountsType = (
  props: GetDisplayAmountsProps,
) => DisplayAmountsType;

//=============================================

export enum FilterKeys {
  dateRange = "dateRange",
  dateType = "dateType",
  from = "from",
  to = "to",
  hasMemo = "hasMemo",
  hasReceipt = "hasReceipt",
  hasBillPayInvoice = "hasBillPayInvoice",
  billPayInvoiceNumber = "billPayInvoiceNumber",
  attendeesContainGovernmentOfficial = "attendeesContainGovernmentOfficial",
  compliance = "compliance",
  budget = "budget",
  budgetTemplate = "budgetTemplate",
  approvalStatus = "approvalStatus",
  paymentStatus = "paymentStatus",
  approval = "approval",
  type = "expenseType",
  incurredBy = "incurredBy",
  sortBy = "sortBy",
  sortDirection = "sortDirection",
  user = "user",
  merchant = "merchant",
  merchantCategory = "merchantCategory",
  department = "department",
  parentExpenseIds = "parentExpenseIds",
  billedEntity = "billedEntity",
  spendingEntity = "spendingEntity",
  trip = "trip",
  brexTravelSpend = "brexTravelSpend",
  documentationDeadline = "documentationDeadline",
  expenseCategory = "expenseCategory",
  userStatus = "userStatus",
  userCategoryExtendedField = "userCategoryExtendedField",
  amountBetween = "amountBetween",
  minAmount = "minAmount",
  maxAmount = "maxAmount",
  card = "card",
  derivedComplianceStatus = "derivedComplianceStatus",
  vendor = "vendor",
  flagged = "flagged",
  costCenter = "costCenter",
  expensePolicyViolationReasons = "expensePolicyViolationReasons",
  approvers = "approvers",
  trackingStatus = "trackingStatus",
  disputeStatus = "disputeStatus",
  searchQuery = "searchQuery",
  expensePolicy = "expensePolicy",
  reimbursementPaymentMethod = "reimbursementPaymentMethod",
}

/**
 * Used in Cash Details Pane
 */
export type PaymentApprover = {
  /**
   * @param name
   * Name can refer to an individual approver or a group type (ex. admin)
   */
  name: string;
  actionDate?: string;
  status: "approved" | "denied" | "pending";
  /**
   * @param customLabel
   * Overrides the default label function
   */
  customLabel?: React.ReactNode;
};

export enum BudgetProgramsTableRowActions {
  pause = "pause",
  resume = "resume",
  viewOrEdit = "view or edit",
  delete = "delete",
}

// accounts view
export type CreditProduct = "CARD" | "EMPOWER";
export type ProductOffered = "CASH" | CreditProduct | "KYC" | "CARD_AND_CASH";

export enum IntegrationSubscriptionStates {
  TRIAL = "TRIAL",
  TRIALOPTIN = "TRIALOPTIN",
  EXPIRED = "EXPIRED",
  SUBSCRIBED = "SUBSCRIBED",
  RESTRICTED = "RESTRICTED",
  SUSPENDED = "SUSPENDED",
  CANCELED = "CANCELED",
  UNKNOWN = "UNKNOWN",
}

export enum IntegrationBankFeeds {
  quickbooks = "QuickBooks",
  expensify = "Expensify",
}

export enum IntegrationCategoryTypes {
  ALL = "all",
  BANK = "bank_account",
  ASSET = "asset",
  CREDIT_CARD = "credit_card",
  LIABILITY = "liability",
  REVENUE = "revenue",
  COST = "cost",
  EXPENSE = "expense",
  INCOME = "income",
  EQUITY = "equity",
  OTHER = "other",
}

/**
 * @deprecated Use the typename union instead
 */
export enum IntegrationEntityType {
  QuickbooksClass = "QuickbooksClass",
  QuickbooksLocation = "QuickbooksLocation",
  QuickbooksCustomer = "QuickbooksCustomer",
  NetsuiteClass = "NetsuiteClass",
  NetsuiteCustom = "NetsuiteCustom",
  NetsuiteLocation = "NetsuiteLocation",
  NetsuiteVendor = "NetsuiteVendor",
  NetsuiteDepartment = "NetsuiteDepartment",
  XeroTrackingCategory = "XeroTrackingCategory",
  IntacctDepartment = "IntacctDepartment",
  IntacctLocation = "IntacctLocation",
}

export enum IntegrationRuleTypes {
  CUSTOM = "custom",
  MANUAL_OVERRIDE = "manual_override",
  PERSONAL_EXPENSE = "personal_expense",
}

export type SelectIntegrationRuleType = IntegrationRule & {
  enabled: boolean;
  conflicting: boolean;
};

export enum AccountingAlertGroup {
  ACCOUNTING = "ACCOUNTING",
  COMPLIANCE = "COMPLIANCE",
  OTHER = "OTHER",
  TMC_CARD = "TMC_CARD",
}

export enum AccountingJournalEntryAttributeAlert {
  SENT_BACK_FOR_REVIEW = "SENT_BACK_FOR_REVIEW",
  RESUBMITTED = "RESUBMITTED",
  REVERTED = "REVERTED",
}

export enum AccountingJournalExpenseStatusAlert {
  APPROVED = "APPROVED",
}

export enum AccountingJournalExpenseComplianceStatusAlert {
  COMPLETED = "COMPLETED",
}

export type AccountingAlert =
  | AccountingJournalEntryAlert
  // TODO: FE should be refactored such that statuses are not considered "alerts"
  | AccountingJournalExpenseStatusAlert
  | AccountingJournalEntryAttributeAlert
  // TODO: FE should be refactored such that compliance statuses are not considered "alerts"
  | AccountingJournalExpenseComplianceStatusAlert;

export const AccountingAlertLabels: {
  [key in AccountingAlert]?: string;
} = {
  [AccountingJournalEntryAlert.CONFLICTING_RULES]: "Conflicting rules",
  [AccountingJournalEntryAlert.EMPLOYEE_MODIFIED]: "Employee modified",
  [AccountingJournalEntryAlert.MISSING_GL_ACCOUNT]: "Missing GL account",
  [AccountingJournalEntryAlert.MISSING_MEMO]: "Missing memo",
  [AccountingJournalEntryAlert.MISSING_RECEIPT]: "Missing receipt",
  [AccountingJournalEntryAlert.MISSING_REQUIRED_FIELDS]:
    "Missing required field(s)",
  [AccountingJournalEntryAlert.OTHER_REASON]: "Other reason",
  [AccountingJournalEntryAlert.PERSONAL_EXPENSE]: "Personal expense",
  [AccountingJournalEntryAlert.SPLIT_EXPENSE]: "Split expense",
  [AccountingJournalEntryAlert.NO_ALERT]: "No alert",
  [AccountingJournalEntryAlert.TMC_DATA_MISSING]: "Data unavailable",
  [AccountingJournalEntryAlert.TMC_DATA_PENDING]: "Data pending",
  [AccountingJournalEntryAlert.VAT_ELIGIBLE]: "Is VAT enabled",
  [AccountingJournalExpenseStatusAlert.APPROVED]: "Approved",
  [AccountingJournalExpenseComplianceStatusAlert.COMPLETED]:
    "Documentation completed",
  [AccountingJournalEntryAttributeAlert.RESUBMITTED]: "Resubmitted",
  [AccountingJournalEntryAttributeAlert.REVERTED]: "Previously exported",
  [AccountingJournalEntryAttributeAlert.SENT_BACK_FOR_REVIEW]:
    "Sent back for review",
};

// Deprecated: This 1:1 mapping is not up to date.
export const productBlueprintMap = {
  CARD: "product_v1_card",
  CARD_AND_CASH: "product_v1_card_and_cash",
  CASH: "product_v1_onboarding_cash",
  EMPOWER: "product_v1_empower_onboarding",
  KYC: "main_business_verification",
};

export enum BillSourceAccountType {
  DEPOSITS_ACCOUNT = "DEPOSITS_ACCOUNT",
  FUNDING_SOURCE = "FUNDING_SOURCE",
}

export type BillSourceAccount = {
  id: string;
  name: string;
  type: BillSourceAccountType;
  // Optional for deposits account type
  displayColor?: DepositsAccountsDisplayColor;
};

export type BillDetailsInvoiceType = {
  downloadUrl?: string | null;
  presignedDownloadUrl?: string | null;
  id?: string | null;
};

export type BillDetailsType = {
  id: string;
  invoice?: BillDetailsInvoiceType | undefined;
  externalInvoiceNumber?: string;
  description?: string;
  dueAt?: string;
  expense: {
    id: string;
  };
  servicePerformedAt?: string;
  isDraft?: boolean;
  merchantCategory?: {
    id?: string | null;
    name?: string | null;
  } | null;
  userErpCategory?: {
    id?: string | null;
    name?: string | null;
  } | null;
  originMetadata?: {
    fromEmailAddress?: string | null;
  } | null;
  sourceAccount?: BillSourceAccount | null;
  documents?: Document[];
};

export type CashTransactionDetailsSectionType = {
  method?: string;
  methodType?: CashTransactionType;
  paymentMethod?: CashTransactionPaymentMethod;
  achId?: string | null;
  routingNumber?: string | null;
  accountNumber?: string | null;
  accountType?: string | null;
  address?: string | null;
  description?: string | null;
  email?: string | null;
  country?: string | null;
  swiftCode?: string | null;
  iban?: string | null;
  recipientType?: string;
  transferDescription?: string | null;
  checkMemo?: string | null;
  checkNumber?: number | null;
  sentToLabel?: string;
  sentToName?: string | null;
  sentToAddress?: string | null;
  returnReason?:
    | CashTransactionChequeReturnReason
    | CashTransactionAchReturnReason
    | CashTransactionWireReturnReason
    | null;
  fedReferenceNumber?: string | null;
  imad?: string | null;
  omad?: string | null;
  mt103?: string | null;
  trackingNumber?: string | null;
  bankReferenceNumber?: string | null;
  invoiceNumber?: string | null;
};

export type CashTransactionExtendedStatus =
  | keyof typeof CashTransactionStatus
  | keyof typeof CashTransactionCancellationReason
  | "PAYROLL_ESTIMATE";

export enum CardNetwork {
  visa = "visa",
  mastercard = "mastercard",
}

export const MAX_NUMBER_DEPOSITS_ACCOUNTS_PER_CUSTOMER = 240;

export type IntegrationEntitiesSearchValue = {
  id: string;
  name?: string | null;
};

export enum MappingType {
  categories = "default_category",
  departments = "default_department",
  locations = "default_location",
  customRules = "custom",
  merchants = "default_merchant",
  vendors = "default_vendor",
  subsidiaries = "default_subsidiary",
}

export enum BudgetsTableOrigin {
  ALL_BUDGETS = "AllBudgets",
  YOUR_BUDGETS = "YourBudgets",
  YOUR_MANAGED_BUDGETS = "YourManagedBudgets",
  BUDGET_DETAILS = "BudgetDetails",
  CARDS_AND_LIMITS = "CardsAndLimits",
  LIMIT_GROUP_DETAILS = "LimitGroupDetails",
  EXPERIMENTAL = "Experimental",
}

export enum BudgetFormOrigin {
  BILL_PAY = "bill_pay",
  WALLET_CREATE_CARD = "wallet_create_card",
  CARDS_AND_LIMITS = "cards_and_limits",
}

export enum NavigationReferrer {
  CARDS_AND_LIMITS = "cards-and-limits",
  WALLET = "wallet",
  BUDGETS = "budgets",
}

export enum ConfigureBillingFlowOrigin {
  TEAMS_ENTITIES = "TEAMS_ENTITIES",
  ACCOUNTS_L2_TABLE = "ACCOUNTS_L2_TABLE",
  ACCOUNTS_L3 = "ACCOUNTS_L3",
  BILLED_ENTITIES_PANE = "BILLED_ENTITIES_PANE",
  ACCOUNTS_L3_PAYMENTS = "ACCOUNTS_L3_PAYMENTS",
  ACCOUNTS_L3_TRANSACTIONS = "ACCOUNTS_L3_TRANSACTIONS",
}

interface BudgetPermission {
  /** Whether the user should be able to perform this action */
  hasPermission: boolean;
  /** If disabled, a displayable reason for why this action is disabled */
  disabledReason?: string;
}

export interface BudgetPermissions {
  /** For the "Edit Budget" action */
  editBudget: BudgetPermission;
  editBudgetFull: BudgetPermission;
  /** Budgets V1: For hiding Edit Budget but allowing creating sub-budgets */
  editBudgetPartial: BudgetPermission;
  /** For the "Increase limit" action */
  increaseLimit: BudgetPermission;
  /** For the "Request limit increase" action */
  requestLimitIncrease: BudgetPermission;
  /** For the "Archive" action */
  archiveBudget: BudgetPermission;
}

export const BUDGET_PROPAGATION_LOCKED_ERROR_CODE =
  ErrorCode.FAILED_PRECONDITION;

export enum IntercompanyAccountType {
  PAYABLE = "payable",
  RECEIVABLE = "receivable",
}

export type UserCategory = {
  id: string;
  category: string | null;
  name: string | null;
  isDeleted: boolean | null;
  isDisabled: boolean | null;
  isInactive: boolean | null;
  parentUserCategoryId: string | null;
};

export type AccountCategories = {
  accountsPayableUserCategory?: UserCategory;
  awxWalletUserCategory?: UserCategory;
  collectionTargetUserCategory?: UserCategory;
  rewardsIncomeUserCategory?: UserCategory;
  reimbursementsUserCategory?: UserCategory;
  personalExpenseUserCategory?: UserCategory;
  reimbursementClearingUserCategory?: UserCategory;
};

export type ActionMetadata = {
  totalCount: number | null;
};

export type AuditTrail = {
  action: string;
  value: string | null;
  user: { fullName: string; persona: string | null };
  dateTime: string | null;
  type: BudgetAuditLogActionType | null;
  mechanism: string | null;
  actionMetadata: ActionMetadata | null;
}[];

export type BudgetPeriod = {
  periodType: BudgetPeriodType;
  startDate?: Date;
  endDate?: Date;
};

export enum AccountTypes {
  businessChecking = "Business Checking",
  businessSavings = "Business Savings",
  personalChecking = "Personal Checking",
  personalSavings = "Personal Savings",
}

export type ConflictingMerchantConfig = {
  controlReference: {
    merchantName: string;
  };
  controlType: MerchantCategoryControlType;
};

export type ConflictingCategoryConfig = {
  controlReference: {
    merchantCategoryName: string;
    merchantCategoryId: string;
  };
  controlType: MerchantCategoryControlType;
};

export type ConflictingMerchant = {
  id: string;
  name: string;
  budget: {
    id: string;
    name: string;
  };
  configs: Array<ConflictingMerchantConfig | ConflictingCategoryConfig>;
};

export type ConflictingCategory = {
  id: string;
  name: string;
  budget: {
    id: string;
    name: string;
  };
  configs: Array<ConflictingMerchantConfig | ConflictingCategoryConfig>;
};

export type MccConflicts = {
  merchants: ConflictingMerchant[];
  categories: ConflictingCategory[];
};

type FieldAnalyticsData = {
  id: string;
  type: string;
  name: string | null;
};

export type ERPExportAnalyticsParams = {
  integrationId: string;
  integrationFields: FieldAnalyticsData[];
  extendedFields?: FieldAnalyticsData[];
  customFields?: FieldAnalyticsData[];
};

export enum EmployeeOptionTypes {
  NESTED_REPORTS = "nested_reports",
  DIRECT_REPORTS = "direct_reports",
  INDIVIDUAL = "individual",
}

export type VatDecodedExtendedFieldOption = VatExtendedFieldOption & {
  taxCodeName?: string;
  taxCodeRate?: number;
};

export type VatDecodedExtendedField = Omit<VatExtendedField, "options"> & {
  options: VatDecodedExtendedFieldOption[];
};

export type VatDecodedExpenseExtendedFieldsData =
  | { extendedFields: VatDecodedExtendedField[] }
  | undefined;

export type AccountingFieldOptionType = "field" | "value";

export enum AccountsTimeSeriesPeriod {
  DAY = "day",
  MONTH = "month",
}

export enum ChartType {
  BAR = "BAR",
  LINE = "LINE",
  STACKED_BAR = "STACKED_BAR",
}

export type AccountsTimeSeriesPeriodInfo = {
  period: AccountsTimeSeriesPeriod;
  periodSize: number;
};

export type AccountsBarChartGroup = {
  groupLabel: string;
  amountInCents: number;
};

export type AccountsBarChartData = AccountsBarChartGroup[];

export enum DateTypeFilterInput {
  POSTED_DATE = "POSTED_DATE",
  PURCHASE_DATE = "PURCHASE_DATE",
  SUBMISSION_DATE = "SUBMISSION_DATE",
  DATE_ASSIGNED_TO_ME = "DATE_ASSIGNED_TO_ME",
  BUDGET_PERIOD = "BUDGET_PERIOD",
  TRAVEL_DATE = "TRAVEL_DATE",
}

export type ExpenseFundingEntityDetails = {
  fundingEntityId: string;
  fundingEntityName: string;
  entityBillingCode: string;
  isPushPayments: boolean;
  billingGroupTrackingId?: string;
};

export type FspClientConnection = Pick<
  GetFSPClients_getFSPClients,
  keyof GetFSPClients_getFSPClients
>;

export enum ClientConnectionsQueryParam {
  CLIENT_ID = "clientId",
}

export enum LegalEntityKYCStatus {
  UNVERIFIED,
  PENDING_EVIDENCE,
  /* If there is an app in progress, the application status response from API varies
  based on admin user.

  If the admin started the application:
  - While the application is in-process of being completed, the state is PENDING_EVIDENCE.
  - Once the application has been submitted, the state is PROCESSING (while background processes are being ran).

  If the admin did not start the application:
  - While the application is in-process of being completed, the state is PROCESSING.
  - Once the application has been submitted, the state is PROCESSING (while background processes are being ran).
  */
  PROCESSING_OTHER_ADMIN,
  PROCESSING,
  IN_REVIEW,
  REJECTED,
  VERIFIED,
}

export type BudgetForWallet = {
  id: string;
  name: string;
  status: BudgetStatus | SpendLimitStatus;
  expenseVisibilityShared: boolean;
  currentPeriodSpent: FinancialAsset;
  limitVisibleForUser: boolean;
  limitVisibility:
    | BudgetLimitVisibilityType
    | SpendLimitAuthorizationVisibility;
  spendableLimit: FinancialAsset | undefined;
  template: { id: string; name: string; type: BudgetTemplateType };
  cards: { id: string }[];
};

export type CardForWallet = {
  id: string;
  displayName: string;
  holderName: string;
  expiration: string;
  status: string;
  network: string;
  budget: BudgetForWallet;
  billingAddress: OfficeAddress;
  user: { id: string };
  statusView?: StatusView | null;
};

export type ConflictingExpenseExtendedFieldsValues = {
  field: {
    key: string;
    id: string;
    name: string;
    type: FormattedExpenseExtendedFieldValue["fieldType"];
  };
  values: (Pick<FormattedExpenseExtendedFieldValue, "value"> & {
    ruleId: string;
  })[];
};

export type AccountingIntegrationCategories = Omit<
  AccountingIntegrationSubsidiarySettings,
  "subsidiary" | "__typename" | "accruedLiabilityUserCategory" | "extension"
>;

/**
 * Explicitly define the return type to use it to dynamically
 * build the dropdowns in AccountingSetupV2ReviewGLDetails1BrexController.
 */
export type ERPSettingsKeys = {
  showAccountsPayableUserCategoryDropdown: boolean;
  showPersonalExpenseUserCategoryDropdown: boolean;
  showCollectionTargetUserCategoryDropdown: boolean;
  showReimbursementsUserCategoryDropdown: boolean;
  showReimbursementsClearingUserCategoryDropdown: boolean;
  showAwxWalletUserCategoryDropdown: boolean;
  showRewardsIncomeUserCategoryDropdown: boolean;
};

export const VerificationSubmittedResults = [
  VerificationAnalysisResult.APPROVED,
  VerificationAnalysisResult.REJECTED,
  VerificationAnalysisResult.PENDING_REVIEW,
];

/**
 * Requirement names for onboarding/KYC verifications
 */
export const VerificationRequirements = {
  MAIN_ENTITY_DETAILS: "kyc_main_entity_details",
  MAIN_ENTITY_OWNERSHIP: "kyc_main_entity_ownership",
  USER_MONEY_MOVEMENT_KYC: "kyc_user_transfer_write",
  CASH_UPSELL: "cash_upsell",
  OWNERSHIP_REFRESH: "bo_refresh",
  EBA_BILL_PAY_KYC: "kyc_entity_bill_pay",
  PRODUCT_ONBOARDING_KYC: "product_onboarding_kyc",
  KYC_COLUMN_SFBO: "kyc_column_sfbo",
  DELAYED_EIN_ONBOARDING: "delayed_ein_onboarding",
  LENDING_DISCLOSURE_CA: "lending_disclosure_ca",
  LENDING_DISCLOSURE_FL: "lending_disclosure_fl",
  LENDING_DISCLOSURE_GA: "lending_disclosure_ga",
  LENDING_DISCLOSURE_NY: "lending_disclosure_ny",
  PRODUCT_TERMS: "product_terms",
  PRODUCT_CASH: "product_cash",
  PRODUCT_CARD: "product_card",
} as const;

export enum StepStatusType {
  SUBMITTED = "submitted",
  PENDING = "pending",
  COMPLETED = "completed",
  DENIED = "denied",
  DISMISSED = "dismissed",
  CANCELED = "canceled",
  OVERRIDDEN = "overridden",
  REEVALUATED = "reevaluated",
}

export const YOU = "you";

export type Step = {
  status: StepStatusType;
  date?: Date;
  users?: string[];
  hasCurrentUserAsApprover?: boolean;
};

export type BudgetFormDateReference =
  | "PARENT_START_DATE"
  | "PARENT_END_DATE"
  | "ORIGINAL_START_DATE"
  | "SELECTED_START_DATE"
  | "SELECTED_END_DATE"
  | "TODAY";

export type BudgetFormStartDateDisabledReason =
  | "ALREADY_STARTED"
  | "WAS_CREATED_WITH_NULL_START_DATE";

export type SearchVariables = {
  searchQuery: string;
  handleSearchQueryChange: (event: { value: string }) => void;
  handleSearchQueryClear: VoidFunction;
  debouncedSearchQuery: string;
};

export type BudgetDropdownOption = Option<
  AdditionalOptionProps & {
    budgetName?: string;
    templateName?: string;
    description?: string;
  }
>;

export type DatagridExpenseBillDueAtDetails = {
  dueAt?: string;
  daysLate?: number;
  isLate?: boolean;
};

export type DatagridExpenseBillSourceDetails = {
  origin?: BillOrigin;
  forwardedFromEmailAddress?: string;
  createdByFullName?: string;
};

export type DatagridExpenseSelectedBudgetDetails = {
  userId: string;
  expenseUserId: string;
  employeeName: string;
  budget: {
    id: string;
    name: string;
    status: BudgetStatus;
    template: DatagridBudgetCellFragmentBudget["template"];
    budgetType: BudgetType;
    ancestryPath: DatagridBudgetCellFragmentBudget["ancestryPath"];
    memberEmployees: undefined;
    autoAssignedAndNeedsReview: boolean;
  } | null;
  amounts: ExpenseAmounts;
  expenseStatus: ExpenseStatus;
  expenseType: ExpenseType;
  purchasedAt: Date | undefined;
  paymentStatusType: ExpensePaymentStatusType | undefined;
  originator: DatagridBudgetCellFragmentOriginator | null;
};

export type DatagridExpenseOriginatorDetails = {
  cardLast4: string | null;
};

/** @note [Ramy Majouji, April 11 2024] Moved from deleted `BulkInvite` feature as it was only used in the adjacent analytics file */
export enum BulkStatementsDownloadPeriod {
  YTD = "ytd",
  PREVIOUS_YEAR = "previous_year",
  ALL = "all",
}

// TODO: to replace other instances of QueryHookOptionsWithRequiredVariables
export type QueryHookOptionsWithRequiredVariables<
  TData,
  TVariables extends OperationVariables,
> = SetRequired<
  Omit<QueryHookOptions<TData, TVariables>, "defaultOptions">,
  "variables"
>;

export type FinancialAssetGraphQL = {
  quantity: string;
  instrumentCodeString: string;
};

export enum BillCurrencyStrategy {
  NON_USD_BILL_PAID_LCW = "NON_USD_BILL_PAID_LCW",
  NON_USD_BILL_PAID_USD = "NON_USD_BILL_PAID_USD",
  USD_BILL_PAID_USD = "USD_BILL_PAID_USD",
  NON_USD_BILL_PAID_LOCAL_RAILS = "NON_USD_BILL_PAID_LOCAL_RAILS",
  UNKNOWN = "UNKNOWN",
}

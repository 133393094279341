/*
 * 🚨 Before changing this, ping the API and Product System teams first.
 * For more details, see https://docs.google.com/document/d/14cdxNTh-JLB4c1PWhRL92tQmPNxfxf2NaS9YeCKJxLE/edit#heading=h.hkx004tgnvjy
 * */
const authExpenseRequests = new Set([
  "resetpassword",
  "finishresetpasswordmutation",
  "validateactivation",
  "shouldseelegalagreements",
  "unauthenticateduserpasswordscreen",
  "activateuser",
  "activateuserv2",
  "vendorportal_vendordetailsquery",
  "updatevendorfrommagiclinkmutation",
]);

export const isOperationExemptFromAuthentication = (operation: {
  operationName?: string;
}) => {
  return authExpenseRequests.has(
    operation?.operationName?.toLowerCase?.()?.trim?.() || "",
  );
};

import type { AuthorizationRequestJson } from "@openid/appauth";
import {
  AuthorizationRequest,
  AuthorizationServiceConfiguration,
  FetchRequestor,
} from "@openid/appauth";
import { parse, stringify } from "qs";
import { trackPerformance } from "@/features/Analytics/helpers/performance";
import { generateRandomValue } from "@/features/Authentication/helpers/generateRandomValue";
import type {
  AuthOptions,
  EnvironmentConfig,
  OAuthConfig,
} from "@/features/Authentication/sharedTypes";
import { getEnvironment } from "@/helpers/environment";

const SERVICE_CONFIGURATION_REQUEST_TIMEOUT_MS = 10000;

const fetchRequestor = new FetchRequestor();

// cache service configs locally by `endpoint`
const serviceConfigs: {
  [endpoint: string]: AuthorizationServiceConfiguration;
} = {};

/**
 * This is only for testing purposes and should not be used in production
 */
export const clearServiceConfigCache = () => {
  for (const key in serviceConfigs) {
    delete serviceConfigs[key];
  }
};

export const getEnvironmentConfig = (): EnvironmentConfig => ({
  clientId: getEnvironment("AUTHZ_CLIENT_ID") as string,
  endpoint: getEnvironment("OKTA_AUTHZ_ENDPOINT") as string,
  interactiveRedirectUri: `${getEnvironment("REACT_APP_BASE_URL")}/auth.html`,
  invisibleRedirectUri: `${getEnvironment(
    "REACT_APP_BASE_URL",
  )}/auth-invisible.html`,
  postLogoutRedirectUri: `${getEnvironment("REACT_APP_BASE_URL")}/`,
  scope: getEnvironment("AUTHZ_SCOPE") as string,
});

type OAuthServiceConfigOptions = AuthOptions & {
  endpointOverride?: string;
};

/**
 * Fetch the OAuth service configuration from our OAuth provider, use a cached
 * configuration if available.
 *
 * We use the `authorizationEndpoint` and `endSessionEndpoint` values from
 * this configuration to log in and out of the Dashboard.
 */
export const getOAuthServiceConfig = async (
  options?: OAuthServiceConfigOptions,
) => {
  const { endpoint: environmentEndpoint } = getEnvironmentConfig();
  const endpoint = options?.endpointOverride || environmentEndpoint;
  if (!serviceConfigs[endpoint]) {
    serviceConfigs[endpoint] = await new Promise((resolve, reject) => {
      const timeout = setTimeout(() => {
        reject(new Error("OAuth Service Configuration timed out"));
      }, SERVICE_CONFIGURATION_REQUEST_TIMEOUT_MS);

      void AuthorizationServiceConfiguration.fetchFromIssuer(
        endpoint,
        fetchRequestor,
      )
        .then((configuration) => {
          resolve(configuration);
          clearTimeout(timeout);
        })
        .catch((error) => reject(error));
    });
  }
  return serviceConfigs[endpoint];
};

/**
 * Fetch the OAuth configuration from our OAuth provider
 */
export const getOAuthConfig = async (
  options?: AuthOptions,
): Promise<OAuthConfig> => {
  const { clientId, interactiveRedirectUri, invisibleRedirectUri, scope } =
    getEnvironmentConfig();

  let authorizationUrl: string;
  try {
    const { authorizationEndpoint } = await getOAuthServiceConfig();
    authorizationUrl = authorizationEndpoint;
  } catch (e) {
    throw new Error(`OAuth: service config: ${(e as Error).message}`);
  }

  const id = generateRandomValue();
  const nonce = generateRandomValue();

  const { prompt, redirectUri } = options?.interactive
    ? {
        prompt: options.prompt,
        redirectUri: interactiveRedirectUri,
      }
    : {
        prompt: "none",
        redirectUri: invisibleRedirectUri,
      };

  const params = parse(window.location.search, {
    ignoreQueryPrefix: true,
  });

  const authorizationRequest = new AuthorizationRequest({
    client_id: clientId,
    redirect_uri: redirectUri,
    response_type: AuthorizationRequest.RESPONSE_TYPE_CODE,
    scope,
    state: id,
    extras: {
      ...options?.extras,
      prompt: prompt ?? "",
      nonce,
      iss: params.iss,
      ...(options?.isReauthentication
        ? { acr_values: "urn:okta:loa:1fa:pwd" }
        : undefined),
    },
  });

  trackPerformance("auth.config.fetch");
  let authorizationRequestData: AuthorizationRequestJson;
  try {
    authorizationRequestData = await authorizationRequest.toJson();
  } catch (e) {
    throw new Error(`OAuth: authorization request: ${(e as Error).message}`);
  }

  trackPerformance("auth.config.response", "auth.config.fetch");

  const pkceVerifier = authorizationRequestData.internal?.code_verifier;
  const search = stringify(
    {
      client_id: authorizationRequestData.client_id,
      redirect_uri: authorizationRequestData.redirect_uri,
      response_type: authorizationRequestData.response_type,
      scope: authorizationRequestData.scope,
      state: authorizationRequestData.state,
      tenant_accounts: options?.tenantAccounts,
      // add login url for localhost env.
      login_url:
        (getEnvironment("APP_ENV") == "localhost" &&
          getEnvironment("AUTHZ_ENDPOINT")) ||
        null,
      ...authorizationRequestData.extras,
    },
    { skipNulls: true },
  );

  // PKCE is ignored if code_challenge generation fails
  if (!pkceVerifier) {
    throw new Error("OAuth: PKCE generation failed");
  }

  // this shouldn't happen, but we need to verify it exists in order to
  // validate the session later on
  if (!authorizationRequestData.state) {
    throw new Error("OAuth: State generation failed");
  }

  return {
    authorizationUrl: `${authorizationUrl}?${search}`,
    clientId: authorizationRequestData.client_id,
    id: authorizationRequestData.state,
    pkceVerifier,
    redirectUri: authorizationRequestData.redirect_uri,
    scope: authorizationRequestData.scope,
  };
};

import { SecondaryButton, StatusMessage } from "@brexhq/component-library";
import { useTranslation } from "@brexhq/i18n";
import * as React from "react";
import BrokenLightbulb from "@assets/illustrations/empower/spot/back-end-crash.png";
import InsufficientPermissionsIllustration from "@assets/illustrations/empower/spot/insufficient-permissions.png";
import { FullScreenPageContainer } from "@/components/FullScreenPageContainer";
import { ErrorType } from "@/data/sharedTypes";
import { useTrackEvent } from "@/features/Analytics/contexts/Analytics";
import { AllAnalyticsEvents } from "@/features/Analytics/sharedTypes";
import { UnauthorizedLayout } from "@/features/UnauthorizedLayout";
import { useTrackError } from "@/helpers/errorTracking";

const errorTypeToTitle = (errorType: ErrorType) => {
  switch (errorType) {
    case ErrorType.LOGIN_USER_DISABLED:
      return "user-disabled-title";
    case ErrorType.LOGIN_ACCOUNT_DISABLED:
      return "account-disabled-title";
    case ErrorType.ACTIVATE_USER_FAILURE:
    case ErrorType.LOGIN_FINISH_FAILURE:
    case ErrorType.LOGIN_FAILURE:
      return "login-error-title";
    default:
      return "default-error-title";
  }
};

const errorTypeToDescription = (errorType: ErrorType) => {
  switch (errorType) {
    case ErrorType.LOGIN_USER_DISABLED:
      return "user-disabled-desc";
    case ErrorType.LOGIN_ACCOUNT_DISABLED:
      return "account-disabled-desc";
    default:
      return "default-error-desc";
  }
};

const errorTypeToIllustration = (errorType: ErrorType) => {
  switch (errorType) {
    case ErrorType.LOGIN_USER_DISABLED:
      return InsufficientPermissionsIllustration;
    case ErrorType.LOGIN_ACCOUNT_DISABLED:
      return InsufficientPermissionsIllustration;
    default:
      return BrokenLightbulb;
  }
};

type LoginErrorProps = {
  errorType: ErrorType;
  errorMessage?: string;
  onClickRetry?: VoidFunction;
};

export const LoginError: React.VFC<LoginErrorProps> = ({
  errorType,
  errorMessage,
  onClickRetry,
}) => {
  const { t } = useTranslation();
  const { trackError } = useTrackError();
  const trackEvent = useTrackEvent();

  const actions = React.useMemo(() => {
    switch (errorType) {
      case ErrorType.LOGIN_USER_DISABLED:
      case ErrorType.LOGIN_ACCOUNT_DISABLED:
        return [
          {
            id: "logout",
            action: (
              <SecondaryButton
                onClick={() => {
                  trackEvent(AllAnalyticsEvents.LoginErrorLogout);
                  window.location.replace("/logout");
                }}
              >
                {t("sign-out")}
              </SecondaryButton>
            ),
          },
        ];
      default:
        return [
          {
            id: "retry",
            action: (
              <SecondaryButton
                onClick={() => {
                  trackEvent(AllAnalyticsEvents.LoginErrorRetry);
                  onClickRetry ? onClickRetry() : window.location.reload();
                }}
              >
                {t("try-again")}
              </SecondaryButton>
            ),
          },
          {
            id: "logout",
            action: (
              <SecondaryButton
                onClick={() => {
                  trackEvent(AllAnalyticsEvents.LoginErrorLogout);
                  window.location.replace("/logout");
                }}
              >
                {t("sign-in-again")}
              </SecondaryButton>
            ),
          },
        ];
    }
  }, [errorType, onClickRetry, t, trackEvent]);

  /**
   * Login errors are tracked separately from application errors. Many of these
   * errors are expected and should not count towards the overall error rate.
   */
  React.useEffect(() => {
    trackEvent(AllAnalyticsEvents.LoginError, { errorType, errorMessage });
    trackError(AllAnalyticsEvents.LoginError, new Error(errorMessage), {
      errorType,
      errorMessage,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <UnauthorizedLayout>
      <FullScreenPageContainer>
        <StatusMessage
          description={t(errorTypeToDescription(errorType))}
          graphic={errorTypeToIllustration(errorType)}
          title={t(errorTypeToTitle(errorType))}
          actions={actions}
        />
      </FullScreenPageContainer>
    </UnauthorizedLayout>
  );
};

interface JsonArray extends Array<JsonSerializable> {}

export type JsonMap = {
  [key: string]: JsonSerializable;
};

export type GuardedJsonMap<T extends JsonMap> = {
  [key in keyof T]: GuardedJsonSerializable<T[key]>;
};

export type GuardedJsonSerializable<T> = T extends boolean
  ? // a default value of an exact should be widened to `boolean`
    boolean
  : // common default number
    T extends 0
    ? number
    : // common default string
      T extends ""
      ? string
      : T extends number | string | null | JsonArray
        ? T
        : T extends JsonMap
          ? GuardedJsonMap<T>
          : never;

export type JsonSerializable =
  | boolean
  | number
  | string
  | null
  | JsonArray
  | JsonMap;

export const enum LocalPersistedStateKeys {
  EXPENSES_HIDE_DISMISS_CONFIRMATION_MODAL = "expenses_hide_dismiss_confirmation_modal",
  EXPENSES_HAS_SEEN_MANAGE_SETTINGS_ALERTS_SETUP_TOOLTIP = "expenses_has_seen_manage_settings_alerts_setup_tooltip",
  HAS_COMPLETED_MULTIPLAYER_INTRO_MODAL = "has_completed_multiplayer_intro_modal",
  PREAPPROVED_CARD_REQUEST_APPROVE_MODAL = "preapproved_card_request_approve_modal",
  PREAPPROVED_CARD_REQUEST_DENY_MODAL = "preapproved_card_request_deny_modal",
  REPORTS_INTEGRATION_UPSELL_DISMISSED = "reports_integration_upsell_dismissed",
  HAS_SEEN_CASH_WELCOME_MODAL = "has_seen_cash_welcome_modal",
  HAS_DARK_MODE_ENABLED = "HAS_DARK_MODE_ENABLED",
  BILLS_TAB_INTERACTED = "bills_tab_interacted",
  CAPITAL_INSTANT_PAYOUTS_TAB_INTERACTED = "instant_payouts_tab_interacted",
  CASH_INTEGRATIONS_ANNOUNCEMENT_INTERACTED = "integrations_all_announcement",
  CASH_VENDORS_TAB_INTERACTED = "vendors_tab_interacted",
  VENDOR_NAME = "vendor_name",
  SPEND_REQUESTS_TAB_INTERACTED = "requests_tab_interacted",
  RECONNECT_INTEGRATION_ID = "reconnectIntegrationId",
  PENDING_CREDENTIAL_ID = "pendingCredentialId",
  SUBSIDIARY_NAME = "subsidiaryName",
  ERP_SUBSIDIARIES = "erpSubsidiaries",
  ERP_INTEGRATION_TO_CONNECT = "erpIntegrationToConnect",
  SUBSIDIARY_VENDOR_INTERNAL_ID = "subsidiaryVendorInternalId",
  SUBSIDIARY_VENDOR_COUNTRY = "subsidiaryVendorCountry",
  QUICKBOOKS_BILL_SYNC_ENABLED = "quickbooksBillSyncEnabled",
  QUICKBOOKS_MIGRATION_PROMPT_TOKEN_INTEGRATIONS = "show_quickbooks_migration_prompt",
  QUICKBOOKS_MIGRATION_PROMPT_TOKEN_HOMEPAGE = "show_quickbooks_migration_prompt_homepage",
  CASH_TOUR_ID = "FTUE_CASH_TOUR_V1",
  BREX_30_TOUR_ID = "FTUE_BREX_30_V2",
  CASH_TRANSACTIONS_TOUR_ID = "CASH_TRANSACTIONS_TOUR_V1",
  CREDIT_UPSELL_FAILED_SSN_ATTEMPTS = "credit_upsell_failed_ssn_attempts",
  CREDIT_UPSELL_SSN_LOCKED_TIME = "credit_upsell_ssn_locked_time",
  PLAID_OAUTH = "PLAID_OAUTH",
  VENDOR_NOTIFICATIONS = "vendor_notifications",
  HOME_TASK_TILE_COMPLETED = "HOME_TASK_TILE_COMPLETED",
  HOME_HEADER_ANIMATION = "HOME_HEADER_ANIMATION",
  MOBILE_UPSELL_SEEN_COUNT = "MOBILE_UPSELL_SEEN_COUNT",
  YOUR_WALLET_CARD_INDEX = "YOUR_WALLET_CARD_INDEX",
  PRY_UPSELL_BANNER_REMINDER = "PRY_UPSELL_BANNER_REMINDER",
  PRODUCT_CONFIG_DEV_OVERRIDES = "PRODUCT_CONFIG_DEV_OVERRIDES",
  DISMISSED_TASKS = "DISMISSED_TASKS",
  DEV_ANALYTICS_LOGGING = "DEV_ANALYTICS_LOGGING",
  DEV_NAVIGATION_LOGGING = "DEV_NAVIGATION_LOGGING",
  DEV_FF_FAVORITES = "DEV_FF_FAVORITES",
  DEV_STEED_ROUTING_KEY = "DEV_STEED_ROUTING_KEY",
  STOP_ACCOUNT_SETUP_AUTO_OPEN = "STOP_ACCOUNT_SETUP_AUTO_OPEN",
  ACCOUNTING_AUTOMATION_IMPACTED_TRANSACTIONS_OPEN = "ACCOUNTING_AUTOMATION_IMPACTED_TRANSACTIONS_OPEN",
  ACCOUNTING_JOURNAL_TABLE_STATE = "ACCOUNTING_JOURNAL_TABLE_STATE",
  ACCOUNTING_JOURNAL_TABLE_STATE_V2 = "ACCOUNTING_JOURNAL_TABLE_STATE_V2",
  ACCOUNTING_JOURNAL_EXPORT_HISTORY_TABLE_STATE_V2 = "ACCOUNTING_JOURNAL_EXPORT_HISTORY_TABLE_STATE_V2",
  ACCOUNTING_ACKNOWLEDGED_SUGGESTION_EXPORT_RECORD_ID = "ACCOUNTING_ACKNOWLEDGED_SUGGESTION_EXPORT_RECORD_ID",
  ACCOUNTING_JOURNAL_DATAGRID_JOBS_ANIMATION_SEEN = "ACCOUNTING_JOURNAL_DATAGRID_JOBS_ANIMATION_SEEN",
  EMPOWER_LIGHT_ONBOARDING_TOUR_ID = "EMPOWER_LIGHT_ONBOARDING_TOUR_ID",
  BILL_PAY_BUDGET_TOUR_ID = "BILL_PAY_BUDGET_TOUR_ID",
  WALLET_V2_TOUR_ID = "WALLET_V2_TOUR_ID",
  ESSENTIALS_WALLET_TOUR_ID = "ESSENTIALS_WALLET_TOUR_ID",
  ESSENTIALS_BUDGETS_TOUR_ID = "ESSENTIALS_BUDGETS_TOUR_ID",
  POLICY_AUTOMEMO_UPSELL_BANNER_SEEN = "POLICY_AUTOMEMO_UPSELL_BANNER_SEEN",
  EXPENSES_FILTER_AUTOMEMO_UPSELL_BANNER_SEEN = "EXPENSES_FILTER_AUTOMEMO_UPSELL_BANNER_SEEN",
  EXPENSE_ATTENDEES_LAST_ENTERED = "EXPENSE_ATTENDEES_LAST_ENTERED",
  BUDGETS_TABLE_TOUR_ID = "BUDGETS_TABLE_TOUR_ID",
  BULK_BUDGET_MANAGEMENT_TOUR_ID = "BULK_BUDGET_MANAGEMENT_TOUR_ID",
  BUDGETS_DETAILS_V2_TOUR_ID = "BUDGETS_DETAILS_V2_TOUR_ID",
  BUDGET_TEMPLATES_TOUR_ID = "BUDGET_TEMPLATES_TOUR_ID",
  SHOW_TRIPS_AVAILABLE_SEEN = "SHOW_TRIPS_AVAILABLE_SEEN",
  UPLOAD_FROM_MOBILE_INTRO_SEEN = "UPLOAD_FROM_MOBILE_INTRO_SEEN",
  EXPORT_CREDIT_CARD_ID = "EXPORT_CREDIT_CARD_ID",
  UMA_ROLE_TOUR_ID = "UMA_ROLE_TOUR_ID",
  ACCOUNTING_STREAMLINED_RULES_TOUR_STEP = "ACCOUNTING_STREAMLINED_RULES_TOUR_STEP",
  LE_EXTERNAL_ACCOUNTS_NICKNAMES_TOUR_ID = "LE_EXTERNAL_ACCOUNTS_NICKNAMES_TOUR_ID",
  POST_INTEGRATION_FINISH_REDIRECT_URL = "POST_INTEGRATION_FINISH_REDIRECT_URL",
  TEAM_USERS_TABLE_STATE = "TEAM_USERS_TABLE_STATE",
  EXPENSE_DETAILS_RECEIPT_PANE_STATE = "EXPENSE_DETAILS_RECEIPT_PANE_STATE",
  SPEND_EXPENSE_DETAILS_PANE_V2_TOUR_SEEN = "SPEND_EXPENSE_DETAILS_PANE_V2_TOUR_SEEN",
  BUDGETS_V1_FTUX_DISPLAY_EDUCATION_DISMISSED_HOTSPOT = "BUDGETS_V1_FTUX_DISPLAY_EDUCATION_DISMISSED_HOTSPOT",
  PO_NUMBERS_FOR_DEMOING = "PO_NUMBERS_FOR_DEMOING",
  BILLING_FILTER_RECENTLY_SELECTED = "BILLING_FILTER_RECENTLY_SELECTED",
  SCOPED_SEARCH = "SCOPED_SEARCH",
  GLOBAL_SEARCH = "GLOBAL_SEARCH",
  SEND_EMAIL_EXPORT_NOTIFICATION = "SEND_EMAIL_EXPORT_NOTIFICATION",
  DATAGRID_SETTINGS = "DATAGRID_SETTINGS",
  DATAGRID_LAYOUT_STATE = "DATAGRID_LAYOUT_STATE",
  DATAGRID_COLUMN_SETTINGS = "DATAGRID_COLUMN_SETTINGS",
  DATAGRID_FILTERS = "DATAGRID_FILTERS",
  INCIDENT_BANNER = "INCIDENT_BANNER",
  SPEND_LIMIT_GROUP_ASYNC_NOTIFICATION_BANNER_STATE = "SPEND_LIMIT_GROUP_ASYNC_NOTIFICATION_BANNER_STATE",
  SAMPLE_TRACES = "SAMPLE_TRACES",

  /**
   * For testing purposes only and required for tests
   * DO NOT REMOVE
   */
  TEST = "TEST",
}

export type CacheKey = `__BREX__${LocalPersistedStateKeys}`;

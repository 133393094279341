import { Icon } from "@brexhq/metal-icon";
import { useInterval } from "@brexhq/metal-utils";
import styled from "@emotion/styled";
import * as React from "react";
import { useTrackEvent } from "@/domains/App";
import { AnalyticsEvents } from "@/domains/Travel/analytics";
import { useAda } from "@/features/Ada/hooks/useAda";
import { useIsAuthenticated } from "@/features/Authentication/hooks/useIsAuthenticated";
import { useChatBubble } from "@/features/ChatBubble/hooks/useChatBubble";
import { useFeatureFlags } from "@/features/LaunchDarkly/hooks/useFeatureFlags";
import { travelSupportClient } from "@/features/TravelSupport/TravelSupportController";

const ChatIcon = styled.div(({ theme }) => ({
  color: theme.colors.content.inverse.primary,
  // Vertically center icon with the first line of WarningLabel text.
  height: theme.typography.DetailLargeM.lineHeight,
  alignItems: "center",
  justifyContent: "center",
  display: "flex",
}));

const MessageIndicator = styled.div(({ theme }) => ({
  backgroundColor: theme.colors.accent.primary.background.default,
  height: 10,
  width: 10,
  position: "absolute",
  borderRadius: theme.borders.radius.pill,
  top: 16,
  right: 16,
  zIndex: theme.zIndex.sidepanelMask + 2,
}));

// This Bubble mimics the Ada floating button.
const Bubble = styled.div<{
  isDevButtonVisible?: boolean;
  isVisible?: boolean;
  isAdaVisible?: boolean;
}>(({ theme, isVisible, isAdaVisible }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "64px",
  width: "64px",
  position: "fixed",
  bottom: "24px",
  right: isAdaVisible ? "86px" : "24px",
  zIndex: theme.zIndex.sidepanelMask + 1,
  backgroundColor: "rgb(44, 44, 44)",
  borderRadius: "50%",
  cursor: "pointer",
  transition: "transform 0.2s ease-in-out",
  transform: `translateY(${isVisible ? 0 : 100}px)`,
  boxShadow: theme.shadows.floating,
  ":hover": {
    backgroundColor: "rgba(0, 0, 0, 0.8)",
  },
}));

const ADA_CHAT_POLLING_INTERVAL = 5000;

export const ChatBubble = () => {
  const { devPanelAccess, travelSpotnanaTwilioChatEnabled } = useFeatureFlags();
  const isAuthenticated = useIsAuthenticated();
  const [isAdaVisible, setIsAdaVisible] = React.useState(false);
  const { isAdaReady } = useAda();
  const { setChatBubble, isVisible, unreadCount } = useChatBubble();
  const trackEvent = useTrackEvent();

  const isUnread = unreadCount > 0;
  const shouldShowBubble = isVisible || isUnread;
  const shouldShowNewMessageIndicator = isUnread;
  const provider = travelSpotnanaTwilioChatEnabled ? "twilio" : "freshchat";

  /** This hack is needed because Ada can't provide any
   * callback to know if their bubble is visible or not.
   */
  useInterval(
    React.useCallback(() => {
      const adaButton = document.querySelector(
        "#ada-button-frame",
      ) as HTMLElement;

      setIsAdaVisible(!!adaButton);
    }, []),
    isAdaReady && shouldShowBubble ? ADA_CHAT_POLLING_INTERVAL : null,
  );

  const handleBubbleClick = React.useCallback(() => {
    trackEvent(AnalyticsEvents.TravelSupportChatBubbleClicked, {
      isUnread,
      provider,
    });

    setChatBubble({
      isVisible: false,
      unreadCount: 0,
    });

    void travelSupportClient.openChat();
  }, [isUnread, setChatBubble, trackEvent, provider]);

  if (!isAuthenticated) {
    return null;
  }

  return (
    <>
      <Bubble
        data-testid="chat-bubble"
        isDevButtonVisible={devPanelAccess}
        isVisible={shouldShowBubble}
        onClick={handleBubbleClick}
        isAdaVisible={isAdaVisible}
      >
        <ChatIcon>
          {shouldShowNewMessageIndicator ? <MessageIndicator /> : null}
          <Icon name="chatSupport" size={24} />
        </ChatIcon>
      </Bubble>
    </>
  );
};

import * as React from "react";
import { Route, Routes } from "react-router-dom";
import AuthenticatedRouterController from "@/domains/App/controllers/AuthenticatedRouterController";
import { useTrackLoadMountPerformance } from "@/features/Analytics/helpers/performance";
import { UnauthorizedLayoutLoading } from "@/features/UnauthorizedLayout";
import { lazyImportHelper } from "@/helpers/asyncRoutes";
import { urls } from "@/helpers/urls";

const importDevPanel = () => {
  if (process.env.APP_ENV !== "prod") {
    const {
      DevPanelController,
    } = require("@/features/DevPanel/DevPanelController");
    return <DevPanelController />;
  }
  return null;
};

const ExternalError = React.lazy(
  () =>
    import(
      /* webpackChunkName: "ExternalError" */
      "@/components/Error/ExternalError"
    ),
);
const LoginFinish = React.lazy(
  () =>
    import(
      /* webpackChunkName: "LoginFinish" */
      "@/domains/Trust/components/LoginFinish"
    ),
);
const LoginWithSessionController = React.lazy(
  () =>
    import(
      /* webpackChunkName: "LoginWithSessionController" */
      "@/domains/Trust/features/LoginWithSession/LoginWithSessionController"
    ),
);
const ForgotPasswordController = React.lazy(
  () =>
    import(
      /* webpackChunkName: "ForgotPasswordController" */
      "@/domains/Trust/features/ForgotPassword/ForgotPasswordController"
    ),
);
const SignupRedirectController = React.lazy(
  () =>
    import(
      /* webpackChunkName: "SignupRedirectController" */
      "@/domains/Onboarding/features/SignupRedirect/SignupRedirectController"
    ),
);
const SignupActivationController = React.lazy(
  () =>
    import(
      /* webpackChunkName: "SignupActivationController" */
      "@/domains/Onboarding/features/SignupActivation/SignupActivationController"
    ),
);
const MobileActivationDelegateController = React.lazy(
  () =>
    import(
      /* webpackChunkName: "MobileActivationDelegatePageController" */
      "@/features/ActivateUser/components/MobileActivationDelegatePage/MobileActivationDelegatePageController"
    ),
);
const MobileGoogleOAuthController = React.lazy(
  () =>
    import(
      /* webpackChunkName: "MobileGoogleOAuthController" */
      "@/domains/Primitives/features/GoogleCalendarIntegration/controllers/MobileGoogleOAuthController"
    ),
);
const SetPasswordPageController = React.lazy(
  () =>
    import(
      /* webpackChunkName: "SetPasswordPageController" */
      "@/features/ActivateUser/components/SetPasswordPage/SetPasswordPageController"
    ),
);
const ResetPasswordController = React.lazy(
  () =>
    import(
      /* webpackChunkName: "ResetPasswordController" */
      "@/domains/Trust/features/ResetPassword/ResetPasswordController"
    ),
);
const ChangeEmailController = React.lazy(
  () =>
    import(
      /* webpackChunkName: "ChangeEmailController" */
      "@/features/UpdateEmail/controllers/ChangeEmailController"
    ),
);
const LogoutController = React.lazy(
  () =>
    import(
      /* webpackChunkName: "LogoutController" */
      "@/domains/Trust/components/Logout"
    ),
);

const SwitchTenant = React.lazy(
  () =>
    import(
      /* webpackChunkName: "SwitchTenant" */
      "@/domains/Trust/controllers/SwitchTenantController"
    ),
);
const RejectedController = React.lazy(
  () =>
    import(
      /* webpackChunkName: "RejectedController" */
      "@/domains/Onboarding/components/AccountStates/RejectedV2"
    ),
);
const HelpshiftLiveChatWindow = React.lazy(() =>
  import(
    /* webpackChunkName: "HelpshiftLiveChatWindow" */
    "@/features/Helpshift/components/HelpshiftLiveChatWindow"
  ).then(({ HelpshiftLiveChatWindow: d }) => ({
    default: d,
  })),
);

const QuickBooksIntegrationDisconnected = React.lazy(() =>
  import(
    /* webpackChunkName: "QuickBooksIntegrationDisconnected" */
    "@/domains/Integrations/components/QuickBooksIntegrationDisconnected"
  ).then(({ QuickBooksIntegrationDisconnected: d }) => ({
    default: d,
  })),
);

const ReceiptsEmailFiltersController = React.lazy(
  () =>
    import(
      /* webpackChunkName: "ReceiptsEmailFiltersController" */
      "@/domains/Spend/features/ReceiptsEmailFilters/ReceiptsEmailFiltersController"
    ),
);

const VendorPortalController = lazyImportHelper(
  () =>
    import(
      /* webpackChunkName: "VendorPortalController" */
      "@/domains/Primitives/features/VendorPortal"
    ),
  "VendorPortalController",
);

const DismissFraudAlertPageController = React.lazy(
  () =>
    import(
      /* webpackChunkName: "DismissFraudAlertPageController" */
      "@/features/FraudAlerts/controllers/DismissFraudAlertPageController"
    ),
);
const SendVendorCardController = lazyImportHelper(
  () =>
    import(
      /* webpackChunkName: "SendVendorCardController" */
      "@/domains/Primitives/features/SendVendorCard"
    ),
  "SendVendorCardController",
);

export const Router: React.FC = React.memo(() => {
  useTrackLoadMountPerformance("app.main_router");
  return (
    <div className="app">
      <React.Suspense fallback={<UnauthorizedLayoutLoading />}>
        <Routes>
          <Route path={urls.error()} element={<ExternalError />} />
          <Route path={urls.auth.loginFinish()} element={<LoginFinish />} />
          <Route
            path={urls.auth.loginWithSession()}
            element={<LoginWithSessionController />}
          />
          <Route
            path={`${urls.auth.forgot()}/*`}
            element={<ForgotPasswordController />}
          />
          <Route
            path={urls.auth.signup()}
            element={<SignupRedirectController />}
          />
          <Route
            path={urls.auth.activate()}
            element={<SignupActivationController />}
          />
          <Route
            path={urls.users.activateMobile()}
            element={<MobileActivationDelegateController />}
          />
          <Route
            path={`${urls.oauth.google.mobile()}/*`}
            element={<MobileGoogleOAuthController />}
          />
          <Route
            path={`${urls.users.activate()}/*`}
            element={<SetPasswordPageController />}
          />
          <Route
            path={`${urls.users.passwordReset()}/*`}
            element={<ResetPasswordController />}
          />
          <Route
            path={`${urls.users.emailChange()}/*`}
            element={<ChangeEmailController />}
          />
          <Route
            path={`${urls.onboarding.rejected()}/*`}
            element={<RejectedController />}
          />
          <Route
            path={`${urls.auth.switchTenant()}/*`}
            element={<SwitchTenant />}
          />
          <Route path={urls.auth.logout()} element={<LogoutController />} />
          <Route
            path={urls.support.helpshiftLiveChatWindow()}
            element={<HelpshiftLiveChatWindow />}
          />
          <Route
            path={urls.integrations.qboDisconnected()}
            element={<QuickBooksIntegrationDisconnected />}
          />
          <Route
            path={urls.receipts.gmailFilter()}
            element={<ReceiptsEmailFiltersController />}
          />
          <Route
            path={urls.vendors.pay()}
            element={<VendorPortalController />}
          />
          <Route
            path={urls.fraudAlerts.dismissAlert()}
            element={<DismissFraudAlertPageController />}
          />
          <Route
            path={urls.vendors.bill_pay_card()}
            element={<SendVendorCardController />}
          />
          <Route
            path={urls.vendors.bill_pay_card_tmp()}
            element={<SendVendorCardController />}
          />
          <Route path="/*" element={<AuthenticatedRouterController />} />
        </Routes>
      </React.Suspense>
      {/*
          ⚠️ This here is an experimental and non-production controller. ⚠️

          It's used to implement tools/flows and helpers that can improve
          developer experience while working on the dashboard.

          Please be mindful when adding things here, as we want to keep it as
          minimal and non-disruptive as possible.

          Some examples of things added to it:
           - Turn on and off LogRocket redaction styling to ensure PII is not leaked
           -
      */}
      {importDevPanel()}
    </div>
  );
});

import { useTranslation } from "@brexhq/i18n";
import { useAtom } from "jotai";
import * as React from "react";
import { permissionsForDelegatorAtom } from "./atoms/permissionsAtom";
import { usePermissionsQuery } from "./data/queries";
import { FullScreenErrorPage } from "@/components/FullScreenErrorPage";
import { withControllerPerformanceMetrics } from "@/domains/App/components/Controller";
import { useCopilot } from "@/features/Copilot/hooks/useCopilot";
import {
  UnauthorizedLayout,
  UnauthorizedLayoutLoading,
} from "@/features/UnauthorizedLayout";

export const PermissionsController: React.FC = React.memo(({ children }) => {
  const { t } = useTranslation();
  const { getDelegatorUserId } = useCopilot();
  const isInCopilotMode = !!getDelegatorUserId();

  const [
    permissionsQueryDataForDelegator,
    setPermissionsQueryDataForDelegator,
  ] = useAtom(permissionsForDelegatorAtom);

  const { data, loading, error, refetch, refetching } = usePermissionsQuery({
    // We need to run the permissions query independently if we are in copilot mode
    // This would get the permissions for the delegator user
    skip: !isInCopilotMode,
  });

  const handleClickRetry = React.useCallback(() => {
    refetch();
  }, [refetch]);

  React.useEffect(() => {
    if (data) {
      setPermissionsQueryDataForDelegator(data);
    }
  }, [data, setPermissionsQueryDataForDelegator]);

  const isAtomUpdated = permissionsQueryDataForDelegator === data;

  if (isInCopilotMode) {
    if (error) {
      return (
        <UnauthorizedLayout>
          <FullScreenErrorPage
            errorMessage={t("permissions-query-failed")}
            onClickRetry={handleClickRetry}
          />
        </UnauthorizedLayout>
      );
    }

    if (loading || refetching || !isAtomUpdated) {
      return <UnauthorizedLayoutLoading />;
    }

    if (!data) {
      return (
        <UnauthorizedLayout>
          <FullScreenErrorPage
            errorMessage={t("permissions-query-returned-no-data")}
            onClickRetry={handleClickRetry}
          />
        </UnauthorizedLayout>
      );
    }
  }

  return <>{children}</>;
});

export default withControllerPerformanceMetrics(PermissionsController);

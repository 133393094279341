import type {
  RewardsIncentivePlacementLocation,
  RewardsRedemptionTile,
} from "./sharedTypes";

export enum RewardsAnalyticsEvents {
  RewardsOfferRedemptionLinkClicked = "rewards.offer_redemption_link_clicked",
  RewardsOfferClicked = "rewards.offer_clicked",
  RewardsRedemptionTileClicked = "rewards.redemption_tile_clicked",
  RewardsPointsHistoryClicked = "rewards.points.history_clicked",
  RewardsMilesHistoryClicked = "rewards.miles.history_clicked",
  RewardsMilesLoyaltyProgramClicked = "rewards.miles.loyalty_program_clicked",
  RewardsMilesTransferConfirmTransferConfirmClicked = "rewards.miles_transfer.confirm_transfer.confirm_clicked",
  RewardsCashRedemptionClicked = "rewards.cash_redemption.card_clicked",
  RewardsCashRedemptionRedeemClicked = "rewards.cash_redemption.redeem_clicked",
  RewardsCashRedemptionPointsAmountFocused = "rewards.cash_redemption.points_amount_focused",
  RewardsIncentivesShowBanner = "rewards.incentives.show_banner",
  RewardsIncentivesShowCashAddFundsFlowBanner = "rewards.incentives.show_cash_add_funds_flow_banner",
  RewardsIncentivesShowCashBalanceTooltip = "rewards.incentives.show_cash_balance_tooltip",
  RewardsIncentivesOptOutBanner = "rewards.incentives.opt_out_banner",
  RewardsIncentivesOptIn = "rewards.incentives.opt_in",
  RewardsOverviewCurrentPointsMounted = "rewards.overview.current_points.mounted",
  RewardsOverviewReferralInviteMounted = "rewards.overview.referral_invite.mounted",
  RewardsDonationBannerCTAClicked = "rewards.donation.banner.cta.clicked",
  RewardsDonationDonateAllClicked = "rewards.donation.donate_all.clicked",
  RewardsVASHighlightBannerCTAClicked = "rewards.vas_highlight.banner.cta.clicked",
  RewardsDonationError = "rewards.donation.do.error",
  RewardsBulkStatementCreditRedeemClicked = "rewards.bulk_statement_credit.redeem.clicked",
  RewardsBulkStatementCreditViewTransactionsClicked = "rewards.bulk_statement_credit.view_transactions.clicked",
  RewardsOffsitesGetStartedClicked = "rewards.offsites.get_started.clicked",
  RewardsOffsitesRequestPackageClicked = "rewards.offsites.request_package.clicked",
  RewardsMentalHealthCareGetStartedClicked = "rewards.mental_health_care.get_started.clicked",
  RewardsInkleGetStartedClicked = "rewards.inkle.get_started.clicked",
  RewardsMentalHealthCareRequestSessionClicked = "rewards.mental_health_care.request_session.clicked",
  RewardsExecutiveCoachingGetStartedClicked = "rewards.executive_coaching.get_started.clicked",
  RewardsExecutiveCoachingRequestSessionClicked = "rewards.executive_coaching.request_session.clicked",
  RewardsBillboardsGetStartedClicked = "rewards.billboards.get_started.clicked",
  RewardsBillboardsSelectPackageClicked = "rewards.billboards.select_package.clicked",
  RewardsBillboardsRequestPackageClicked = "rewards.billboards.request_package.clicked",
  RewardsBillboardsSelectPackageDurationClicked = "rewards.billboards.select_package_duration.clicked",
  RewardsBillboardsCloseClicked = "rewards.billboards.close.clicked",
  RewardsDelinquentAccountModalMounted = "rewards.delinquent.account.modal.mounted",
  SpendMigrationClicked = "rewards.points.spend_migration.clicked",
}

type RewardsOfferRedemptionLinkClickedSchema =
  | {
      id: string;
      partnerName: string;
      link: string;
    }
  | undefined;
type RewardsOfferClickedSchema = { id: string; partnerName: string };

type RewardsMilesLoyaltyProgramClickedSchema = {
  programName: string;
};

type RewardsMilesTransferConfirmTransferConfirmClickedSchema = {
  programName: string;
};

type RewardsRedemptionTileClickedSchema = {
  tile: RewardsRedemptionTile;
  url?: string;
};

type RewardsIncentivePlacementSchema = {
  placementId?: string;
};

type RewardsIncentivesOptInSchema = {
  placementLocation: RewardsIncentivePlacementLocation;
  placementId?: string;
};

type RewardsDonationAllSchema = {
  isChecked: boolean;
  donation: string;
};

type RewardsDonationSchema = {
  donation: string;
};

type RewardsDelinquentAccountModalMountedPayload = {
  redemptionOption?: string;
};

type RewardsVASHighlightSchema = {
  redemptionOption?: RewardsRedemptionTile;
};

export type RewardsEventSchemaMap = {
  [RewardsAnalyticsEvents.RewardsOfferRedemptionLinkClicked]: RewardsOfferRedemptionLinkClickedSchema;
  [RewardsAnalyticsEvents.RewardsOfferClicked]: RewardsOfferClickedSchema;
  [RewardsAnalyticsEvents.RewardsRedemptionTileClicked]: RewardsRedemptionTileClickedSchema;
  [RewardsAnalyticsEvents.RewardsPointsHistoryClicked]: {};
  [RewardsAnalyticsEvents.RewardsMilesHistoryClicked]: {};
  [RewardsAnalyticsEvents.RewardsMilesLoyaltyProgramClicked]: RewardsMilesLoyaltyProgramClickedSchema;
  [RewardsAnalyticsEvents.RewardsMilesTransferConfirmTransferConfirmClicked]: RewardsMilesTransferConfirmTransferConfirmClickedSchema;
  [RewardsAnalyticsEvents.RewardsCashRedemptionClicked]: {};
  [RewardsAnalyticsEvents.RewardsCashRedemptionRedeemClicked]: {};
  [RewardsAnalyticsEvents.RewardsCashRedemptionPointsAmountFocused]: {};
  [RewardsAnalyticsEvents.RewardsIncentivesShowBanner]: RewardsIncentivePlacementSchema;
  [RewardsAnalyticsEvents.RewardsIncentivesShowCashBalanceTooltip]: RewardsIncentivePlacementSchema;
  [RewardsAnalyticsEvents.RewardsIncentivesShowCashAddFundsFlowBanner]: RewardsIncentivePlacementSchema;
  [RewardsAnalyticsEvents.RewardsIncentivesOptIn]: RewardsIncentivesOptInSchema;
  [RewardsAnalyticsEvents.RewardsIncentivesOptOutBanner]: RewardsIncentivePlacementSchema;
  [RewardsAnalyticsEvents.RewardsOverviewCurrentPointsMounted]: {};
  [RewardsAnalyticsEvents.RewardsOverviewReferralInviteMounted]: {};
  [RewardsAnalyticsEvents.RewardsDonationBannerCTAClicked]: RewardsDonationSchema;
  [RewardsAnalyticsEvents.RewardsDonationDonateAllClicked]: RewardsDonationAllSchema;
  [RewardsAnalyticsEvents.RewardsVASHighlightBannerCTAClicked]: RewardsVASHighlightSchema;
  [RewardsAnalyticsEvents.RewardsDonationError]: RewardsDonationSchema;
  [RewardsAnalyticsEvents.RewardsBulkStatementCreditRedeemClicked]: {};
  [RewardsAnalyticsEvents.RewardsBulkStatementCreditViewTransactionsClicked]: {};
  [RewardsAnalyticsEvents.RewardsOffsitesGetStartedClicked]: {};
  [RewardsAnalyticsEvents.RewardsOffsitesRequestPackageClicked]: {};
  [RewardsAnalyticsEvents.RewardsExecutiveCoachingGetStartedClicked]: {};
  [RewardsAnalyticsEvents.RewardsMentalHealthCareGetStartedClicked]: {};
  [RewardsAnalyticsEvents.RewardsInkleGetStartedClicked]: {};
  [RewardsAnalyticsEvents.RewardsMentalHealthCareRequestSessionClicked]: {};
  [RewardsAnalyticsEvents.RewardsExecutiveCoachingRequestSessionClicked]: {};
  [RewardsAnalyticsEvents.RewardsBillboardsGetStartedClicked]: {};
  [RewardsAnalyticsEvents.RewardsBillboardsSelectPackageClicked]: {};
  [RewardsAnalyticsEvents.RewardsBillboardsSelectPackageDurationClicked]: {};
  [RewardsAnalyticsEvents.RewardsBillboardsRequestPackageClicked]: {};
  [RewardsAnalyticsEvents.RewardsBillboardsCloseClicked]: {};
  [RewardsAnalyticsEvents.RewardsDelinquentAccountModalMounted]: RewardsDelinquentAccountModalMountedPayload;
  [RewardsAnalyticsEvents.SpendMigrationClicked]: {};
};

import * as React from "react";
import { ErrorType } from "@/data/sharedTypes";
import { useTrackEvent } from "@/features/Analytics/contexts/Analytics";
import { AllAnalyticsEvents } from "@/features/Analytics/sharedTypes";
import { useBeginOpenIDAuthorization } from "@/features/Authentication/hooks/useBeginOpenIDAuthorization";
import { useIsAuthenticated } from "@/features/Authentication/hooks/useIsAuthenticated";

export const useAuthenticate = () => {
  const [authenticating, setAuthenticating] = React.useState(true);
  const [error, setError] = React.useState<[ErrorType, string?] | null>(null);
  const beginOpenIDAuthorization = useBeginOpenIDAuthorization();
  const isAuthenticated = useIsAuthenticated();
  const trackEvent = useTrackEvent();

  const authenticate = React.useCallback(async () => {
    // if a JWT access token already exists, we assume that there's a valid
    // session. if the access token is invalid, the next GraphQL request will
    // fail with a 401 and trigger a logout.
    // @see apolloLinkError for more details
    if (isAuthenticated) {
      setAuthenticating(false);
      return;
    }

    try {
      trackEvent(AllAnalyticsEvents.BeginOpenIDAuthorization);
      const response = await beginOpenIDAuthorization();
      if (response !== "redirect") {
        setAuthenticating(false);
      }
    } catch (e) {
      setError([ErrorType.LOGIN_FAILURE, (e as Error).message]);
      // this API has been supported everywhere since early 2022, the if is here mostly for tests
      if (typeof window.reportError === "function") {
        // report error for Sentry/LogRocket
        reportError(e);
      }
    }
  }, [beginOpenIDAuthorization, isAuthenticated, trackEvent]);

  React.useEffect(() => {
    authenticate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    authenticating,
    error,
  };
};

import type { HrSystem, UserRole } from "@/__generated__/globalTypes";

export enum AnalyticsEvents {
  TeamInviteUserMounted = "team.invite_user.mounted",
  TeamInviteUserStates = "team.invite_user.states",
  TeamInviteUserExpandedMoreRoles = "team.invite_user.expanded_roles",
  TeamInviteUserSubmitted = "team.invite_user.submitted",
  TeamInviteUserConfirmationPageClicked = "team.invite_user.confirmation_page.clicked",
  TeamInviteUserTooltipDisplayed = "team.invite_user.tooltip.displayed",
  TeamBulkInviteReviewClicked = "team.bulk_invite.review.clicked",
  TeamBulkInviteReviewBackClicked = "team.bulk_invite.review_back.clicked",
  TeamBulkInviteSubmitted = "team.bulk_invite.submitted",
  TeamUpdateUserRole = "team.user.update_role",
  TeamUpdateUserCardsInfoClicked = "team.user.update_cards_info.clicked",
  TeamUpdateUserCardsInfoMounted = "team.user.update_cards_info.mounted",
  TeamUpdateUserCardsInfoComplete = "team.user.update_cards_info.complete",
  TeamUsersTabNavigationClicked = "team.users.tab.clicked",
  TeamUpdateUserManager = "team.user.update_manager.complete",
  PersonaBasedUserRoleNamesLearned = "onboarding.persona_based_user_role_names_learned",
  EditMonthlyLimitClicked = "user.monthly_limit.edit.clicked",
  EditMonthlyLimitForm = "user.monthly_limit.edit.form",
  EditMonthlyLimitComplete = "user.monthly_limit.edit.complete",
  CreateUserLimitIncreaseClicked = "create.user_limit_increase.clicked",
  CreateUserLimitIncreaseForm = "create.user_limit_increase.form",
  CreateUserLimitIncreaseReview = "create.user_limit_increase.review",
  CreateUserLimitIncreaseComplete = "create.user_limit_increase.complete",
  AddPartnerLearnMoreClicked = "team.add_partner_learn_more.clicked",
  AddPartnerClicked = "team.add_partner_clicked",
  PartnerAccessGranted = "team.partner_access_granted",
  ConnectHris = "team.connect_hris",
  PrimaryAccountAdminOptInMounted = "team.primary_account_admin_opt_in.mounted",
  PrimaryAccountAdminOptInUpdated = "team.primary_account_admin_opt_in.updated",
}

export enum TeamInviteConfirmationPageActions {
  newInvite = "new_invite",
  orderCard = "order_card",
  done = "done",
}

export enum TeamInviteOriginatedFrom {
  teamUsers = "team_users",
  teamInviteUsers = "team_invite_users",
  budgets = "budgets",
  cards_and_limits = "cards_and_limits",
}

type EmptyPayload = {};

type UserIdPayload = {
  userId: string;
};

type TeamInviteUserMountedPayload = {
  originatedFrom: TeamInviteOriginatedFrom;
};

type TeamInviteUserStatesPayload = {
  step: string;
  isBifurcated?: boolean;
};

type TeamInviteUserSubmittedPayload = {
  role: string;
  hasSetLimit: boolean;
  hasSetLocation: boolean;
  hasSetDepartment: boolean;
  hasSetManager: boolean;
  allowCorporateCard: boolean;
};

type TeamInviteUserConfirmationClickedPayload = {
  action: TeamInviteConfirmationPageActions;
};

type TeamInviteUserTooltipDisplayedPayload = {
  role: string;
};

type TeamUpdateUserRolePayload = {
  role: UserRole;
};

type TeamBulkInviteSubmittedPayload = {
  count: number;
  locationsCount: number;
  departmentsCount: number;
};

type EditMonthlyLimitOrCreateUserLimitIncreaseClickedPayload = {
  userId: string;
  origin: "all-cards" | "users";
};

type EditCardsInfoClickedPayload = {
  userId: string;
  origin: "all-cards" | "users";
};

type EditMonthlyLimitCompletePayload = {
  userId: string;
  oldLimit?: number | null;
  newLimit?: number | null;
};

type CreateUserLimitIncreaseCompletePayload = {
  userId: string;
  oldLimit: number;
  newLimit: number;
  endsAt: string;
};

type EditCardsInfoCompletePayload = {
  userId: string;
  allowAccessCorporateCards: boolean;
  shouldIssueVirtualCard: boolean;
  oldLimit?: number | null;
  newLimit?: number | null;
};

type TeamUsersTabNavigationClickedPayload = {
  tabId: string;
};

type ConnectHrisPayload = {
  step: string;
  selectedProvider?: HrSystem;
  otherHrisResponse?: string;
};

type PrimaryAccountAdminOptInMountedPayload = {
  currentUserId: string;
  invitedUserId: string;
  invitedUserRole: string;
};

type PrimaryAccountAdminOptInUpdatedPayload = {
  currentUserId: string;
  invitedUserId: string;
  invitedUserRole: string;
  invitedUserSetAsPrimaryAdmin: boolean;
};

export type EventSchemaMap = {
  [AnalyticsEvents.TeamInviteUserMounted]: TeamInviteUserMountedPayload;
  [AnalyticsEvents.TeamInviteUserStates]: TeamInviteUserStatesPayload;
  [AnalyticsEvents.TeamInviteUserExpandedMoreRoles]: EmptyPayload;
  [AnalyticsEvents.TeamInviteUserSubmitted]: TeamInviteUserSubmittedPayload;
  [AnalyticsEvents.TeamInviteUserConfirmationPageClicked]: TeamInviteUserConfirmationClickedPayload;
  [AnalyticsEvents.TeamInviteUserTooltipDisplayed]: TeamInviteUserTooltipDisplayedPayload;
  [AnalyticsEvents.TeamBulkInviteReviewClicked]: EmptyPayload;
  [AnalyticsEvents.TeamBulkInviteReviewBackClicked]: EmptyPayload;
  [AnalyticsEvents.TeamBulkInviteSubmitted]: TeamBulkInviteSubmittedPayload;
  [AnalyticsEvents.TeamUpdateUserRole]: TeamUpdateUserRolePayload;
  [AnalyticsEvents.TeamUpdateUserCardsInfoClicked]: EditCardsInfoClickedPayload;
  [AnalyticsEvents.TeamUpdateUserCardsInfoMounted]: UserIdPayload;
  [AnalyticsEvents.TeamUpdateUserCardsInfoComplete]: EditCardsInfoCompletePayload;
  [AnalyticsEvents.TeamUsersTabNavigationClicked]: TeamUsersTabNavigationClickedPayload;
  [AnalyticsEvents.TeamUpdateUserManager]: EmptyPayload;
  [AnalyticsEvents.PersonaBasedUserRoleNamesLearned]: EmptyPayload;
  [AnalyticsEvents.EditMonthlyLimitClicked]: EditMonthlyLimitOrCreateUserLimitIncreaseClickedPayload;
  [AnalyticsEvents.EditMonthlyLimitForm]: UserIdPayload;
  [AnalyticsEvents.EditMonthlyLimitComplete]: EditMonthlyLimitCompletePayload;
  [AnalyticsEvents.CreateUserLimitIncreaseClicked]: EditMonthlyLimitOrCreateUserLimitIncreaseClickedPayload;
  [AnalyticsEvents.CreateUserLimitIncreaseForm]: UserIdPayload;
  [AnalyticsEvents.CreateUserLimitIncreaseReview]: UserIdPayload;
  [AnalyticsEvents.CreateUserLimitIncreaseComplete]: CreateUserLimitIncreaseCompletePayload;
  [AnalyticsEvents.AddPartnerLearnMoreClicked]: EmptyPayload;
  [AnalyticsEvents.AddPartnerClicked]: EmptyPayload;
  [AnalyticsEvents.PartnerAccessGranted]: EmptyPayload;
  [AnalyticsEvents.ConnectHris]: ConnectHrisPayload;
  [AnalyticsEvents.PrimaryAccountAdminOptInMounted]: PrimaryAccountAdminOptInMountedPayload;
  [AnalyticsEvents.PrimaryAccountAdminOptInUpdated]: PrimaryAccountAdminOptInUpdatedPayload;
};

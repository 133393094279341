import React from "react";
import { withControllerPerformanceMetrics } from "@/domains/App/components/Controller";
import { UnauthorizedLayoutLoading } from "@/features/UnauthorizedLayout";
import { useMockableProductConfig } from "@/hooks/useProductConfig";

type ProductConfigControllerProps = {
  children?: React.ReactNode;
};

const ProductConfigController = React.memo(
  ({ children }: ProductConfigControllerProps) => {
    const { loading, error } = useMockableProductConfig();
    if (loading) {
      return <UnauthorizedLayoutLoading />;
    }
    if (error) {
      throw new Error(`Product config didn't load - ${error.message}`);
    }
    return <>{children}</>;
  },
);

export const ProductConfigPreloader = withControllerPerformanceMetrics(
  ProductConfigController,
);

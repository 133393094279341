import type WDYR from "@welldone-software/why-did-you-render";
import Cookies from "js-cookie";
import type escapeRegExpType from "lodash/escapeRegExp";
import React from "react";
import { notEmpty } from "@/helpers/typeHelpers";

export const WDYR_COOKIE_KEY = "brex.wdyr";

export type WdyrState = {
  isEnabled: boolean;
  trackAllPureComponents: boolean;
  trackHooks: boolean;
  logOwnerReasons: boolean;
  logOnDifferentValues: boolean;
  include: string[] | undefined;
  exclude: string[] | undefined;
};

const defaultWdyrState: WdyrState = Object.freeze({
  isEnabled: false,
  trackAllPureComponents: false,
  trackHooks: true,
  logOwnerReasons: true,
  logOnDifferentValues: true,
  include: undefined,
  exclude: undefined,
});

export const getWdyrStateFromCookies = () => {
  try {
    const value = (Cookies.getJSON(WDYR_COOKIE_KEY) as unknown) ?? {};
    return { ...defaultWdyrState, ...value };
  } catch {
    return defaultWdyrState;
  }
};

export const persistWdyrState = (state: Partial<WdyrState>) => {
  Cookies.set(WDYR_COOKIE_KEY, { ...defaultWdyrState, ...state });
};

if (process.env.APP_ENV !== "prod") {
  // eslint-disable-next-line import/no-extraneous-dependencies -- not used in prod
  const escapeRegExp: typeof escapeRegExpType = require("lodash/escapeRegExp");
  const whyDidYouRender: typeof WDYR =
    // eslint-disable-next-line import/no-extraneous-dependencies -- not used in prod
    require("@welldone-software/why-did-you-render");

  function safeRegexParse(str: string) {
    if (!str?.trim()) {
      return undefined;
    }

    try {
      return new RegExp(str.trim());
    } catch {
      try {
        return new RegExp(escapeRegExp(str.trim()));
      } catch {
        return undefined;
      }
    }
  }

  try {
    const { isEnabled, include, exclude, ...wdyrOptions } =
      getWdyrStateFromCookies();

    if (isEnabled) {
      const options = {
        ...wdyrOptions,
        include: include?.map(safeRegexParse).filter(notEmpty),
        exclude: exclude?.map(safeRegexParse).filter(notEmpty),
      };

      // eslint-disable-next-line no-console
      console.debug("Enabling why-did-you-render with options:", options);

      whyDidYouRender(React, options);
    }
  } catch (error) {
    console.warn("Failed to enable why-did-you-render with error:", error);
  }
}

/**
 * Don't use this outside of the context of this file (or its tests)
 *
 * An array of tuples with feature flag name strings and their default values.
 * Feature flag name strings correspond to their key on LaunchDarkly.
 *
 * In order to access a feature flag value, you must add the feature flag name
 * and its default value to this tuple array.
 *
 * Adding a default value helps in providing an appropriate type for the feature
 * flag value as well as guards against an unexpected value when the LaunchDarkly
 * service is down.
 *
 * When a feature flag is archived, please remove its tuple from this array.
 */

export default [
  ["move-product-selection", false],
  ["hide-card-selection", false],
  ["ownership-last-4-ssn-enabled", false],
  ["remove-public-company-question", false],
  ["enable-lending-disclosure", false],
  ["update-applicant-details-collection", false],
  ["inline-persona-validation", false],
  ["bypass-persona", false],
  ["unified-application-route", false],
  ["product-apps-verification-migration", false],
  ["move-expected-spend", false],
] as const;

import * as React from "react";
import { Redirects } from "./components/Redirects";
import { DepositsAccountStatus } from "@/__generated__/globalTypes";
import { AccountStatus, UserRole } from "@/data/sharedTypes";
import { withControllerPerformanceMetrics } from "@/domains/App/components/Controller";
import { useSetInitialLanguageAuthenticated } from "@/domains/App/features/Localization/hooks/useSetInitialLanguage";
import { useAccountStates } from "@/domains/App/hooks/useAccountStates";
import { useGenerateSupportSessionCookie } from "@/features/Authentication/hooks/useSupportSessionCookie";
import { UnauthorizedLayoutLoading } from "@/features/UnauthorizedLayout";
import { useGetLendingDisclosureAnalysis } from "@/hooks/useGetLendingDisclosureAnalysis";
import { useHasEmailBeenVerified } from "@/hooks/useHasEmailBeenVerified";
import useQueryParams from "@/hooks/useQueryParams";
import { useBankingDemo } from "@/modules/Banking/hooks/useBankingDemo";
import { useSandbox } from "@/modules/Sandbox/hooks/useSandbox";
import { useOnboardingExperiencePermissions } from "@/routes/Onboarding/OnboardingExperience/hooks/useOnboardingExperiencePermissions";

export const AuthorizationController: React.FC = React.memo(({ children }) => {
  const { initialLanguageLoaded } = useSetInitialLanguageAuthenticated();
  const { enabled: bankingDemoEnabled } = useBankingDemo();
  const { isInSandboxMode } = useSandbox();

  const accountStates = useAccountStates();
  // Used for tracking user's traffic in Help Center. Needs to be in sync with the user session
  useGenerateSupportSessionCookie();
  const {
    mustSeeEmailVerificationScreen,
    loading: productApplicationPermissionsLoading,
  } = useHasEmailBeenVerified();
  const {
    shouldSeeOnboardingExperience,
    isLoading: onboardingExperienceLoading,
    hasError: onbardingExperienceError,
  } = useOnboardingExperiencePermissions();
  const { lendingDisclosureAnalysis } = useGetLendingDisclosureAnalysis(
    isInSandboxMode || accountStates.userRoles.userRole !== UserRole.ADMIN,
  );

  const { queryParams } = useQueryParams();

  if (
    accountStates.loading ||
    productApplicationPermissionsLoading ||
    onboardingExperienceLoading
  ) {
    return <UnauthorizedLayoutLoading />;
  }

  if (!accountStates.accountStates.cardAccountState) {
    throw new Error("Card account states has no data");
  }

  if (onbardingExperienceError) {
    throw new Error(`OnboardingExperience status failed`);
  }

  const canSeeDashboard = [
    AccountStatus.ACTIVE,
    AccountStatus.WAITING_APPROVAL,
    AccountStatus.WAITING_SUBMISSION,
    AccountStatus.CLOSED,
    AccountStatus.SUSPENDED,
  ].includes(accountStates.accountStates.cardAccountState);

  const canSeeCard = ![AccountStatus.CLOSED, AccountStatus.SUSPENDED].includes(
    accountStates.accountStates.cardAccountState,
  );

  const hasCashAccount =
    !!accountStates.accountStates.cashAccountState &&
    accountStates.accountStates.cashAccountState !==
      DepositsAccountStatus.RESTRICTED;

  const shouldSeeCashApplication = !hasCashAccount && !bankingDemoEnabled;

  const shouldSeeLendingDisclosure = !!lendingDisclosureAnalysis;

  // TODO: deprecate this when bank connection settings is fully rolled out, and backdoor link no longer receives visits
  const connectBankBackdoorEnabled =
    queryParams["should-connect-bank"] === "true";

  if (!initialLanguageLoaded) {
    return <UnauthorizedLayoutLoading />;
  }

  return (
    <Redirects
      mustSeeEmailVerificationScreen={mustSeeEmailVerificationScreen}
      shouldSeeCashApplication={shouldSeeCashApplication}
      shouldSeeAddBankConnectionModal={connectBankBackdoorEnabled}
      canSeeDashboard={canSeeDashboard}
      canSeeCard={canSeeCard}
      shouldSeeOnboardingExperience={shouldSeeOnboardingExperience}
      shouldSeeLendingDisclosure={shouldSeeLendingDisclosure}
    >
      {children}
    </Redirects>
  );
});

export const Authorization = withControllerPerformanceMetrics(
  AuthorizationController,
);

import type { RequiredFieldLogger } from "relay-runtime";
import type { JsonObject } from "type-fest";
import type { IncidentBannerObject } from "@/domains/App/sharedTypes";
import type { CardType } from "@/domains/Card/features/ManageCards/sharedTypes";

export enum AnalyticsEvents {
  TestEvent = "test.test_event.tested",
  NavigationExpSbContactRateSeen = "navigation.exp_sb_contact_rate.seen",
  NavigationItemClicked = "navigation-item-clicked",
  SideNavigationToggleClicked = "side_navigation_toggle.clicked",
  SideNavigationAccountMenuItemClicked = "side_navigation_account_menu_item.clicked",
  NavigationSwitchAccount = "navigation.switch_account",
  NavigationTasksLink = "navigation.tasks_link.seen",
  AppPageComponentMounted = "app.page_component.mounted",
  AnnouncementDismissed = "announcement.dismissed",
  AnnouncementShown = "announcement.shown",
  SettingsPersonalChangePhoneStart = "settings.personal.change_phone.start",
  SettingsPersonalChangePhoneSuccess = "settings.personal.change_phone.success",
  SettingsCompanyChangeAddressStart = "settings.company.change_address.start",
  SettingsCompanyChangeAddressSuccess = "settings.company.change_address.success",
  SettingsProductConfigurationsClicked = "settings.product_configurations.clicked",
  SettingsProductConfigurationsPageViewed = "settings.product_configurations.page.viewed",
  SettingsProductConfigurationsFeatureEnablementSectionViewed = "settings.product_configurations.feature_enablement_section.viewed",
  SettingsProductBillingPageViewed = "settings.product_billing.page.viewed",
  SettingsProductConfigurationsFeatureEnablementItemViewed = "settings.product_configurations.feature_enablement_item.viewed",
  SettingsProductConfigurationsFeatureEnablementItemClicked = "settings.product_configurations.feature_enablement_item.clicked",
  SettingsProductConfigurationsUpgradeSectionViewed = "settings.product_configurations.upgrade_section.viewed",
  SettingsProductConfigurationsUpgradeSectionClicked = "settings.product_configurations.upgrade_section.clicked",
  SettingsProductConfigurationsFeatureEnablementAnnouncementViewed = "settings.product_configurations.feature_enablement_announcement.viewed",
  SettingsProductConfigurationsFeatureEnablementAnnouncementClicked = "settings.product_configurations.feature_enablement_announcement.clicked",
  MultiStepModalStepViewed = "multi_step_modal.step.viewed",
  MultiStepModalPrimaryButtonClicked = "multi_step_modal.primary_button.clicked",
  MultiStepModalClosed = "multi_step_modal.closed",
  SaveInOnepasswordButtonEnabled = "app.save_in_onepassword_button.enabled",
  OnepasswordSaveCreditCardButtonClicked = "app.save_in_onepassword_button.clicked",
  NavBarMenuItemClicked = "app.nav_bar_menu_item.clicked",
  ErrorMounted = "error.mounted",
  DefaultErrorButtonClicked = "app.default_error_button.clicked",
  HasDarkModeEnabled = "app.dark_mode.has_enabled",
  SetDarkModeEnabled = "app.dark_mode.set_enabled",
  ControllerMounted = "app.controller.mounted",
  ControllerErrored = "app.controller.errored",
  ControllerWrappedComponentMissingDisplayName = "app.controller.wrapped_component_name.empty",
  SanctionsPossiblyImpactedBannerInteracted = "app.sanctions_possibly_impacted_banner.interacted",
  SanctionsPossiblyImpactedBannerDismissed = "app.sanctions_possibly_impacted_banner.dismissed",
  LaunchdarklyFeatureFlagEvaluated = "app.launchdarkly_feature_flag.evaluated",
  ProductRecommendationsCardViewed = "product_recommendations.card.viewed",
  ProductRecommendationsCardCtaClicked = "product_recommendations.card.cta_clicked",
  ProductRecommendationsCardRemindLater = "product_recommendations.card.remind_later",
  ProductRecommendationsCardClosed = "product_recommendations.card.closed",
  ProductRecommendationsPageViewed = "product_recommendations.page.viewed",
  ProductRecommendationsPageClosed = "product_recommendations.page.closed",
  ProductRecommendationsPageSectionViewed = "product_recommendations.page.section.viewed",
  ProductRecommendationsDismissed = "product_recommendations.dismissed",
  ProductRecommendationsPermanentlyDismissed = "product_recommendations.permanently_dismissed",
  ProductRecommendationsCompleted = "product_recommendations.completed",
  ProductTermsAndConditionsDropdownClicked = "product_terms_and_conditions.dropdown.clicked",
  ProductTermsAndConditionsLinkClicked = "product_terms_and_conditions.link.clicked",
  ProductTermsAndConditionsBannerDismissedClicked = "product_terms_and_conditions.banner.dismiss_clicked",
  EmpowerRecommendationPageBackToDashboardClicked = "empower_recommendation.page.back_to_dashboard.clicked",
  EmpowerRecommendationPageRequestDemoClicked = "empower_recommendation.page.request_demo.clicked",
  EmpowerRecommendationPageVideoWatched = "empower_recommendation.page.video.watched",
  WhatsappBannerInteracted = "app.whatsapp_opt_in_banner.interacted",
  WhatsappBannerDismissed = "app.whatsapp_opt_in_banner.dismissed",
  SingaporeBannerDismissed = "app.singapore_warning_banner.dismissed",
  NotificationCenterExperimentExposureBackend = "notification_center.experiment_exposure.backend",
  NotificationChannelSwitchInteracted = "app.notification_channel_switch.interacted",
  NotificationChannelSmsModalDismissed = "app.notification_channel_sms_modal.dismissed",
  SettingsNotificationPreferencesShown = "settings.notification_preferences.shown",
  SettingsNotificationPreferencesLeft = "settings.notification_preferences.left",
  SettingsNotificationPreferencesGroupDropdownClicked = "settings.notification_preferences.group_dropdown.clicked",
  SettingsNotificationPreferencesSwitchClicked = "settings.notification_preferences.switch.clicked",
  SettingsNotificationPreferencesCollapsibleDropdownClicked = "settings.notification_preferences.collapsible_dropdown.clicked",
  SettingsNotificationPreferencesCollapsibleTextChannelPreferencesDropdownClicked = "settings.notification_preferences.collapsible_text_channel_preferences_dropdown.clicked",
  HelpCenterAnonymousLogin = "app.help_center_anonymous_login",
  AppleStoreDownloadClicked = "app.apple_store_download.clicked",
  PlayStoreDownloadClicked = "app.play_store_download.clicked",
  CopilotNotificationRedirectToggleError = "copilot.notification_redirect.toggle.error",
  CopilotNotificationRedirectToggleSuccess = "copilot.notification_redirect.toggle.success",
  SettingsSetSelfServeEmailEnabled = "settings.self_serve.email.set_enabled",
  UpdateEmailMounted = "update.email.mounted",
  UpdateEmailVerifyMounted = "update.email.verify.mounted",
  UpdateEmailCancelMounted = "update.email.cancel.mounted",
  UpdateEmailCancelSubmitted = "update.email.cancel.submitted",
  IncidentBannerMounted = "incident_banner.mounted",
  IncidentBannerDismissed = "incident_banner.dismissed",
  IncidentBannerContactSupportClicked = "incident_banner.contact_support.clicked",
  IncidentBannerTextLinkClicked = "incident_banner.text_link.clicked",
  SpotlightStarted = "home.spotlight.started",
  SpotlightCompleted = "home.spotlight.completed",
  MissingRequiredField = "relay.missing_required_field",
  DuplicateDataId = "relay.duplicate_data_id",
}

export enum AnalyticsMetadata {
  TestMetadataA = "test.metadata.a",
  TestMetadataB = "test.metadata.b",
}

type EmptyPayload = {};

type NavigationItemClickedSchema = {
  item: string;
};

type SideNavigationToggleClickedPayload = {
  action: "expand" | "collapse";
};

type AnnouncementPayload = {
  contentId: string;
};

type ControllerMountedPayload = {
  controllerName: string;
};

type ControllerErroredPayload = {
  controllerName: string;
};

type SettingsPersonalChangePhoneStartPayload = {
  hasSMSMfa: boolean;
};

type SettingsPersonalChangePhoneSuccessPayload = {
  hasSMSMfa: boolean;
};

type SettingsCompanyChangeAddressSuccessPayload = {
  updateExistingCardAddresses: boolean;
};

type OnepasswordSaveCreditCardButtonClickedPayload = {
  cardType: CardType;
};

type ControllerWrappedComponentMissingDisplayNamePayload = {
  error: Error;
};

export type NavBarMenuItemClickedPayload = {
  id: string;
};

type SettingsNotificationPreferencesSwitchClickedPayload = {
  groupName: string;
  channelType: string;
  subscribed: boolean;
};

type SettingsNotificationPreferencesGroupDropdownClickedPayload = {
  isOpen: boolean;
};

type SettingsNotificationPreferencesShownPayload = {
  pageVersion: number;
  origin?: string;
};

type SettingsNotificationPreferencesLeftPayload = {
  pageVersion: number;
};

type SettingsNotificationPreferencesCollapsibleDropdownClickedPayload = {
  isOpen: boolean;
  categoryName: string;
};

type SettingsNotificationPreferencesCollapsibleTextChannelPreferencesDropdownClickedPayload =
  {
    isOpen: boolean;
  };

type SettingsProductConfigurationsClickedPayload = {
  origin:
    | "account_menu"
    | "settings_tab"
    | "travel_announcement"
    | "bill_pay_announcement";
};

export type SettingsProductConfigurationsFeatureEnablementItemViewedPayload = {
  type: "travel" | "billPay" | "reimbursements" | "customRules";
  isEnabled?: boolean;
};

export type SettingsProductConfigurationsFeatureEnablementItemClickedPayload = {
  type:
    | "travel"
    | "billPay"
    | "brex_business_account"
    | "reimbursements"
    | "customRules";
  action:
    | "learn_more"
    | "enable_travel"
    | "upsell_cash"
    | "enable_bill_pay"
    | "enable_reimbursements"
    | "enable_custom_rules"
    | "disable_custom_rules";
  actionHandler:
    | "link_to_chili_piper_fallback"
    | "link_to_chili_piper_rep"
    | "open_self_serve_modal"
    | "open_learn_more"
    | "open_modal_setup_reimbursements"
    | "open_modal_setup_custom_rules"
    | "disable_custom_rules";
  origin: "settings_tab" | "learn_more_side_panel";
};

type SettingsProductConfigurationsUpgradeSectionClickedPayload = {
  action:
    | "learn_more_global"
    | "learn_more_travel"
    | "learn_more_bill_pay"
    | "learn_more_budget"
    | "schedule_a_call";
  actionHandler:
    | "link_to_chili_piper_rep"
    | "link_to_chili_piper_fallback"
    | "open_learn_more";
  origin: "settings_tab" | "learn_more_side_panel";
};

type SettingsProductConfigurationsFeatureEnablementAnnouncementViewedPayload = {
  type: "travel" | "billPay";
};

type SettingsProductConfigurationsFeatureEnablementAnnouncementClickedPayload =
  {
    type: "travel" | "billPay";
    action: "enable_travel" | "close" | "routed_to_bills";
  };

type SpotlightPayload = {
  spotlightContentId: string;
};

type MultiStepModalPayload = {
  flowType: string;
  step: string;
};

type NavigationExpSbContactRatePayload = { treatment: boolean };

type ProductRecommendationsPayload = {
  recommendationName: string;
};

type ProductRecommendationsCardViewedPayload = ProductRecommendationsPayload & {
  totalRecommendationsCount: number;
};

type ProductRecommendationsPageSectionViewedPayload =
  ProductRecommendationsPayload & {
    sectionName: string;
  };

type ProductTermsAndConditionsDropdownClickedPayload = {
  isOpen: boolean;
};

type ProductTermsAndConditionsLinkClickedPayload = {
  href: string;
};

type EmpowerRecommendationPageVideoWatchedPayload = {
  currentTime: number;
  totalDuration: number;
};

type LaunchdarklyFeatureFlagEvaluatedPayload = {
  launchDarklyEventName: string;
  launchDarklyEventValues: JsonObject;
};

type NotificationCenterExperimentExposureBackendPayload = {
  result: boolean;
};

type NotificationChannelSwitchInteractedPayload = {
  channel: "whatsapp" | "sms";
  enabled: boolean;
};

type SetDarkModeEnabledPayload = {
  value: boolean;
};

export type MobileDownloadButtonOrigin =
  | "mobile-activation"
  | "onboarding"
  | "spotnana";

type MobileDownloadButtonClickedPayload = {
  origin: MobileDownloadButtonOrigin;
};

type DefaultErrorPayload = {
  title: string | undefined;
  description: string | undefined;
};

type CopilotNotificationRedirectToggleErrorPayload = {
  msg: string;
};

type CopilotNotificationRedirectToggleSuccessPayload = {
  newState: string;
};

type NavigationTasksLinkPayload = {
  canSeeAccountNameInNav: boolean;
  canSeeWalletNavItem: boolean;
  canSeeInboxNavItem: boolean;
  canSeeTripsNavItem: boolean;
  canSeeAccounts: boolean;
  canSeeGroupEventsNavItem: boolean;
  canSeeCompanyTravelNavItem: boolean;
  canSeeCardsAndSpendLimitsNavItem: boolean;
  canSeeBudgetsNavItem: boolean;
  canSeeExpensesNavItem: boolean;
  canSeeBillPayNavItem: boolean;
  canSeeVendorNavItem: boolean;
  canSeeReportingNavItem: boolean;
  canSeeClientsNavItem: boolean;
  canSeeTeamNavItem: boolean;
  canSeeAccountingNavItem: boolean;
  canSeeRewards: boolean;
  canSeeSecurityNavItem: boolean;
};

type SettingsSetSelfServeEmailEnabledPayload = {
  value: boolean;
};

type UpdateEmailFlowPayload = {
  self: boolean;
};

type IncidentBannerPayload = IncidentBannerObject;

type DuplicateDataIdPayload = {
  typeNames: string[];
};

export type EventSchemaMap = {
  [AnalyticsEvents.TestEvent]: any;
  [AnalyticsEvents.NavigationExpSbContactRateSeen]: NavigationExpSbContactRatePayload;
  [AnalyticsEvents.NavigationItemClicked]: NavigationItemClickedSchema;
  [AnalyticsEvents.SideNavigationAccountMenuItemClicked]: NavigationItemClickedSchema;
  [AnalyticsEvents.SideNavigationToggleClicked]: SideNavigationToggleClickedPayload;
  [AnalyticsEvents.NavigationSwitchAccount]: EmptyPayload;
  [AnalyticsEvents.NavigationTasksLink]: NavigationTasksLinkPayload;
  [AnalyticsEvents.AnnouncementDismissed]: AnnouncementPayload;
  [AnalyticsEvents.ControllerMounted]: ControllerMountedPayload;
  [AnalyticsEvents.ControllerErrored]: ControllerErroredPayload;
  [AnalyticsEvents.AnnouncementShown]: AnnouncementPayload;
  [AnalyticsEvents.ControllerWrappedComponentMissingDisplayName]: ControllerWrappedComponentMissingDisplayNamePayload;
  [AnalyticsEvents.SettingsPersonalChangePhoneStart]: SettingsPersonalChangePhoneStartPayload;
  [AnalyticsEvents.SettingsPersonalChangePhoneSuccess]: SettingsPersonalChangePhoneSuccessPayload;
  [AnalyticsEvents.SettingsCompanyChangeAddressStart]: EmptyPayload;
  [AnalyticsEvents.SettingsCompanyChangeAddressSuccess]: SettingsCompanyChangeAddressSuccessPayload;
  [AnalyticsEvents.SettingsProductConfigurationsClicked]: SettingsProductConfigurationsClickedPayload;
  [AnalyticsEvents.SettingsProductConfigurationsPageViewed]: EmptyPayload;
  [AnalyticsEvents.SettingsProductConfigurationsFeatureEnablementSectionViewed]: EmptyPayload;
  [AnalyticsEvents.SettingsProductBillingPageViewed]: EmptyPayload;
  [AnalyticsEvents.SettingsProductConfigurationsFeatureEnablementItemViewed]: SettingsProductConfigurationsFeatureEnablementItemViewedPayload;
  [AnalyticsEvents.SettingsProductConfigurationsFeatureEnablementItemClicked]: SettingsProductConfigurationsFeatureEnablementItemClickedPayload;
  [AnalyticsEvents.SettingsProductConfigurationsUpgradeSectionViewed]: EmptyPayload;
  [AnalyticsEvents.SettingsProductConfigurationsUpgradeSectionClicked]: SettingsProductConfigurationsUpgradeSectionClickedPayload;
  [AnalyticsEvents.SettingsProductConfigurationsFeatureEnablementAnnouncementViewed]: SettingsProductConfigurationsFeatureEnablementAnnouncementViewedPayload;
  [AnalyticsEvents.SettingsProductConfigurationsFeatureEnablementAnnouncementClicked]: SettingsProductConfigurationsFeatureEnablementAnnouncementClickedPayload;
  [AnalyticsEvents.MultiStepModalStepViewed]: MultiStepModalPayload;
  [AnalyticsEvents.MultiStepModalPrimaryButtonClicked]: MultiStepModalPayload;
  [AnalyticsEvents.MultiStepModalClosed]: MultiStepModalPayload;
  [AnalyticsEvents.SaveInOnepasswordButtonEnabled]: EmptyPayload;
  [AnalyticsEvents.OnepasswordSaveCreditCardButtonClicked]: OnepasswordSaveCreditCardButtonClickedPayload;
  [AnalyticsEvents.NavBarMenuItemClicked]: NavBarMenuItemClickedPayload;
  [AnalyticsEvents.ErrorMounted]: DefaultErrorPayload;
  [AnalyticsEvents.HasDarkModeEnabled]: EmptyPayload;
  [AnalyticsEvents.SetDarkModeEnabled]: SetDarkModeEnabledPayload;
  [AnalyticsEvents.SanctionsPossiblyImpactedBannerInteracted]: EmptyPayload;
  [AnalyticsEvents.SanctionsPossiblyImpactedBannerDismissed]: EmptyPayload;
  [AnalyticsEvents.LaunchdarklyFeatureFlagEvaluated]: LaunchdarklyFeatureFlagEvaluatedPayload;
  [AnalyticsEvents.ProductRecommendationsCardViewed]: ProductRecommendationsCardViewedPayload;
  [AnalyticsEvents.ProductRecommendationsCardCtaClicked]: ProductRecommendationsPayload;
  [AnalyticsEvents.ProductRecommendationsCardRemindLater]: ProductRecommendationsPayload;
  [AnalyticsEvents.ProductRecommendationsCardClosed]: ProductRecommendationsPayload;
  [AnalyticsEvents.ProductRecommendationsPageViewed]: ProductRecommendationsPayload;
  [AnalyticsEvents.ProductRecommendationsPageClosed]: ProductRecommendationsPayload;
  [AnalyticsEvents.ProductRecommendationsPageSectionViewed]: ProductRecommendationsPageSectionViewedPayload;
  [AnalyticsEvents.ProductRecommendationsDismissed]: ProductRecommendationsPayload;
  [AnalyticsEvents.ProductRecommendationsPermanentlyDismissed]: ProductRecommendationsPayload;
  [AnalyticsEvents.ProductRecommendationsCompleted]: ProductRecommendationsPayload;
  [AnalyticsEvents.ProductTermsAndConditionsDropdownClicked]: ProductTermsAndConditionsDropdownClickedPayload;
  [AnalyticsEvents.ProductTermsAndConditionsLinkClicked]: ProductTermsAndConditionsLinkClickedPayload;
  [AnalyticsEvents.ProductTermsAndConditionsBannerDismissedClicked]: EmptyPayload;
  [AnalyticsEvents.EmpowerRecommendationPageBackToDashboardClicked]: EmptyPayload;
  [AnalyticsEvents.EmpowerRecommendationPageRequestDemoClicked]: EmptyPayload;
  [AnalyticsEvents.EmpowerRecommendationPageVideoWatched]: EmpowerRecommendationPageVideoWatchedPayload;
  [AnalyticsEvents.WhatsappBannerInteracted]: EmptyPayload;
  [AnalyticsEvents.NotificationCenterExperimentExposureBackend]: NotificationCenterExperimentExposureBackendPayload;
  [AnalyticsEvents.NotificationChannelSwitchInteracted]: NotificationChannelSwitchInteractedPayload;
  [AnalyticsEvents.WhatsappBannerDismissed]: EmptyPayload;
  [AnalyticsEvents.SingaporeBannerDismissed]: EmptyPayload;
  [AnalyticsEvents.NotificationChannelSmsModalDismissed]: EmptyPayload;
  [AnalyticsEvents.SettingsNotificationPreferencesShown]: SettingsNotificationPreferencesShownPayload;
  [AnalyticsEvents.SettingsNotificationPreferencesLeft]: SettingsNotificationPreferencesLeftPayload;
  [AnalyticsEvents.SettingsNotificationPreferencesGroupDropdownClicked]: SettingsNotificationPreferencesGroupDropdownClickedPayload;
  [AnalyticsEvents.SettingsNotificationPreferencesSwitchClicked]: SettingsNotificationPreferencesSwitchClickedPayload;
  [AnalyticsEvents.SettingsNotificationPreferencesCollapsibleDropdownClicked]: SettingsNotificationPreferencesCollapsibleDropdownClickedPayload;
  [AnalyticsEvents.SettingsNotificationPreferencesCollapsibleTextChannelPreferencesDropdownClicked]: SettingsNotificationPreferencesCollapsibleTextChannelPreferencesDropdownClickedPayload;
  [AnalyticsEvents.HelpCenterAnonymousLogin]: EmptyPayload;
  [AnalyticsEvents.AppleStoreDownloadClicked]: MobileDownloadButtonClickedPayload;
  [AnalyticsEvents.PlayStoreDownloadClicked]: MobileDownloadButtonClickedPayload;
  [AnalyticsEvents.AppPageComponentMounted]: EmptyPayload;
  [AnalyticsEvents.DefaultErrorButtonClicked]: DefaultErrorPayload;
  [AnalyticsEvents.CopilotNotificationRedirectToggleError]: CopilotNotificationRedirectToggleErrorPayload;
  [AnalyticsEvents.CopilotNotificationRedirectToggleSuccess]: CopilotNotificationRedirectToggleSuccessPayload;
  [AnalyticsEvents.SettingsSetSelfServeEmailEnabled]: SettingsSetSelfServeEmailEnabledPayload;
  [AnalyticsEvents.UpdateEmailMounted]: UpdateEmailFlowPayload;
  [AnalyticsEvents.UpdateEmailVerifyMounted]: UpdateEmailFlowPayload;
  [AnalyticsEvents.UpdateEmailCancelMounted]: UpdateEmailFlowPayload;
  [AnalyticsEvents.UpdateEmailCancelSubmitted]: EmptyPayload;
  [AnalyticsEvents.IncidentBannerMounted]: IncidentBannerPayload;
  [AnalyticsEvents.IncidentBannerDismissed]: IncidentBannerPayload;
  [AnalyticsEvents.IncidentBannerContactSupportClicked]: IncidentBannerPayload;
  [AnalyticsEvents.IncidentBannerTextLinkClicked]: IncidentBannerPayload;
  [AnalyticsEvents.SpotlightStarted]: SpotlightPayload;
  [AnalyticsEvents.SpotlightCompleted]: SpotlightPayload;
  [AnalyticsEvents.MissingRequiredField]: Parameters<RequiredFieldLogger>[0];
  [AnalyticsEvents.DuplicateDataId]: DuplicateDataIdPayload;
};

export type MetadataSchemaMap = {
  [AnalyticsMetadata.TestMetadataA]: any;
  [AnalyticsMetadata.TestMetadataB]: any;
};

import { JotaiMockProvider } from "@brexhq/jotai-utils/testing";
import * as React from "react";
import { DepositsRole } from "@/__generated__/globalTypes";
import { UserRole } from "@/data/sharedTypes";
import { userPropertiesQueryDataAtom } from "@/domains/App/features/Permissions/atoms/permissionsAtom";
import type { PermissionsQuery } from "@/domains/App/features/Permissions/data/__generated__/queries.generated";

/**
 * The overarching assumption in Dashboard is that the default permissions
 * are admin permissions. Do not change this mock data unless you are explicitly
 * addressing this assumption across the codebase.
 */
export const defaultPermissionsQueryData = {
  user: {
    id: window.btoa("user:user1"),
    role: UserRole.ADMIN,
    depositsRole: DepositsRole.ADMIN,
    isManager: true,
  },
};

export type MockPermissionsProviderProps = (
  | {
      permissionsQueryData?: PermissionsQuery;
    }
  | {
      depositsRole?: DepositsRole | null;
      role?: UserRole;
      isManager?: boolean;
    }
) & {
  overwrite?: boolean;
};

/**
 * Provides default admin permissions if no `permissionsQueryData` prop is supplied.
 */
export const MockPermissionsProvider: React.FC<MockPermissionsProviderProps> =
  React.memo(({ children, overwrite = true, ...props }) => {
    const {
      id = defaultPermissionsQueryData.user.id,
      depositsRole = defaultPermissionsQueryData.user.depositsRole,
      role = defaultPermissionsQueryData.user.role,
      isManager = defaultPermissionsQueryData.user.isManager,
    } = "permissionsQueryData" in props
      ? (props.permissionsQueryData?.user ?? {})
      : {
          role: "role" in props ? props.role : undefined,
          depositsRole:
            "depositsRole" in props ? props.depositsRole : undefined,
          isManager: "isManager" in props ? props.isManager : undefined,
        };

    const permissionsQueryData = React.useMemo(
      () => ({
        user: {
          id,
          role,
          depositsRole,
          isManager,
        },
      }),
      [depositsRole, id, isManager, role],
    );

    return (
      <React.Suspense fallback={null}>
        <JotaiMockProvider
          mockedAtoms={[[userPropertiesQueryDataAtom, permissionsQueryData]]}
          overwrite={overwrite}
        >
          {children}
        </JotaiMockProvider>
      </React.Suspense>
    );
  });
